import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Measure } from '@doctorus-front-end-monorepo/graphql';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { BaseEntityFormComponent } from '../../../../../shared/feature-entity/src';


@Component({
    selector: 'medical-measure-write-measure-dialog',
    imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    UiMatFormFieldErrorDisplayerDirective,
    MatInputModule
],
    templateUrl: './write-measure-dialog.component.html',
    styleUrls: ['./write-measure-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WriteMeasureDialogComponent extends BaseEntityFormComponent<Measure> {
  patient = computed(() => this.extra().patient);
  override initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id],
      name: [this.obj()?.name ?? this.queryParams()?.name, Validators.required],
      unit: [this.obj()?.unit ?? this.queryParams()?.unit, Validators.required],
      patient: [this.patient().id, Validators.required],
      description: [this.obj()?.description ?? this.queryParams()?.description],
    });
}
