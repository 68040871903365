import { Route } from '@angular/router';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_LIST_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityConfig,
  EntityDeleteMutationService,
  ListWrapperComponent,
  PutEntityService,
  WriteWrapperComponent,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  DeleteLocationGQL,
  PutLocationGQL,
  resolveAccountSettingsCollection,
  resolveAccountSettingsItem,
} from '@doctorus-front-end-monorepo/graphql';
import { LocationDetailsComponent } from './location-details/location-details.component';
import { LocationFormComponent } from './location-form/location-form.component';
import { UiLocationsComponent } from './ui-locations/ui-locations.component';
// type EntityRedirect = ((args: any) => any[]) | any[];

// export const accountSettingsFeatureEntityRoute = <T extends IEntity>(args: {
//   entityConfig: EntityConfig<T>;
//   listComponent: BaseEntityListComponent<T>;
//   detailsComponent: BaseEntityDetailsComponent<T>;
//   writeComponent: BaseEntityFormComponent<T>;
//   writeService: PutEntityService<T>;
//   deleteService: EntityDeleteMutationService;
//   listResolver: any;
//   detailsResolver: any;
//   // deleteRedirect: EntityRedirect;
//   // newEntityRedirect: EntityRedirect;
//   // editEntityRedirect:
// }) => ({
//   path: args.entityConfig.base + 's',
//   data: {
//     breadcrumbChunck: { name: args.entityConfig.plural },
//   },
//   providers: [
//     {
//       provide: ENTITY_CONFIG,
//       useValue: args.entityConfig,
//     },
//     {
//       provide: ENTITY_LIST_COMPONENT,
//       useValue: args.listComponent,
//     },
//     {
//       provide: ENTITY_WRITE_COMPONENT,
//       useValue: args.writeComponent,
//     },
//     {
//       provide: PutEntityService,
//       useClass: args.writeService,
//     },
//   ],
//   children: [
//     {
//       path: '',
//       component: ListWrapperComponent,
//       resolve: {
//         objs: args.listResolver,
//       },
//       data: {
//         breadcrumbChunck: { name: null },
//         deleteRedirect: ['..'],
//       },
//     },
//     {
//       path: 'new',
//       component: WriteWrapperComponent,
//       data: {
//         successRedirect: (args: any) => ['.', args.id],
//         cancelRedirect: ['..'],
//         obj: null,
//         breadcrumbChunck: { name: $localize`new` },
//       },
//     },
//     {
//       path: ':id',
//       runGuardsAndResolvers: 'always',

//       resolve: {
//         obj: args.detailsResolver,
//       },
//       data: {
//         breadcrumbChunck: {
//           name: $localize`${args.entityConfig.feature}:{{obj.name}}`,
//         },
//       },
//       providers: [
//         {
//           provide: ENTITY_DETAILS_COMPONENT,
//           useValue: args.detailsComponent,
//         },
//       ],
//       children: [
//         {
//           path: '',
//           data: {
//             breadcrumbChunck: { name: null },
//           },
//           providers: [
//             {
//               provide: EntityDeleteMutationService,
//               useClass: args.deleteService,
//             },
//           ],
//           component: DetailsWrapperComponent,
//         },
//         {
//           path: 'edit',
//           data: {
//             breadcrumbChunck: { name: $localize`edit` },
//             successRedirect: ['..'],
//             cancelRedirect: ['..'],
//           },
//           component: WriteWrapperComponent,
//         },
//       ],
//     },
//   ],
// });
export const uiLocationsRoutes: Route = {
  path: 'locations',
  data: {
    breadcrumbChunck: { name: $localize`location` },
  },
  providers: [
    {
      provide: ENTITY_CONFIG,
      useValue: new EntityConfig({ feature: $localize`location` }),
    },
    {
      provide: ENTITY_LIST_COMPONENT,
      useValue: UiLocationsComponent,
    },
    {
      provide: ENTITY_WRITE_COMPONENT,
      useValue: LocationFormComponent,
    },
    {
      provide: PutEntityService,
      useClass: PutLocationGQL,
    },
  ],
  children: [
    {
      path: '',
      component: ListWrapperComponent,
      resolve: {
        objs: resolveAccountSettingsCollection('locations'),
      },
      data: {
        breadcrumbChunck: { name: null },
      },
    },
    {
      path: 'new',
      component: WriteWrapperComponent,
      data: {
        successRedirect: ['..'],
        cancelRedirect: ['..'],
        obj: null,
        breadcrumbChunck: { name: $localize`new` },
      },
    },
    {
      path: ':id',
      runGuardsAndResolvers: 'always',

      resolve: {
        obj: resolveAccountSettingsItem('locations'),
      },
      data: {
        deleteRedirect: ['..'],

        breadcrumbChunck: { name: $localize`location:{{obj.name}}` },
      },
      providers: [
        {
          provide: ENTITY_DETAILS_COMPONENT,
          useValue: LocationDetailsComponent,
        },
      ],
      children: [
        {
          path: '',
          data: {
            breadcrumbChunck: { name: null },
          },
          providers: [
            {
              provide: EntityDeleteMutationService,
              useClass: DeleteLocationGQL,
            },
          ],
          component: DetailsWrapperComponent,
        },
        {
          path: 'edit',
          data: {
            breadcrumbChunck: { name: $localize`edit` },
            successRedirect: ['..'],
            cancelRedirect: ['..'],
          },
          component: WriteWrapperComponent,
        },
      ],
    },
  ],
};
