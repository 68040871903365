import { Route } from '@angular/router';
import {
  PutTaskTypeGQL,
  resolveAccountSettingsCollection,
  resolveAccountSettingsItem,
} from '../../../../shared/data-access/graphql/src';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_LIST_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityConfig,
  ListWrapperComponent,
  PutEntityService,
  WriteWrapperComponent,
} from '../../../../shared/feature-entity/src';
import { TaskTypeDetailsComponent } from './task-type-details/task-type-details.component';
import { TaskTypeFormComponent } from './task-type-form/task-type-form.component';
import { UiTaskTypesComponent } from './ui-task-types/ui-task-types.component';

export const uiTaskTypesRoute: Route = {
  path: 'task-types',
  data: {
    breadcrumbChunck: { name: $localize`task types` },
  },
  providers: [
    {
      provide: ENTITY_CONFIG,
      useValue: new EntityConfig({ feature: $localize`task type` }),
    },
    {
      provide: ENTITY_LIST_COMPONENT,
      useValue: UiTaskTypesComponent,
    },
    {
      provide: ENTITY_WRITE_COMPONENT,
      useValue: TaskTypeFormComponent,
    },
    {
      provide: PutEntityService,
      useClass: PutTaskTypeGQL,
    },
  ],
  children: [
    {
      path: '',
      component: ListWrapperComponent,
      resolve: {
        objs: resolveAccountSettingsCollection('taskTypes'),
      },
      data: {
        breadcrumbChunck: { name: null },
      },
    },
    {
      path: 'new',
      component: WriteWrapperComponent,
      data: {
        obj: null,
        breadcrumbChunck: { name: $localize`new` },
      },
    },
    {
      path: ':id',
      runGuardsAndResolvers: 'always',

      resolve: {
        obj: resolveAccountSettingsItem('taskTypes'),
      },
      data: {
        breadcrumbChunck: { name: $localize`task type:{{obj.name}}` },
      },
      providers: [
        {
          provide: ENTITY_CONFIG,
          useValue: new EntityConfig({ feature: $localize`task type` }),
        },
        {
          provide: ENTITY_DETAILS_COMPONENT,
          useValue: TaskTypeDetailsComponent,
        },
      ],
      children: [
        {
          path: '',
          data: {
            breadcrumbChunck: { name: null },
          },
          component: DetailsWrapperComponent,
        },
        {
          path: 'edit',
          data: {
            breadcrumbChunck: { name: $localize`edit` },
          },
          component: WriteWrapperComponent,
        },
      ],
    },
  ],
};
