import { Route } from '@angular/router';
import {
  AccountResolver,
  AccountSettingsComponent,
  settingsRoutes,
} from '@doctorus-front-end-monorepo/account-settings';
import { AccountRole } from '@doctorus-front-end-monorepo/auth';
import { featureCarePlanMainRoutes } from '@doctorus-front-end-monorepo/feature-care-plan';
import { patientsRoutes } from '@doctorus-front-end-monorepo/feature-patients-viewer';
import { FeatureSchedulerComponent } from '@doctorus-front-end-monorepo/feature-scheduler';
import { OfficeManagerComponent } from './office-manager/office-manager.component';
import { OfficeCreatedComponent } from './staff-board/components/office-created/office-created.component';
import { StaffBoardComponent } from './staff-board/components/staff-board/staff-board.component';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'staff-board',
    pathMatch: 'full',
  },
  {
    path: 'staff-board',
    component: StaffBoardComponent,
  },
  // {
  //   path: 'account-created/:accountId',
  //   component: OfficeCreatedComponent,
  // },

  {
    path: ':accountId',
    component: OfficeManagerComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      account: AccountResolver,
      obj: AccountResolver,
    },
    data: {
      breadcrumbChunck: { name: '{{account.alias}}' },
    },
    children: [
      {
        path: '',
        redirectTo: 'scheduler',
        pathMatch: 'full',
      },
      {
        path: 'account-created',
        component: OfficeCreatedComponent,
      },
      {
        path: 'settings',
        data: {
          breadcrumbGroup: 'primary',
          authorizeFor: [AccountRole.MANAGER],

          breadcrumbChunck: { name: $localize`preferences & settings` },
        },
        component: AccountSettingsComponent,
        children: settingsRoutes,
      },
      featureCarePlanMainRoutes,
      {
        path: 'scheduler',
        component: FeatureSchedulerComponent,

        data: {
          authorizeFor: [AccountRole.MEDICAL_ASSISTANT, AccountRole.DOCTOR],

          breadcrumbChunck: { name: 'scheduler' },
        },
      },
      patientsRoutes,
    ],
  },
  {
    path: '',
    redirectTo: 'staff-board',
    pathMatch: 'full',
  },
];
