import { QuillConfig, QuillModules } from 'ngx-quill';
import Quill from 'quill';

export const quillConfig: QuillConfig = {
  modules: {
    toolbar: {
      container: [
        ['undo', 'redo'],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        //['blockquote', 'code-block'],

        [{ list: 'ordered' }, { list: 'bullet' }],
        //[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        // [{ direction: 'rtl' }], // text direction

        //[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }],
        //[{ font: [] }],
        [{ align: [] }],

        ['clean'], // remove formatting button

        //['link', 'image', 'video'], // link and image, video]
      ],
      handlers: {
        undo: function () {
          this['quill'].history.undo();
        },
        redo: function () {
          this['quill'].history.redo();
        },
      },
    },
    history: {
      userOnly: true, // leaves programmatical changes intact, including init'ing the content
    },
  },
};
export type SuggestionItem = { id: any; value: string };

export const mentionsConfig = (tags: string[]): QuillModules => ({
  toolbar: quillConfig.modules?.toolbar,
  mention: {
    allowedChars: /^[A-Za-z0-9_\sÅÄÖåäöéàçè]*$/,
    mentionDenotationChars: ['@', '#'],
    positioningStrategy: 'fixed',

    source: (searchTerm: string, renderList: any, mentionChar: any) => {
      let values: SuggestionItem[] = [];
      console.warn('hello');
      // if (searchTerm.includes(' ')) {
      //   this.addItem(searchTerm);
      // }
      if (['@', '#'].includes(mentionChar)) {
        values = tags.map(x => ({ id: x, value: x })) ?? [];
      }
      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches: SuggestionItem[][] = [];
        values.forEach((value, index) => {
          if (value.value?.toLowerCase().indexOf(searchTerm.toLowerCase())) {
            matches.push(values);
          }
        });

        renderList(matches, searchTerm);
      }
    },
  },
});

const icons = Quill.import('ui/icons');
icons['undo'] =
  '<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>' +
  '<path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path></svg>';
icons['redo'] =
  '<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>' +
  '<path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path></svg>';

//Quill.register({ 'blots/mention': MentionBlot, 'modules/mention': Mention });
