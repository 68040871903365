import {
  DatePipe,
  NgFor,
  TitleCasePipe
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientInfo } from '@doctorus-front-end-monorepo/graphql';
import { STATUS_CONF } from '@doctorus-front-end-monorepo/shared-type';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import { debounceTime, filter } from 'rxjs';
import { SchedulerFilterPayload } from '../types';

@Component({
    selector: 'medical-space-web-scheduler-filter',
    templateUrl: './scheduler-filter.component.html',
    styleUrls: ['./scheduler-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
    MatDatepickerModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatButtonModule,
    TitleCasePipe
]
})
export class SchedulerFilterComponent {
  @Input({ required: true }) selectedDate!: Date;
  @Output() dateChanged = new EventEmitter<Date>();
  @Output() filterChanged = new EventEmitter<Partial<SchedulerFilterPayload>>();
  humanPipe = new HumanNamePipe();
  queryParamsFg = this.fb.group({
    location: [[]],
    status: [[]],
    // contributors: [[]],
    // types: [[]],
  });
  datePipe = new DatePipe(this.localeId);
  patientFilterCtrl = new FormControl();
  statusList = Object.entries(STATUS_CONF['appointment']);
  patientDisplayFn(patient: PatientInfo): string {
    return patient && this.humanPipe.transform(patient, 'full');
  }
  //account$ = this.ads.account$;
  constructor(
    //private ads: AccountDataService,
    @Inject(LOCALE_ID) private localeId: string,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) {
    // this.filtredPatients$ = this.patientFilterCtrl.valueChanges.pipe(
    //   filter((x) => (x ? true : false)),
    //   switchMap((x) =>
    //     this.ads.get<PaginatedResponseType<Patient>, Patient>(
    //       patientConfig.baseUri,
    //       {
    //         fields: ['id', 'given_name', 'family_name', 'email', 'gender'],
    //         withLoadEvent: false,
    //         paramMap: new Map<string, any>()
    //           .set('search', x?.toString() ?? '')
    //           .set('page_size', '500'),
    //       },
    //     ),
    //   ),
    //   map((res) => res.results.map((x) => patientConfig.deserializerFun(x))),
    // );
    this.queryParamsFg.valueChanges
      .pipe(
        debounceTime(300),
        filter(() => this.queryParamsFg.valid),
      )
      .subscribe((value: Partial<SchedulerFilterPayload>) => {
        this.filterChanged.emit(value);
      });
  }
  toDate(event: Date): void {
    this.dateChanged.next(event);
  }
}
