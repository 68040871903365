import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { BaseEntityListComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { MedicalHistoryModel } from '@doctorus-front-end-monorepo/graphql';

@Component({
  selector: 'lib-ui-medical-history-model',
  imports: [CommonModule, MatTableModule, RouterModule, MatIconModule],
  templateUrl: './ui-medical-history-model.component.html',
  styleUrl: './ui-medical-history-model.component.css',
})
export class UiMedicalHistoryModelComponent extends BaseEntityListComponent<MedicalHistoryModel> {}
