import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import {
  AccountDataService,
  ListCarePlansGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import {
  ContainerComponent,
  EmptyStateComponent,
  LoadingComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { RouterNavigationHelperService } from '@doctorus-front-end-monorepo/util-navigation';
import { filter, map } from 'rxjs';

@Component({
  selector: 'lib-care-plan-table',
  imports: [
    CommonModule,
    ContainerComponent,
    HumanNamePipe,
    MatTableModule,
    MatButtonModule,
    MatDatepickerModule,
    RouterModule,
    HumanNamePipe,
    EmptyStateComponent,
    MatPaginatorModule,
    MatFormFieldModule,
    ContainerComponent,
    LoadingComponent,
    MatSelectModule,
    MatSortModule,
  ],
  templateUrl: './care-plan-table.component.html',
  styleUrl: './care-plan-table.component.scss',
})
export class CarePlanTableComponent {
  public rhns = inject(RouterNavigationHelperService);
  columns = ['name', 'patient', 'start', 'end'];
  listCarePlansGQL = inject(ListCarePlansGQL);

  account = inject(AccountDataService).account;

  page = signal<number | null>(null);
  startDateTo = signal<Date | null>(null);
  startDateFrom = signal<Date | null>(null);
  contributors = signal<Array<string> | null>(null);
  ordering = signal<string | null>(null);

  filters = computed(() => ({
    page: this.page() ? this.page()! + 1 : null,
    start_before: this.startDateTo(),
    start_after: this.startDateFrom(),
    contributors: this.contributors(),
    ordering: this.ordering(),
  }));

  carePlansRessource = rxResource({
    request: this.filters,
    loader: ({ request }) =>
      this.listCarePlansGQL
        .watch(
          {
            payload: request,
          },
          { fetchPolicy: 'no-cache', initialFetchPolicy: 'no-cache' },
        )
        .valueChanges.pipe(
          map(res => res.data.listCarePlans),
          filter(res => (res ? true : false)),
        ),
  });

  title = computed(
    () => $localize`care plans (${this.carePlans()?.count ?? 0})`,
  );
  carePlans = computed(() => this.carePlansRessource.value());
  isLoading = computed(() => this.carePlansRessource.isLoading());

  clearFilters(): void {
    this.startDateTo.set(null);
    this.startDateFrom.set(null);
    this.contributors.set(null);
  }

  onSort(event: Sort): void {
    const direction = event.direction === 'asc' ? '' : '-';
    const value = event.direction ? `${direction}${event.active}` : '';
    this.ordering.set(value);
  }
}
