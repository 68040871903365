<main class="grid grid-cols-2 gap-4">
    <ui-layout-key-value key="phone number" i18n-key>
        <a class="anchor" [href]="obj().phone_number|phoneNumber:'RFC3966'">{{
            obj().phone_number|phoneNumber
            }}</a>
    </ui-layout-key-value>
    
    <ui-layout-key-value key="availabilities" i18n-key>
        @if (obj().availabilities) {
            <mat-chip-set class="flex flex-col gap-1">
              @for (availability of obj().availabilities; track $index) {<mat-chip>{{availability}}</mat-chip>}
            </mat-chip-set>
            }
    </ui-layout-key-value>
    
    <ui-layout-key-value class="col-span-full" key="remarks" i18n-key>
        <span>{{obj().remarks | coalesce:'-'}}</span>
    </ui-layout-key-value>
</main>
