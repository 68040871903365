import { AsyncPipe, CommonModule, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
  computed,
  signal,
} from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AccountDataService } from '@doctorus-front-end-monorepo/auth';
import {
  CorePatientFieldsFragment,
  CreatePatientGQL,
  DeletePatientGQL,
  GetPatientProfileGQL,
  UpdatePatientProfileGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { Human } from '@doctorus-front-end-monorepo/shared-type';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import { SlideOutPanelService } from '@doctorus-front-end-monorepo/slide-out-panel';
import {
  EntityDeleteMutationService,
  EntityDialogService,
} from '@doctorus-front-end-monorepo/ui-entity-dialog';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { UiPhoneNumberPickerComponent } from '@doctorus-front-end-monorepo/ui-phone-number-picker';
import { checkForm } from '@doctorus-front-end-monorepo/util-form';
import { PhoneNumberPipe } from '@doctorus-front-end-monorepo/util-formatting';
import { RouterNavigationHelperService } from '@doctorus-front-end-monorepo/util-navigation';
import * as _ from 'lodash';
import { filter, finalize, first, map, switchMap } from 'rxjs';
import { InlineFormArrayComponent } from '../../../../../form/inline-form-array/src';
import { KeyValueEditorComponent } from '../../../../../form/key-value-editor/src';
import {
  ContainerComponent,
  EmptyStateComponent,
} from '../../../../../ui-layout/src';
import { PatientFormBuilderService } from '../patient-form-builder.service';
import { PatientPropertyInlineFormComponent } from '../patient-property-inline-form/patient-property-inline-form.component';
import { patientConfig } from '../patient.entity.model';
import { PHONE_NUMBER_AVAILABILTIES } from '../types';
@Component({
  selector: 'patient-patient-write-form',
  templateUrl: './patient-write-form.component.html',
  styleUrls: ['./patient-write-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    {
      provide: EntityDeleteMutationService,
      useExisting: DeletePatientGQL,
    },
  ],
  imports: [
    MatIconModule,
    CommonModule,
    NgIf,
    MatButtonModule,
    MatListModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatChipsModule,
    MatDividerModule,
    ContainerComponent,
    RouterModule,
    PhoneNumberPipe,
    InlineFormArrayComponent,
    UiMatFormFieldErrorDisplayerDirective,
    EmptyStateComponent,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    PhoneNumberPipe,
    UiPhoneNumberPickerComponent,
    PatientPropertyInlineFormComponent,
    NgFor,
    AsyncPipe,
    KeyValueEditorComponent,
    HumanNamePipe,
  ],
})
export class PatientWriteFormComponent implements OnInit {
  patient = signal<CorePatientFieldsFragment | undefined | null>(undefined);
  isNewPatient = computed<boolean>(() => _.isNil(this.patient()));
  titleStr = computed<string>(() =>
    this.isNewPatient() ? $localize`new patient` : $localize`edit patient`,
  );
  patientForm = this.fbs.createForm();
  loading = signal(false);
  availabilities = PHONE_NUMBER_AVAILABILTIES;
  // phoneNumberInjector = Injector.create({
  //   providers: [
  //     {
  //       provide: EntityMutationService,
  //       useExisting: PutPhoneNumberGQL,
  //     },
  //     {
  //       provide: EntityDeleteMutationService,
  //       useExisting: DeletePhoneNumberGQL,
  //     },
  //   ],
  //   parent: this.injector,
  // });
  constructor(
    private eds: EntityDialogService,
    private sds: SlideOutPanelService,
    private matSnackBar: MatSnackBar,
    public rhns: RouterNavigationHelperService,
    private route: ActivatedRoute,
    private injector: Injector,
    private router: Router,
    public oss: AccountDataService,
    private getPatientGql: GetPatientProfileGQL,
    private fbs: PatientFormBuilderService,
    private createPatientGql: CreatePatientGQL,
    private updatePatientGql: UpdatePatientProfileGQL,
  ) {}

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        map(x => x.get('patId')),
        filter(x => (x ? true : false)),
        switchMap(x => this.getPatientGql.watch({ id: x }).valueChanges),
      )
      .subscribe(x => {
        this.patient.set(x.data?.getPatient);
        this.patientForm = this.fbs.createForm(this.patient());
      });
  }

  get actionStr(): string {
    return this.patient() ? $localize`save` : $localize`create`;
  }

  get patientInfo(): Human {
    return {
      given_name:
        this.patient()?.given_name ??
        this.patientForm?.get('given_name')?.value ??
        '____',
      family_name:
        this.patient()?.family_name ??
        this.patientForm?.get('family_name')?.value ??
        '____',
      gender:
        this.patient()?.gender ?? this.patientForm?.get('gender')?.value ?? '',
      middle_name:
        this.patient()?.middle_name ??
        this.patientForm?.get('middle_name')?.value ??
        '',
    };
  }

  get phoneNumberCtrls() {
    return (this.patientForm.get('phone_numbers') as FormArray)
      ?.controls as FormGroup[];
  }
  get phoneNumberCount() {
    return this.phoneNumberCtrls.length;
  }
  get phoneNumberTitle() {
    return $localize`phone number (${this.phoneNumberCount})`;
  }
  newPhoneNumber(): void {
    this.addPhoneNumberCtrl();
  }

  removePhoneNumberCtrl(index: number): void {
    (this.patientForm.get('phone_numbers') as FormArray).removeAt(index);
  }
  addPhoneNumberCtrl(): void {
    (this.patientForm.get('phone_numbers') as FormArray).push(
      this.fbs.createPhoneNumberForm(),
    );
  }

  get propertiesCtrls() {
    return (this.patientForm.get('public_properties') as FormArray)
      ?.controls as FormGroup[];
  }
  get propertiesCount() {
    return this.propertiesCtrls.length;
  }
  get propertiesTitle() {
    return $localize`public properties (${this.propertiesCount})`;
  }
  newProperty(): void {
    this.addProperyCtrl();
  }

  // removeProperyCtrl(index: number): void {
  //   (this.patientForm.get('public_properties') as FormArray).removeAt(index);
  // }
  addProperyCtrl(): void {
    (this.patientForm.get('public_properties') as FormArray).push(
      this.fbs.createPropertyForm(),
    );
  }
  createProperty = (payload?: any) => this.fbs.createPropertyForm(payload);

  deletePatient(): void {
    this.eds
      .openEntityDeleteDialog(this.patient(), this.injector, patientConfig)
      .subscribe(() => this.router.navigate(this.rhns.getPatientListRoute()));
  }

  submit(): void {
    this.loading.set(true);
    checkForm(this.patientForm)
      .pipe(
        switchMap(x =>
          this.isNewPatient()
            ? this.createPatientGql
                .mutate({
                  payload: patientConfig.serializerFun(this.patientForm.value),
                })
                .pipe(map(x => x.data?.createPatient))
            : this.updatePatientGql
                .mutate({
                  payload: patientConfig.serializerFun(this.patientForm.value),
                  id: this.patient()?.id,
                })
                .pipe(map(x => x.data?.updatePatientProfile)),
        ),
        first(),
        finalize(() => this.loading.set(false)),
      )
      .subscribe(x => {
        this.matSnackBar.open(
          patientConfig.onPutSuccessMsg(this.patient() ? true : false),
          'ok',
        );

        if (this.isNewPatient()) {
          this.router.navigate(['..', x.id], { relativeTo: this.route });
        } else {
          this.router.navigate(['..'], { relativeTo: this.route });
        }
      });
  }
}
