@if (mode === 'SMALL') {
  <a
    class="anchor"
    [href]="phonenumber.phone_number | phoneNumber: 'RFC3966'"
    >{{ phonenumber.phone_number | phoneNumber }}</a
  >
} @else {
  <div class="flex flex-col">
    <a
      class="anchor"
      [href]="phonenumber.phone_number | phoneNumber: 'RFC3966'"
      >{{ phonenumber.phone_number | phoneNumber }}</a
    >
    @if (phonenumber.remarks) {
      <i>{{ phonenumber.remarks }}</i>
    }
    <div class="flex gap-2">
      @for (tag of phonenumber.availabilities; track $index) {
        <span
          class="bg-slate-200 rounded-full py-0.5 text-xs px-1 font-medium"
          >{{ tag }}</span
        >
      }
    </div>
  </div>
}
