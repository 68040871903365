<entity-list-wrapper [objs]="objs()">
  <ul class="flex flex-col gap-6">
    @for (measureEntry of data(); track $index) {
      <li
        class="grid gap-2 grid-cols-4 w-full border p-4 rounded-md border-gray-200"
      >
        @if (measureEntry.checked) {
          <div>
            <a class="anchor" [routerLink]="measureEntry.obj?.id">
              <h4>{{ measureEntry.name }} ({{ measureEntry.obj?.unit }})</h4>
            </a>
            @if (measureEntry.obj?.description; as description) {
              <p class="text-gray-700">{{ description }}</p>
            }
            <medical-measure-measure-observations-summary
              size="1"
              class="text-sm text-gray-700"
              ngProjectAs="description"
              [measure]="$any(measureEntry.obj)"
            ></medical-measure-measure-observations-summary>
          </div>
          <medical-measure-measure-graph
            class="col-span-3"
            height="80"
            withScales="false"
            [measure]="$any(measureEntry.obj)"
          ></medical-measure-measure-graph>
        } @else {
          <div>
            <h4>{{ measureEntry.name }} ({{ measureEntry.model?.unit }})</h4>
            @if (measureEntry.model?.description; as description) {
              <p class="text-gray-700">{{ description }}</p>
            }
          </div>
          <ui-layout-feedback
            class="col-span-3"
            pattern="2"
            message="this measure needs to be taken"
            i18n-message
            i18n-title
            type="1"
          >
            <button
              mat-button
              [queryParams]="measureEntry.model"
              [routerLink]="['new']"
              ngProjectAs="actions"
            >
              <span i18n>create this measure</span>
            </button>
          </ui-layout-feedback>
        }
      </li>
    }
  </ul>
</entity-list-wrapper>
