import { Route } from '@angular/router';
import { BreadcrumbRouteData } from '@doctorus-front-end-monorepo/breadcrumb';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityDeleteMutationService,
  PutEntityService,
  WriteWrapperComponent,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  DeletePrescriptionGQL,
  PutPrescriptionGQL,
  resolveMedicalRecordItem,
} from '@doctorus-front-end-monorepo/graphql';
import { PrescriptionBoxComponent } from './prescription-box/prescription-box.component';
import { prescriptionConfig } from './prescription.entity.model';
import { WritePrescriptionFormComponent } from './write-prescription-form/write-prescription-form.component';

export const featurePrescriptionRoutes: Route = {
  path: 'prescription',
  data: {
    breadcrumbChunck: {
      name: null,
    } as BreadcrumbRouteData,
  },
  providers: [
    {
      provide: ENTITY_CONFIG,
      useValue: prescriptionConfig,
    },
    {
      provide: EntityDeleteMutationService,
      useClass: DeletePrescriptionGQL,
    },
    {
      provide: PutEntityService,
      useClass: PutPrescriptionGQL,
    },
    {
      provide: ENTITY_DETAILS_COMPONENT,
      useValue: PrescriptionBoxComponent,
    },
    {
      provide: ENTITY_WRITE_COMPONENT,
      useValue: WritePrescriptionFormComponent,
    },
  ],
  children: [
    {
      path: 'new',
      component: WriteWrapperComponent,
      data: {
        successRedirect: ['../..'],
        cancelRedirect: ['../..'],
        obj: null,
        breadcrumbChunck: {
          name: $localize`new`,
        } as BreadcrumbRouteData,
      },
    },
    {
      path: ':id',
      runGuardsAndResolvers: 'always',

      resolve: {
        obj: resolveMedicalRecordItem('prescriptions'),
      },
      data: {
        breadcrumbChunck: {
          name: $localize`prescription:{{obj.id}}`,
        } as BreadcrumbRouteData,
      },
      children: [
        {
          path: '',
          component: DetailsWrapperComponent,

          data: {
            deleteRedirect: ['../..'],

            breadcrumbChunck: {
              name: null,
            } as BreadcrumbRouteData,
          },
        },
        {
          path: 'edit',
          component: WriteWrapperComponent,
          data: {
            breadcrumbChunck: {
              name: $localize`edit`,
            } as BreadcrumbRouteData,
          },
        },
      ],
    },
  ],
};
