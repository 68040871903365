<main class="grid grid-cols-3 gap-4">
    <ui-layout-key-value key="name">
        {{obj().name}}
    </ui-layout-key-value>
    <ui-layout-key-value key="date">
        {{obj().date|date}}
    </ui-layout-key-value>
    <ui-layout-key-value key="status">
        @if(obj().is_current) {
            <mat-icon class="text-green-700">check_circle</mat-icon>
        }@else {
            <mat-icon class="text-red-700">cancel</mat-icon>

        }
    </ui-layout-key-value>

    <ui-layout-key-value key="content"> 
        <quill-view-html [content]="obj().content"></quill-view-html>
    </ui-layout-key-value>
    
</main>