<mat-sidenav-container class="h-full">
  <mat-sidenav mode="side" fixedTopGap="64" class="w-24 border-r-gray-100 border-r bg-gray-50" fixedInViewport opened>
    <nav class="flex flex-col items-center  gap-4 pt-4 ">
      @for (link of navLinks; track $index) {
      <a *showForRoles="link.requiredRoles" [matTooltip]="link.name" matTooltipPosition="right"
        class="w-full rounded-full hover:bg-blue-100 flex justify-center py-2" [routerLink]="link.routerLink"
        [routerLinkActive]="['bg-blue-200','hover:bg-blue-200']">
        <mat-icon>{{link.svgIcon}}</mat-icon>
      </a>
      }
    </nav>


  </mat-sidenav>
  <mat-sidenav-content>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>