<ui-layout-container level="3" [title]="(appointment().start|date)">
  <span ngProjectAs="description">
    {{appointment().start |date:'shortTime'}} ->
    {{appointment().end
    |date:'shortTime'}}
  </span>
  <div class="flex" ngProjectAs="actions"> <button (click)="deleteAppointment()" color="warn"
      mat-icon-button><mat-icon>delete</mat-icon></button>
    <button (click)="editAppointment()" mat-icon-button><mat-icon>edit
      </mat-icon></button>
    <button (click)="deselect.emit()" mat-icon-button><mat-icon>close</mat-icon></button>
  </div>
  <main class="flex flex-col gap-4">

    <section class="grid grid-cols-2 gap-4">
      <!--  <ui-layout-key-value class="col-span-2" key="schedules" key-i18n>
        {{appointment().start |date:'shortTime'}} ->
        {{appointment().end
        |date:'shortTime'}} </ui-layout-key-value> -->

      <ui-layout-key-value class="col-span-2" key="type" key-i18n>{{appointment().task_type?.name |
        coalesce:"-"}}</ui-layout-key-value>
      <ui-layout-key-value class="col-span-2" key="location" key-i18n>{{appointment().location?.name |
        coalesce:"-"}}</ui-layout-key-value>

      <appointment-status-picker (statusChange)="setStatus($event)" [status]="appointment().status">
        @if (appointment().status|resolveStatusConfig:'appointment' ;as status) {
        <ui-layout-key-value editable="true" key="status" key-i18n>
          <div class="value parent  group-hover:underline flex gap-1 items-center">
            <mat-icon [style.color]="status.color">{{status.svgIcon}}</mat-icon>
            <span class="first-letter:uppercase">{{status.label}}</span>
          </div>
        </ui-layout-key-value>
        }
      </appointment-status-picker>
      <ui-layout-key-value editable="true" (click)="setFees()" key="fees" key-i18n>{{appointment().fees |coalesce:"-"
        }}</ui-layout-key-value>

    </section>
    <ui-layout-container [border]="false" [level]="3" paddingLevel="" i18n-title title="medical notes">
      <span ngProjectAs="description">
        {{appointment().description}}
      </span>
      <div ngProjectAs="actions"> <button (click)="editMedicalNote()" mat-stroked-button><span i18n>edit
          </span></button>
      </div>

      <quill-view [content]="appointment().note | coalesce:'-'" format="html" theme="snow"></quill-view>
    </ui-layout-container>
  </main>

</ui-layout-container>
