import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Patient, PatientPayment } from '@doctorus-front-end-monorepo/graphql';
import { formatISO } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class PaimentFormBuilderService {
  constructor(private fb: FormBuilder) {}
  createForm(patient?: Patient, paiment?: Partial<PatientPayment>): FormGroup {
    return this.fb.group({
      id: [paiment?.id],

      amount: [paiment?.amount ?? 0, [Validators.required, Validators.min(0)]],
      patient: [patient?.id ?? paiment?.patient, [Validators.required]],
      date: [
        paiment?.date ?? formatISO(new Date(), { representation: 'date' }),
        [Validators.required],
      ],
    });
  }
}
