import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MedicalHistory, Patient } from '@doctorus-front-end-monorepo/graphql';

@Injectable({
  providedIn: 'root',
})
export class MedicalHistoryFormBuilderService {
  constructor(private fb: FormBuilder) {}
  createForm(
    patient?: Patient,
    entity?: Partial<MedicalHistory>,
    name?: string,
  ): FormGroup {
    return this.fb.group({
      id: [entity?.id],
      date: [
        entity?.date ?? new Date().toJSON().slice(0, 10),
        [Validators.required],
      ],
      name: [entity?.name ?? name, Validators.required],
      patient: [patient?.id ?? entity?.patient, Validators.required],
      content: [entity?.content, Validators.required],
      is_current: [entity?.is_current ?? true, Validators.required],
    });
  }
}
