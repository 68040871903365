<ui-layout-container
  sticky="true"
  [border]="false"
  [title]="allDocuments()?.length ?? 0 | i18nPlural: messageMapping"
>
  <button [matMenuTriggerFor]="menu" ngProjectAs="actions" mat-stroked-button>
    <mat-icon>post_add</mat-icon><span i18n>new document</span>
  </button>
  @if (allDocuments()?.length > 0) {
    <ul class="flex flex-col gap-2">
      @for (document of allDocuments(); track document) {
        <li class="p-4 border border-gray-200 rounded-md">
          <a class="flex gap-2">
            @switch (document.type) {
              @case (documentType.Uploaded) {
                @if (document.media | isImage) {
                  <img [src]="document.media" class="object-contain h-12" />
                } @else {
                  <util-document-file-box
                    height="32"
                    width="32"
                    rounded="true"
                    [fileSrc]="document.media"
                  ></util-document-file-box>
                }
              }
              @case (documentType.Generated) {
                <mat-icon>edit_document</mat-icon>
              }

              @default {
                <mat-icon>prescriptions</mat-icon>
              }
            }
            <div class="flex flex-col">
              <a
                class="anchor font-medium"
                [routerLink]="documentDetailsLink(document)"
                >{{ document.name | coalesce: '-' }}</a
              >
              <quill-view
                [content]="document.content"
                format="html"
                theme="snow"
              ></quill-view>
            </div>

            <p class="ml-auto">{{ document.date | date }}</p>
          </a>
        </li>
      }
    </ul>
  } @else {
    <ui-layout-empty-state>
      <span i18n>no medical documents</span>
    </ui-layout-empty-state>
  }
</ui-layout-container>

<mat-menu #menu="matMenu">
  <button [routerLink]="['generated-document', 'new']" mat-menu-item>
    <mat-icon>edit_document</mat-icon><span i18n>write document</span>
  </button>
  <button [routerLink]="['upload-document', 'new']" mat-menu-item>
    <mat-icon>upload_file</mat-icon><span i18n>upload document</span>
  </button>
  <button [routerLink]="['prescription', 'new']" mat-menu-item>
    <mat-icon>prescriptions</mat-icon><span i18n>write prescription</span>
  </button>
</mat-menu>
