@let patients = patientsRessource.value();
@let isLoading = patientsRessource.isLoading();

<ui-layout-container
  title="active patients"
  border="false"
  i18n-title
  [description]="containerDescription()"
>
  <button ngProjectAs="actions" [routerLink]="['../..', 'new']" mat-flat-button>
    new patient
  </button>
  <div class="flex gap-4 justify-between flex-wrap">
    <mat-form-field
      subscriptSizing="dynamic"
      class="max-w-sm my-3 medium-text-field w-full"
    >
      <input
        type="search"
        matInput
        placeholder="Search"
        [formControl]="searchCtrl"
      />
    </mat-form-field>
    <mat-paginator
      [pageIndex]="pageIndex()"
      showFirstLastButtons
      [length]="count()"
      pageSize="50"
      [hidePageSize]="true"
      (page)="pageIndex.set($event.pageIndex)"
    >
    </mat-paginator>
  </div>
  @if (isLoading) {
    <ui-layout-loading></ui-layout-loading>
  } @else {
    <table
      matSort
      (matSortChange)="updateSort($any($event))"
      mat-table
      [dataSource]="patients"
    >
      <ng-container sticky matColumnDef="name">
        <th
          sticky
          mat-header-cell
          mat-sort-header="given_name"
          *matHeaderCellDef
        >
          <span class="first-letter:uppercase" i18n="full-name">name</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <a class="anchor font-medium" [routerLink]="['../..', element.id]">
            {{ element | humanName: 'full' : true | titlecase }}</a
          >
        </td>
      </ng-container>
      <ng-container matColumnDef="reference">
        <th
          sticky
          mat-header-cell
          mat-sort-header="reference"
          *matHeaderCellDef
        >
          <span class="first-letter:uppercase" i18n>reference</span>
        </th>
        <td mat-cell *matCellDef="let element">{{ element?.reference }}</td>
      </ng-container>
      <ng-container matColumnDef="phone_number">
        <th mat-header-cell *matHeaderCellDef>
          <span class="first-letter:uppercase" i18n>phone number</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="flex gap-1">
            @if (element.phone_numbers | arrayExist) {
              @for (
                phone_number of element.phone_numbers;
                track $index;
                let isLast = $last
              ) {
                <patient-phone-number-box
                  [phonenumber]="phone_number"
                  mode="SMALL"
                ></patient-phone-number-box>

                @if (!isLast) {
                  <span>|</span>
                }
              }
            } @else {
              -
            }
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="added">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="added">
          <span class="first-letter:uppercase" i18n>added</span>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.added | date: 'shortDate' | coalesce: '-' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="total_due">
        <th mat-header-cell *matHeaderCellDef>
          <span class="first-letter:uppercase" i18n>balance</span>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.total_due | currency: currency : 'symbol' : '1.0-1' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="headline">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div [innerHTML]="element.headline"></div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>
      <tr
        queryParamsHandling="merge"
        mat-row
        *matRowDef="let row; columns: displayedColumns()"
      ></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          <ui-layout-empty-state>
            <p i18n>no patients</p>
            <button ngProjectAs="actions" mat-stroked-button>
              new patient
            </button>
          </ui-layout-empty-state>
        </td>
      </tr>
    </table>
  }
</ui-layout-container>
