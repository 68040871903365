import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { AuthService } from '@doctorus-front-end-monorepo/auth';
import {
  AppsyncNetworkError,
  isJSON,
  ServerErrorSnackBarComponent,
} from '@doctorus-front-end-monorepo/logger';
import { provideApollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { patientTypePolicy } from './type-policies/patient';

loadErrorMessages();
loadDevMessages();
export const graphQlProvider = provideApollo(() => {
  const httpLink = inject(HttpLink);
  const snackbar = inject(MatSnackBar);
  const as = inject(AuthService);
  const http = httpLink.create({
    uri: process.env['NX_GRAPHQL_API_URL'],
  });
  const authOptions: AuthOptions = {
    type: 'OPENID_CONNECT',
    jwtToken: () => as.fetchTokens().then(x => 'Bearer ' + x?.accessToken),
  };
  const region = 'eu-west-1';
  const error = onError((arg: ErrorResponse) => {
    if (arg.graphQLErrors) {
      arg.graphQLErrors.forEach(element => {
        snackbar?.openFromComponent(ServerErrorSnackBarComponent, {
          data: isJSON(element.message)
            ? JSON.parse(element.message)
            : { detail: element.message },
        });
      });
    }
    if (arg.networkError) {
      console.warn(arg);

      const error = arg.networkError as HttpErrorResponse;
      if (error.status && error.status === 401) {
        console.error(error);
        as.signOut();
      } else {
        (
          arg.networkError as unknown as AppsyncNetworkError
        ).error.errors.forEach(element => {
          snackbar?.openFromComponent(ServerErrorSnackBarComponent, {
            data: isJSON(element.message)
              ? JSON.parse(element.message)
              : { detail: element.message },
          });
        });
      }
    }
    console.error(arg);
  });
  const link = ApolloLink.from([
    createAuthLink({
      url: process.env['NX_GRAPHQL_API_URL'] ?? '',
      region,

      auth: authOptions,
    }),
    createSubscriptionHandshakeLink(
      {
        url: process.env['NX_GRAPHQL_API_URL'] ?? '',
        region,
        auth: authOptions,
      },
      error.concat(http),
    ),
  ]);
  return {
    cache: new InMemoryCache({
      typePolicies: {
        // Type policy map

        Patient: patientTypePolicy,
      },
    }),
    link,
  };
});
