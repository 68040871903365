import { DIALOG_DATA } from '@angular/cdk/dialog';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BaseSlideOutPanelComponent } from '../base-slide-out-panel';
import { SlideOutPanelData } from '../slide-out-panel-types';

@Component({
  selector: 'shared-ui-slide-out-panel',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, PortalModule],
  templateUrl: './slide-out-panel.component.html',
  styleUrl: './slide-out-panel.component.css'
})
export class SlideOutPanelComponent<T, C> extends BaseSlideOutPanelComponent<C,T> {
  constructor(@Inject(DIALOG_DATA) public override data: SlideOutPanelData<T, C>) {
    super(data)
  }


}
