@let patients = patientsRessource.value();
@let isLoading = patientsRessource.isLoading();
<ui-layout-container title="inactive patients" border="false" i18n-title>
  <mat-paginator
    [pageIndex]="pageIndex()"
    showFirstLastButtons
    [length]="count()"
    pageSize="50"
    [hidePageSize]="true"
    (page)="pageIndex.set($event.pageIndex)"
  >
  </mat-paginator>
  @if (isLoading) {
    <ui-layout-loading></ui-layout-loading>
  } @else {
    <table matSort mat-table [dataSource]="patients">
      <ng-container sticky matColumnDef="name">
        <th sticky mat-header-cell *matHeaderCellDef>
          <span class="first-letter:uppercase" i18n="full-name">name</span>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element | humanName: 'full' : true | titlecase }}
        </td>
      </ng-container>
      <ng-container matColumnDef="reference">
        <th sticky mat-header-cell *matHeaderCellDef>
          <span class="first-letter:uppercase" i18n>reference</span>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.reference }}
        </td>
      </ng-container>
      <ng-container matColumnDef="by">
        <th sticky mat-header-cell *matHeaderCellDef>
          <span class="first-letter:uppercase" i18n>by</span>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.latest_log_entry.user | humanName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="added">
          <span class="first-letter:uppercase" i18n>date</span>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.latest_log_entry.action_time | date: 'long' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            disabled
            (click)="restoreObject(element.latest_log_entry)"
            mat-stroked-button
          >
            restore
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        queryParamsHandling="merge"
        mat-row
        *matRowDef="let row; columns: columns"
      ></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          <ui-layout-empty-state>
            <p i18n>no inactive patients found</p>
          </ui-layout-empty-state>
        </td>
      </tr>
    </table>
  }
</ui-layout-container>
