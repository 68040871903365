import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  computed,
  inject,
  ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButton } from '@angular/material/button';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { ListWrapperComponent } from '@doctorus-front-end-monorepo/feature-entity';
import {
  AccountDataService,
  allChecks,
  PatientDataService,
} from '@doctorus-front-end-monorepo/graphql';
import { FeedbackComponent } from '@doctorus-front-end-monorepo/ui-layout';
import { QuillViewHTMLComponent } from 'ngx-quill';
import { map } from 'rxjs';
import { MedicalHistoryTableDataSource } from './medical-history-table-datasource';

@Component({
  selector: 'medical-history-medical-history-table',
  templateUrl: './medical-history-table.component.html',
  styleUrl: './medical-history-table.component.css',
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    RouterModule,
    MatSortModule,
    CommonModule,
    FeedbackComponent,
    MatButton,
    QuillViewHTMLComponent,
    ListWrapperComponent,
    RouterModule,
  ],
})
export class MedicalHistoryTableComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  dataSource = new MedicalHistoryTableDataSource();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'date', 'content', 'status'];

  medicalHistoryModels =
    inject(AccountDataService).account?.medicalHistoryModels ?? [];

  objs = toSignal(
    inject(PatientDataService)?.selectedPatient$.pipe(
      map(x => x?.medical_histories ?? []),
    ),
  );

  data = computed(() =>
    allChecks(this.objs() ?? [], this.medicalHistoryModels, 'name', 'type'),
  );
  ngAfterViewInit(): void {
    this.dataSource.data = this.data();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }
}
