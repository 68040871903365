import { Injectable } from '@angular/core';
import { Patient } from '@doctorus-front-end-monorepo/graphql';

@Injectable({
  providedIn: 'root',
})
export class RouterNavigationHelperService {
  get officeRoute(): any[] {
    return ['/', localStorage.getItem('account-id'), 'office'];
  }

  get schedulerRoute(): any[] {
    return this.officeRoute.map(route => route.concat(['scheduler']));
  }

  getPatientListRoute(): any[] {
    return ['/', localStorage.getItem('account-id'), 'patients', 'list'];
  }

  getPatientRoute = (
    patient: Pick<Patient, 'id' | 'is_active'> | undefined | null,
  ) =>
    patient && patient.is_active
      ? ['/', localStorage.getItem('account-id'), 'patients', patient.id]
      : null;

  getApppointmentRoute = (patientId: string, appId: string) => [
    '/',
    localStorage.getItem('account-id'),
    'patients',
    patientId,
    'appointments',
    appId,
  ];
}
