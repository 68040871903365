import { inject, Injectable } from '@angular/core';
import { debounceTime, filter, merge } from 'rxjs';
import {
  OnAppointmentDeleteGQL,
  OnAppointmentPutGQL,
  OnAppointmentUpdateGQL,
  OnPatientAppointmentPutGQL,
  OnPatientPaimentDeleteGQL,
  OnPatientPaimentPutGQL,
} from './generated';

@Injectable({
  providedIn: 'root',
})
export class RealTimeSubscriptionsService {
  private onPatientAppointmentPutGQL = inject(OnPatientAppointmentPutGQL);
  private onAppointmentUpdateGQL = inject(OnAppointmentUpdateGQL);
  private onAppointmentPutGQL = inject(OnAppointmentPutGQL);
  private onAppointmentDeleteGQL = inject(OnAppointmentDeleteGQL);
  private onPatientPaimentDeleteGQL = inject(OnPatientPaimentDeleteGQL);
  private onPatientPaimentPutGQL = inject(OnPatientPaimentPutGQL);
  listenAppointmentSubscriptions = (patientId?: string | number) =>
    merge(
      this.onPatientAppointmentPutGQL
        .subscribe()
        .pipe(
          filter(x =>
            patientId ? x.data?.patientAppointmentPut?.id === patientId : true,
          ),
        ),
      this.onAppointmentUpdateGQL
        .subscribe()
        .pipe(
          filter(x =>
            patientId ? x.data?.appointmentUpdate?.patient === patientId : true,
          ),
        ),
      this.onAppointmentPutGQL
        .subscribe()
        .pipe(
          filter(x =>
            patientId ? x.data?.appointmentPut?.patient === patientId : true,
          ),
        ),
      this.onAppointmentDeleteGQL.subscribe(),
    ).pipe(debounceTime(1000));
  listenPaymentsSubscriptions = (patientId?: string | number) =>
    merge(
      this.onPatientPaimentPutGQL
        .subscribe()
        .pipe(filter(x => x.data?.patientPaymentPut?.id === patientId)),
      this.onPatientPaimentDeleteGQL.subscribe(),
    ).pipe(debounceTime(1000));
}
