import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { ComponentPortal } from "@angular/cdk/portal";
import { Inject, Injector, inject } from "@angular/core";
import { SLIDE_OUT_DATA_TOKEN } from "./slide-out-panel-tokens";

export class BaseSlideOutPanelComponent<C,R> {
  dialogRef = inject(DialogRef<R>);
  componentPortal = this.loadCmp();
  constructor(@Inject(DIALOG_DATA) public data: any) {

  }
  close(): void {
    this.dialogRef.close();
  }
  private loadCmp() {
    const _injector = Injector.create({
      providers: [
        {
          provide: SLIDE_OUT_DATA_TOKEN,
          useValue: this.data
        }
      ]
    });

    return new ComponentPortal<C>(this.data.cmp as any, undefined, _injector);
  }
}
