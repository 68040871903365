import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterLink } from '@angular/router';
import {
  AccountRole,
  AuthService,
  ShowForRolesDirective,
} from '@doctorus-front-end-monorepo/auth';
import { GetAllPatientsGQL } from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';

import { RouterNavigationHelperService } from '@doctorus-front-end-monorepo/util-navigation';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs';
@Component({
  selector: 'doctorus-front-end-monorepo-patient-mini-list',
  templateUrl: './patient-mini-list.component.html',
  styleUrls: ['./patient-mini-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterLink,
    MatProgressBarModule,
    MatListModule,
    MatButtonModule,
    MatDividerModule,
    MatCardModule,
    MatIconModule,
    ReactiveFormsModule,
    HumanNamePipe,
    ShowForRolesDirective,
  ],
})
export class PatientMiniListComponent {
  loading = signal(false);
  searchCtrl = new FormControl('');
  user$ = this.as.authUserInfo$;
  dialogRef = inject(DialogRef);
  public rhns = inject(RouterNavigationHelperService);
  patients$ = this.searchCtrl.valueChanges.pipe(
    filter(x => x && x.trim().length > 0),
    map(x => x.toLowerCase().trim()),
    distinctUntilChanged(),
    debounceTime(300),
    tap(() => this.loading.set(true)),
    switchMap(term =>
      this.getPatientsGQL.fetch({
        payload: {
          search: term,
          commons: { page_size: 1000 },
        },
      }),
    ),
    tap(() => this.loading.set(false)),
    map(x => x.data.getPatients.results),
  );
  constructor(
    private getPatientsGQL: GetAllPatientsGQL,
    private as: AuthService,
  ) {}

  get hasInput(): boolean {
    return this.searchCtrl.value && this.searchCtrl.value.trim().length > 1;
  }
  accountRole = AccountRole;

  close(): void {
    this.dialogRef.close();
  }
}
