import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  inject,
  INJECTOR,
  Injector,
  ViewContainerRef,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import {
  BaseEntityDetailsComponent,
  BaseEntityFormComponent,
  EntityDeleteMutationService,
  EntityDialogService,
  PutEntityService,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  CarePlan,
  DeleteMedicalTaskGQL,
  MedicalTask,
  PutMedicalTaskGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import { SlideOutPanelService } from '@doctorus-front-end-monorepo/slide-out-panel';
import {
  ContainerComponent,
  EmptyStateComponent,
  KeyValueComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { deleteFn } from '@doctorus-front-end-monorepo/util-entity';
import { CoalescePipe } from '@doctorus-front-end-monorepo/util-formatting';
import { medicalTaskConfig } from '../entity-config';
import { WriteMedicalTaskDialogComponent } from '../write-medical-task-dialog/write-medical-task-dialog.component';

@Component({
  selector: 'lib-care-plan-details',
  imports: [
    CommonModule,
    KeyValueComponent,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    HumanNamePipe,
    MatDividerModule,
    CoalescePipe,
    ContainerComponent,
    EmptyStateComponent,
  ],
  templateUrl: './care-plan-details.component.html',
  styleUrl: './care-plan-details.component.scss',
})
export class CarePlanDetailsComponent extends BaseEntityDetailsComponent<CarePlan> {
  parentInject = inject(INJECTOR);
  medicalTaskInjector = Injector.create({
    parent: this.parentInject,
    providers: [
      { provide: PutEntityService, useClass: PutMedicalTaskGQL },
      { provide: EntityDeleteMutationService, useClass: DeleteMedicalTaskGQL },
    ],
  });

  private sds = inject(SlideOutPanelService);
  private ds = inject(EntityDialogService);
  private vcr = inject(ViewContainerRef);

  medicalTasksTitle = computed(
    () => $localize`medical tasks (${this.obj().medical_tasks?.length ?? 0})`,
  );

  writeMedicalTask(medicalTask?: MedicalTask): void {
    this.sds
      .openDialogWriteForm<
        MedicalTask,
        BaseEntityFormComponent<MedicalTask, any>,
        Pick<CarePlan, 'id' | 'medical_tasks'>
      >(
        {
          cmp: WriteMedicalTaskDialogComponent,
          entity: medicalTask,
          entityConfig: medicalTaskConfig,
          extra: {
            care_plan: this.obj(),
          },
        },
        this.medicalTaskInjector,
      )
      .subscribe(res =>
        this.obj.update(_old => ({
          ..._old,
          ...res,
        })),
      );
  }

  deleteMedicalTask(medicalTask: MedicalTask): void {
    this.ds
      .openEntityDeleteDialog(
        medicalTask,
        this.medicalTaskInjector,
        medicalTaskConfig,
        this.vcr,
      )
      .subscribe(() =>
        this.obj.update(_carePlan => ({
          ..._carePlan,
          medical_tasks: deleteFn(
            _carePlan.medical_tasks,
            medicalTask.id,
            'id',
          ),
        })),
      );
  }
}
