<div [ngClass]="classes()">
  <header [ngClass]="headerClass()">
    <section class="flex flex-wrap gap-4 items-center">
      @if (matIcon) {
        <mat-icon>{{ matIcon }}</mat-icon>
      } @else {
        <ng-content select="media"></ng-content>
      }

      <a class="mr-auto" [routerLink]="link" [class]="titleClass()">{{
        title
      }}</a>
      <ng-content select="actions"></ng-content>
    </section>
    @if (description) {
      <p class="text-gray-600">{{ description }}</p>
    } @else {
      <ng-content
        class="text-gray-600 first-letter:uppercase"
        select="description"
      ></ng-content>
    }
  </header>
  <main [ngClass]="contentClass()">
    <ng-content></ng-content>
  </main>
</div>
