<section class="grid grid-cols-4 gap-4 mb-6">
  <ui-layout-key-value key="name" i18n-key>
    {{ obj().name }}
  </ui-layout-key-value>
  <ui-layout-key-value key="unit" i18n-key>
    {{ obj().unit }}
  </ui-layout-key-value>
  <ui-layout-key-value key="description" i18n-key>
    {{ obj().description | coalesce: '-' }}
  </ui-layout-key-value>
</section>

<ui-layout-container level="2" [border]="true" [title]="observationsTitle">
  <medical-measure-measure-observations-summary
    ngProjectAs="description"
    [measure]="obj()"
  ></medical-measure-measure-observations-summary>

  <button ngProjectAs="actions" (click)="writeObservation()" mat-stroked-button>
    <mat-icon>blood_pressure</mat-icon><span i18n>new observation</span>
  </button>
  <medical-measure-measure-graph
    height="140"
    [measure]="obj()"
  ></medical-measure-measure-graph>

  <ul>
    @for (observation of obj().observations; track $index; let last = $last) {
      <li class="flex items-center p-2 justify-between">
        <div>
          <p class="font-medium">{{ observation.value }} {{ obj().unit }}</p>
          <p class="text-sm text-gray-700">
            {{ observation.date | date: 'mediumDate' }}
          </p>
        </div>
        <p class="text-sm text-gray-700" *ngIf="observation.note">
          {{ observation.note }}
        </p>
        <div class="flex gap-2">
          <button
            (click)="deleteObservation(observation)"
            color="warn"
            mat-icon-button
          >
            <mat-icon>delete</mat-icon>
          </button>
          <button (click)="writeObservation(observation)" mat-icon-button>
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </li>
      @if (!last) {
        <mat-divider></mat-divider>
      }
    }
  </ul>
</ui-layout-container>

<!-- <ui-layout-container [border]="false" [title]="title">

  <p ngProjectAs="description">{{obj().description}}</p>

  <button [mat-menu-trigger-for]="menu" ngProjectAs="actions" mat-icon-button><mat-icon i18n>more_vert
    </mat-icon></button>



  <mat-divider></mat-divider>




</ui-layout-container> -->

<!-- <mat-menu #menu="matMenu">
  <ng-template matMenuContent>

    <button [routerLink]="['edit']" mat-menu-item>
      <mat-icon>edit</mat-icon>
      <span i18n>edit</span>
    </button>
    <button mat-menu-item>
      <mat-icon>delete</mat-icon>
      <span i18n>delete</span>
    </button>
  </ng-template>
</mat-menu> -->
