<h1 class="capitalize" i18n>account settings</h1>

<ui-layout-container title="account ownership" i18n-title>
  <div ngProjectAs="actions"> <button mat-stroked-button><span i18n>edit</span></button>
  </div>

  <mat-grid-list cols="1" rowHeight="56">
    <mat-grid-tile> <ui-layout-key-value key="account owner" key-i18n>
        <div class="flex"><span>{{account().owner |humanName:'full'}}</span>
          <span>({{account().owner.email}})</span>
        </div>
      </ui-layout-key-value></mat-grid-tile>

  </mat-grid-list>
</ui-layout-container>
<ui-layout-container title="account preferences" i18n-title>
  <div ngProjectAs="actions"> <button mat-stroked-button><span i18n>edit</span></button>
  </div>

  <mat-grid-list cols="3" rowHeight="56">
    <mat-grid-tile> <ui-layout-key-value key="account alias" key-i18n>
        {{account().alias}}
      </ui-layout-key-value></mat-grid-tile>
    <mat-grid-tile> <ui-layout-key-value key="currency" key-i18n>
        {{account().currency}}
      </ui-layout-key-value></mat-grid-tile>
  </mat-grid-list>
</ui-layout-container>

<!-- <section class="rounded-md border-gray-200 p-4 border">
  <section class="flex justify-between items-center">
    <h2 class="first-letter:uppercase">account preferences</h2>
    <button mat-stroked-button><span i18n>edit</span></button>

  </section>
  <mat-grid-list cols="3" rowHeight="3:1">
    <mat-grid-tile> <ui-layout-key-value key="account alias" key-i18n>
        {{account().alias}}
      </ui-layout-key-value></mat-grid-tile>

  </mat-grid-list>

</section> -->