<section class="flex flex-col gap-6" [formArrayName]="controlKey">
  @for (control of ctrls.controls; track $index) {
  <form [formGroupName]="$index" class="flex flex-wrap gap-4 items-center">
    <mat-form-field subscriptSizing="dynamic" class="flex-auto">
      <mat-label i18n>key</mat-label>

      <input type="text" matInput formControlName="key">
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" class="flex-auto">
      <mat-label i18n>value</mat-label>

      <input type="text" matInput formControlName="value">
    </mat-form-field>
    <button type="button" (click)="removeKeyValue($index)" mat-stroked-button><span i18n>remove</span></button>



  </form>
  }
</section>