// @Component({
//   selector: 'patient-patient-details-base',
//   standalone: true,
//   imports: [CommonModule],
//   templateUrl: './patient-details-base.component.html',
//   styleUrl: './patient-details-base.component.scss',

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Injector, inject, model } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntityDialogService } from '@doctorus-front-end-monorepo/feature-entity';
import {
  FetchPatientService,
  OnAppointmentDeleteGQL,
  OnAppointmentPutGQL,
  OnAppointmentUpdateGQL,
  OnPatientAppointmentPutGQL,
  OnPatientPaimentDeleteGQL,
  OnPatientPaimentPutGQL,
  Patient,
} from '@doctorus-front-end-monorepo/graphql';
import { SlideOutPanelService } from '@doctorus-front-end-monorepo/slide-out-panel';
import {
  Subscription,
  debounceTime,
  map,
  merge,
  shareReplay,
  switchMap,
} from 'rxjs';

@Component({
  selector: 'patient-details-base',
  template: '',
  styles: '',
  standalone: true,
})
export class PatientDetailsBaseComponent {
  patient = model.required<Patient>();
  route = inject(ActivatedRoute);
  sds = inject(SlideOutPanelService);
  eds = inject(EntityDialogService);
  patientFetchService = inject(FetchPatientService);
  breakpointObserver = inject(BreakpointObserver);
  injector = inject(Injector);

  appointmentMessageMapping: { [k: string]: string } = {
    '=0': 'No appointments',
    '=1': 'One appointment',
    other: '# Appointments',
  };
  paimentMessageMapping: { [k: string]: string } = {
    '=0': 'No Paiments',
    '=1': 'One Paiment',
    other: '# Paiments',
  };
  math = Math;
  isXSmall$ = this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(
    map(result => result.matches),
    shareReplay(),
  );
  subscription = new Subscription();

  constructor(
    private onPatientAppointmentPutGQL: OnPatientAppointmentPutGQL,
    private onAppointmentUpdateGQL: OnAppointmentUpdateGQL,
    private onAppointmentPutGQL: OnAppointmentPutGQL,
    private onAppointmentDeleteGQL: OnAppointmentDeleteGQL,
    private onPatientPaimentDeleteGQL: OnPatientPaimentDeleteGQL,
    private onPatientPaimentPutGQL: OnPatientPaimentPutGQL,
  ) {
    this.subscription.add(
      merge(
        this.onPatientAppointmentPutGQL.subscribe(),
        this.onAppointmentUpdateGQL.subscribe(),
        this.onAppointmentPutGQL.subscribe(),
        this.onAppointmentDeleteGQL.subscribe(),
        this.onPatientPaimentDeleteGQL.subscribe(),
        this.onPatientPaimentPutGQL.subscribe(),
      )
        .pipe(
          debounceTime(1000),
          switchMap(() =>
            this.patientFetchService.fetch(
              { id: this.patient().id },
              {
                fetchPolicy: 'network-only',
              },
            ),
          ),
          map(res => res.data.getPatient),
        )
        .subscribe(_incoming =>
          this.patient.update(_existing => ({
            ..._existing,
            ..._incoming,
          })),
        ),
    );
  }
}
