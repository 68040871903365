// export const extractBreadcrumb = (route: ActivatedRoute) => {
//   const ROUTE_DATA_BREADCRUMB_KEY = 'breadcrumbChunck';
//   if (
//     !route.firstChild &&
//     Object.hasOwn(route.snapshot.data, ROUTE_DATA_BREADCRUMB_KEY)
//   ) {
//     return [route.snapshot.data[ROUTE_DATA_BREADCRUMB_KEY]?.name];
//   }
//   return ['-', ...extractBreadcrumb(route.firstChild)];
// };
// export const buildBreadcrumb = (
//   snapshots: ActivatedRouteSnapshot[],
// ): Breadcrumb[] =>
//   snapshots.map((x, index) => buildChunk(x, snapshots.length - index - 1));

import { FEEDBACK_MAP, MessageType } from './types';

// const buildChunk = (
//   snapshot: ActivatedRouteSnapshot,
//   index: number,
// ): Breadcrumb => ({
//   routerLink: [...Array(index).fill('..'), ...snapshot.url.map(x => x.path)],
//   name:
//     snapshot.data && typeof snapshot.data['breadcrumbChunk'] === 'function'
//       ? snapshot.data['breadcrumbChunk'](snapshot.params)
//       : snapshot.data['breadcrumbChunk'],
// });
type Tree<T> = {
  children: T[];
};

export const flattenTree = <T extends Tree<T>>(route: T): Array<T> => {
  if (route.children.length === 0) {
    return [route];
  } else {
    return [route, ...route.children.map(x => flattenTree(x)).flat()];
  }
};

export const getMessageTypeAtrrs = (type: MessageType) => FEEDBACK_MAP[type];
