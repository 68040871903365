import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IsActiveMatchOptions, RouterModule } from '@angular/router';
import { Breadcrumb } from '../types';

@Component({
  selector: 'lib-layout-breadcrumb',
  imports: [CommonModule, RouterModule, MatIconModule],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent {
  breadcrumbs = input.required<Breadcrumb[]>();
  routerLinkActiveOptions: IsActiveMatchOptions = {
    matrixParams: 'ignored',
    fragment: 'ignored',
    paths: 'exact',
    queryParams: 'ignored',
  };
}
