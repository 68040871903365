import { EntityConfig } from '@doctorus-front-end-monorepo/feature-entity';
import { Measure, Observation } from '@doctorus-front-end-monorepo/graphql';
import * as dateFns from 'date-fns';
export const measureConfig: EntityConfig<Measure> = new EntityConfig<Measure>({
  feature: $localize`measure`,
  title: (measure: Measure) => `${measure.name} (${measure.unit})`,
});
export const observationConfig: EntityConfig<Observation> =
  new EntityConfig<Observation>({
    feature: $localize`observation`,
    serializerFun: value => ({
      ...value,
      date: dateFns.formatISO(new Date(value.date), {
        representation: 'date',
      }),
    }),
  });
