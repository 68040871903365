<section class="grid grid-cols-2 gap-4">
  <ui-layout-key-value key="date" i18n-key>
    {{ obj().date | date }}
  </ui-layout-key-value>
  <ui-layout-key-value key="name" i18n-key>
    {{ obj().title | coalesce: '-' }}
  </ui-layout-key-value>

  <ui-layout-key-value class="col-span-full" key="content" i18n-key>
    <div *ngIf="obj().content">
      <quill-view
        [content]="obj().content"
        format="html"
        theme="snow"
      ></quill-view>
    </div>
  </ui-layout-key-value>
  <button
    (click)="getPdf()"
    class="col-span-full justify-self-end"
    mat-stroked-button
  >
    <mat-icon>preview</mat-icon><span i18n>preview</span>
  </button>
</section>
