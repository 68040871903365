import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';

import { EventContentArg } from '@fullcalendar/core';
import { Observable, map } from 'rxjs';
import { CalendarEvent } from '../types';
@Component({
    selector: 'medical-space-web-calendar-event',
    imports: [
    CommonModule,
    HumanNamePipe,
    MatIconModule
],
    templateUrl: './calendar-event.component.html',
    styleUrls: ['./calendar-event.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarEventComponent {
  @Input() arg!: EventContentArg & { event: CalendarEvent };
  offset!: string;
  // set timezone(obj: string) {
  //   this.offset = timezoneToOffset(obj);
  // }
  isLarge$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall])
    .pipe(map(result => !result.matches));
  //offset: string;
  /**
   *
   */
  constructor(private breakpointObserver: BreakpointObserver) {}

  addAlpha(color: string, opacity: number): string {
    // coerce values so ti is between 0 and 1.
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }
}
