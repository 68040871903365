import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import {
  GenericDocument,
  MedicalDocumentType,
  PatientDataService,
} from '@doctorus-front-end-monorepo/graphql';
import {
  ContainerComponent,
  EmptyStateComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import {
  FileBoxComponent,
  IsImagePipe,
} from '@doctorus-front-end-monorepo/util-document';
import { CoalescePipe } from '@doctorus-front-end-monorepo/util-formatting';
import { QuillViewComponent } from 'ngx-quill';

@Component({
  selector: 'doctorus-front-end-monorepo-medical-documents-list',
  imports: [
    CommonModule,
    RouterModule,
    IsImagePipe,
    EmptyStateComponent,
    CoalescePipe,
    MatListModule,
    MatDividerModule,
    MatIconModule,
    MatTableModule,
    QuillViewComponent,
    MatButtonModule,
    MatMenuModule,
    FileBoxComponent,
    ContainerComponent,
  ],
  templateUrl: './medical-documents-list.component.html',
  styleUrl: './medical-documents-list.component.scss',
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', padding: '16' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class MedicalDocumentsListComponent {
  pds = inject(PatientDataService);
  allDocuments = toSignal(this.pds.allDocuments$);
  documentType = MedicalDocumentType;

  additionalImages = computed(() => {
    return this.allDocuments()
      ?.filter(x => x.type === MedicalDocumentType.Uploaded)
      .map(x => x.media);
  });
  messageMapping: { [k: string]: string } = {
    '=0': 'No medical documents',
    '=1': 'One medical document',
    other: '# medical documents',
  };

  documentDetailsLink(document: GenericDocument): any[] {
    const prefix =
      document.type === MedicalDocumentType.Generated
        ? 'generated-document'
        : document.type === MedicalDocumentType.Prescription
          ? 'prescription'
          : 'upload-document';

    return ['.', prefix, document.id];
  }
  // expandedDocument: MedicalDocument = null;

  // injector = inject(Injector);
  // sds = inject(SlideOutPanelService);

  // uploadInjector = Injector.create({
  //   providers: [
  //     {
  //       provide: PutEntityService,
  //       useClass: PutUploadDocumentGQL,
  //     },
  //     {
  //       provide: EntityDeleteMutationService,
  //       useClass: DeleteUploadDocumentGQL,
  //     },
  //   ],
  //   parent: this.injector,
  // });
  // generatedInjector = Injector.create({
  //   providers: [
  //     {
  //       provide: PutEntityService,
  //       useClass: PutGeneratedDocumentGQL,
  //     },
  //     {
  //       provide: EntityDeleteMutationService,
  //       useClass: DeleteGeneratedDocumentGQL,
  //     },
  //   ],
  //   parent: this.injector,
  // });

  // prescriptionInjector = Injector.create({
  //   providers: [
  //     {
  //       provide: PutEntityService,
  //       useClass: PutPrescriptionGQL,
  //     },
  //     {
  //       provide: EntityDeleteMutationService,
  //       useClass: DeletePrescriptionGQL,
  //     },
  //   ],
  //   parent: this.injector,
  // });

  // newGeneratedDcoument(): void {
  //   this.sds
  //     .openSlideOutEntityWriteForm(
  //       {
  //         cmp: WriteGeneratedDocumentComponent,
  //         entityConfig: generatedDocumentConfig,
  //         extra: {
  //           patient: this.patient,
  //         },
  //       },
  //       this.generatedInjector,
  //     )
  //     .subscribe();
  // }
  // newUploadDcoument(): void {
  //   this.sds
  //     .openSlideOutEntityWriteForm(
  //       {
  //         cmp: WriteUploadDocumentComponent,
  //         entityConfig: generatedDocumentConfig,

  //         extra: {
  //           patient: this.patient,
  //         },
  //       },
  //       this.uploadInjector,
  //     )
  //     .subscribe();
  // }
  // newPrescription(): void {
  //   this.sds
  //     .openSlideOutEntityWriteForm(
  //       {
  //         cmp: WritePrescriptionFormComponent,
  //         entityConfig: prescriptionConfig,

  //         extra: {
  //           patient: this.patient,
  //         },
  //       },
  //       this.prescriptionInjector,
  //     )
  //     .subscribe();
  // }
}
