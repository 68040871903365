// @Component({
//   selector: 'patient-patient-details-base',
//   standalone: true,
//   imports: [CommonModule],
//   templateUrl: './patient-details-base.component.html',
//   styleUrl: './patient-details-base.component.scss',

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Injector, inject, input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  GetPatientMedicalRecordQuery,
  NotificationsHandlerService,
} from '@doctorus-front-end-monorepo/graphql';
import { Subscription, map, shareReplay } from 'rxjs';
import { SlideOutPanelService } from '../../../../../slide-out-panel/src';
import { EntityDialogService } from '../../../../../ui-entity-dialog/src';

@Component({
  selector: 'patient-details-base',
  template: '',
  styles: '',
  standalone: true,
})
export class PatientDetailsBaseComponent {
  patient = input.required<GetPatientMedicalRecordQuery['getPatient']>();
  route = inject(ActivatedRoute);
  sds = inject(SlideOutPanelService);
  eds = inject(EntityDialogService);
  breakpointObserver = inject(BreakpointObserver);
  injector = inject(Injector);

  appointmentMessageMapping: { [k: string]: string } = {
    '=0': 'No appointments',
    '=1': 'One appointment',
    other: '# Appointments',
  };
  paimentMessageMapping: { [k: string]: string } = {
    '=0': 'No Paiments',
    '=1': 'One Paiment',
    other: '# Paiments',
  };
  math = Math;
  isXSmall$ = this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(
    map(result => result.matches),
    shareReplay(),
  );
  nhs = inject(NotificationsHandlerService);
  subscription = new Subscription();
}
