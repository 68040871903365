<entity-list-wrapper [objs]="objs()">
  <table mat-table [dataSource]="objs()">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell i18n *matHeaderCellDef>date</th>
      <td mat-cell *matCellDef="let element">
        <a class="anchor" [routerLink]="[element.id]">{{
          element.date | date
        }}</a>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef i18n>amount</th>
      <td mat-cell *matCellDef="let element">
        {{ element.amount | accountCurrency }}
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef i18n>type</th>
      <td mat-cell *matCellDef="let element">
        {{ element.type | coalesce: '-' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</entity-list-wrapper>
