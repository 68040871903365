<mat-sidenav-container class="h-full">
  <mat-sidenav class="max-w-xs border-r border-r-gray-200" mode="side" opened>
    <h2
      i18n
      [routerLink]="['.']"
      class="p-4 border-b border-b-gray-200 hover:cursor-pointer"
    >
      patients
    </h2>

    <ui-layout-nav-tree
      [authUserRoles]="roles$ | async"
      [navLinks]="routes"
    ></ui-layout-nav-tree>
  </mat-sidenav>
  <mat-sidenav-content class="p-6">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
