<entity-list-wrapper [objs]="objs()">
  <ul class="flex flex-col gap-4">
    @for (obj of objs(); track $index) {
      <li>
        <a class="anchor text-lg" [routerLink]="[obj.id]">{{ obj.name }}</a>
        <p class="text-sm text-gray-700">
          by {{ obj.by | humanName }} on {{ obj.at | date: 'long' }}
        </p>
      </li>
      <mat-divider></mat-divider>
    }
  </ul>
</entity-list-wrapper>
