import { Pipe, PipeTransform } from '@angular/core';
import {
  E164Number,
  NumberFormat,
  parsePhoneNumberWithError,
} from 'libphonenumber-js';

@Pipe({
  name: 'phoneNumber',
  standalone: true,
})
export class PhoneNumberPipe implements PipeTransform {
  transform(
    value: E164Number | string | undefined,
    mode: NumberFormat = 'INTERNATIONAL',
  ): string {
    if (value) {
      try {
        const phoneNumber = parsePhoneNumberWithError(value);
        if (phoneNumber.isValid())
          return parsePhoneNumberWithError(value).format(mode);
        return value;
      } catch {
        return value;
      }
    }
    return '-';
  }
}
