<li
  [routerLink]="item().routerLink"
  [routerLinkActive]="['text-blue-700']"
  [fragment]="item().fragment"
  [style.padding-left]="paddingStyle()"
  class="flex items-center justify-between py-2 pr-4 hover:bg-gray-200 hover:cursor-pointer w-full hover:text-blue-700"
>
  <span class="flex items-center gap-1" [style.font-size]="fontSize()"
    >{{ item().name | titleCaseFirst }}
    @if (feedbackAttrs()) {
      <mat-icon class="text-base" [style.color]="feedbackAttrs()?.color">{{
        feedbackAttrs()?.icon
      }}</mat-icon>
    }
  </span>
  @if (hasChildren()) {
    <mat-icon>{{ icon() }}</mat-icon>
  }
</li>
