import { inject } from '@angular/core';
import { Route } from '@angular/router';
import { AccountSettingsComponent } from '../../../../libs/medical-space-web/account-settings/src';
import { AccountResolver } from '../../../../libs/medical-space-web/account-settings/src/lib/resolvers/account.resolver';
import { settingsRoutes } from '../../../../libs/medical-space-web/account-settings/src/lib/routes';
import { FeaturePatientsViewerComponent } from '../../../../libs/medical-space-web/feature-patients-viewer/src';
import { FeatureSchedulerComponent } from '../../../../libs/medical-space-web/feature-scheduler/src';
import { AccountRole, AuthService } from '../../../../libs/shared/auth/src';
import { OfficeManagerComponent } from './office-manager/office-manager.component';
import { patientsRoutes } from './routes/patient-routes';
import { StaffBoardComponent } from './staff-board/components/staff-board/staff-board.component';

export const appRoutes: Route[] = [
  {
    path: 'staff-board',
    component: StaffBoardComponent,
  },
  {
    path: '',
    redirectTo: 'staff-board',
    pathMatch: 'full',
  },
  {
    path: ':accountId',
    component: OfficeManagerComponent,
    runGuardsAndResolvers: 'always',

    resolve: {
      account: AccountResolver,
      obj: AccountResolver,
    },
    data: {
      breadcrumbChunck: { name: null },
    },
    children: [
      {
        path: 'settings',
        data: {
          breadcrumbGroup: 'primary',

          breadcrumbChunck: { name: $localize`preferences & settings` },
        },
        component: AccountSettingsComponent,
        children: settingsRoutes,
      },
      {
        path: 'scheduler',
        component: FeatureSchedulerComponent,
        data: {
          breadcrumbChunck: { name: null },
        },
      },
      {
        path: 'patients',
        data: {
          breadcrumbGroup: 'primary',
          breadcrumbChunck: { name: $localize`patients` },
        },
        component: FeaturePatientsViewerComponent,
        children: patientsRoutes,
      },
      {
        path: '',
        redirectTo: () => {
          const roles = inject(AuthService).authUserInfo()['cognito:groups'];
          if (
            roles.includes(AccountRole.DOCTOR) ||
            roles.includes(AccountRole.MEDICAL_ASSISTANT)
          ) {
            return 'scheduler';
          } else {
            return 'settings';
          }
        },
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '',
    redirectTo: 'staff-board',
    pathMatch: 'full',
  },
];
