interface RealTimeDataChangeConfig {
  soundNotify: boolean;
  visualNotify: boolean;
  visualDuration?: number;
}

export class EntityConfig<T> {
  constructor(args: Partial<EntityConfig<T>>) {
    Object.assign(this, {
      ...args,
      deletable: args.deletable ?? true,
      title: args.title ?? (() => args.feature),
      plural: args.plural ?? $localize`${args.feature}s`,
      messages: Object.assign(args.messages ?? {}, {
        title: {
          new: $localize`:@@default.message.title.new:New ${args.feature}`,
          edit: $localize`:@@default.message.title.edit:Edit ${args.feature}`,
          delete: $localize`:@@default.message.title.delete:Delete ${args.feature}`,
        },
        action: {
          created: {
            success: $localize`:@@default.message.created.success:New ${args.feature} created`,
          },
          updated: {
            success: $localize`:@@default.message.updated.success:${args.feature} updated`,
          },
          deleted: {
            confirmation: $localize`:@@default.message.deleted.confirmation:Do you really want to delete this ${args.feature}`,
            success: $localize`:@@default.message.deleted.success:${args.feature} was deleted`,
          },
        },
      }),
    });
  }
  base!: string;
  deletable!: boolean;
  feature!: string;
  plural!: string;
  title!: (entity?: T) => string;
  description?: (entity?: any) => string;

  serializerFun!: (arg: any) => any;
  realTimeDataChangeConfig?: RealTimeDataChangeConfig | undefined;
  messages?:
    | Partial<{
        title: Partial<Record<'new' | 'edit' | 'delete', string>>;
        action: Partial<
          Record<
            'created' | 'deleted' | 'updated',
            Partial<{
              buttonLabel: string;
              success: string;
              fail: string;
              confirmation: string;
            }>
          >
        >;
      }>
    | undefined;

  writeTitle = (create = false): string =>
    (create ? this.messages?.title?.new : this.messages?.title?.edit) ?? '';
  deleteTitle = (create = false): string =>
    (create ? this.messages?.title?.new : this.messages?.title?.edit) ?? '';
  onPutSuccessMsg = (update = false): string =>
    (update
      ? this.messages?.action?.updated?.success
      : this.messages?.action?.created?.success) ??
    $localize`successfully ${update ? 'updated' : 'created'} ${this.feature}`;
  onDeleteSucessMsg = (entity: any): string =>
    this.messages?.action?.deleted?.success ??
    $localize`${
      this.title &&
      (this.title instanceof Function ? this.title(entity) : this.title)
    } successfully deleted`;
}
