import { NgFor, NgIf } from '@angular/common';
import {
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { DurationInputComponent } from '@doctorus-front-end-monorepo/duration-input';
import {
  BaseEntityFormComponent,
  EntityConfig,
  IEntity,
  PutEntityService,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  AccountDataService,
  Appointment,
  CorePatientFieldsFragment,
  CreatePatientGQL,
  GetAllPatientsGQL,
  Patient,
  QueryGetAvailableSlotsArgs,
} from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import {
  IFormComponent,
  SlideOutPanelService,
} from '@doctorus-front-end-monorepo/slide-out-panel';
import { parseDuration } from '@doctorus-front-end-monorepo/util-formatting';
import {
  DURATIONS_CHOICES,
  timezoneToOffset,
} from '@doctorus-front-end-monorepo/util-time';
import { MtxSelectModule } from '@ng-matero/extensions/select';
import * as dateFns from 'date-fns';
import { isNil } from 'lodash';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  Observable,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs';
import { Temporal } from 'temporal-polyfill';
import { AppointmentInputBuilderService } from '../../services/appointment-form-builder.service';
import { AppointmentNewPatientFormComponent } from '../appointment-new-patient-form/appointment-new-patient-form.component';
import { SlotPickerComponent } from '../slot-picker/slot-picker.component';

type SlotEntry = 'select' | 'manual';
@Component({
  selector: 'appointment-write-appointment-form',
  templateUrl: './write-appointment-form.component.html',
  styleUrls: ['./write-appointment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
  imports: [
    MatFormFieldModule,
    MatButtonModule,
    MatRadioModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MtxSelectModule,
    MatProgressSpinnerModule,
    NgIf,
    NgFor,
    SlotPickerComponent,
    NgxMatSelectSearchModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    HumanNamePipe,
    MatChipsModule,
    MatDividerModule,
    DurationInputComponent,
  ],
})
export class WriteAppointmentFormComponent
  extends BaseEntityFormComponent<Appointment>
  implements OnInit
{
  slideOutPanelService = inject(SlideOutPanelService);
  getAllPatientsGQL = inject(GetAllPatientsGQL);
  cdr = inject(ApplicationRef);
  ads = inject(AccountDataService);
  fbs = inject(AppointmentInputBuilderService);
  patientMutationInjector = Injector.create({
    parent: this.cdr.injector,
    providers: [{ provide: PutEntityService, useClass: CreatePatientGQL }],
  });
  locations = this.ads.account?.locations;
  taskTypes = this.ads.account?.taskTypes;
  slotEntry = new FormControl<SlotEntry>(
    this.extra()?.entity ? 'manual' : 'select',
  );
  slots$: Observable<Date[]> | undefined;
  durations = DURATIONS_CHOICES;
  patientFilterCtrl = new FormControl('');
  //openPatientForm = false;
  slotsLoading = signal(false);
  patientsLoading = false;
  availableSlots: Date[] | undefined;
  slotSelectorCtr = new FormControl();
  override initFormFun = () =>
    this.fbs.createForm(
      this.extra()?.patient,
      this.obj(),
      this.ads.account?.appointmentDefaultTimeZone ??
        Intl.DateTimeFormat().resolvedOptions().timeZone,
    );

  patient = this.extra()?.extra?.patient ?? this.obj()?.patient_info;
  timezones = Intl.supportedValuesOf('timeZone');
  expectedDurationCtrl = new FormControl('PT30M');
  appointmentDay = new FormControl(
    dateFns.formatISO(new Date(), {
      representation: 'date',
    }),
  );
  filtredPatients = signal<Array<CorePatientFieldsFragment>>([]);

  editPatient = false;
  initData: any;
  loading = false;
  subscription = new Subscription();
  slotFetchParams: QueryGetAvailableSlotsArgs | null = null;
  override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.slotSelectorCtr.valueChanges
        .pipe(filter(x => (x ? true : false)))
        .subscribe((x: string) => this.form.get('start')?.setValue(x)),
    );
    this.subscription.add(
      this.form
        ?.get('start')
        ?.valueChanges.pipe(
          filter(x => (x ? true : false)),
          distinctUntilChanged(),
        )
        .subscribe(x => {
          if (x) {
            this.form.controls['end'].setValue(
              dateFns.add(
                new Date(x),
                parseDuration(this.expectedDurationCtrl.value ?? 'PT30M'),
              ),
            );
          }
        }),
    );

    this.subscription.add(
      this.patientFilterCtrl.valueChanges
        .pipe(
          filter(x => (x ? true : false)),
          tap(() => (this.patientsLoading = true)),
          debounceTime(200),
          switchMap(x =>
            this.getAllPatientsGQL
              .fetch({
                payload: {
                  commons: { page_size: 500 },
                  search: x?.toString() ?? '',
                },
              })
              .pipe(finalize(() => (this.patientsLoading = false))),
          ),
          map(res => res.data.getPatients?.results),
        )
        .subscribe(x => this.filtredPatients.set(x ?? [])),
    );
    this.filtredPatients.set(
      [this.obj()?.patient_info ?? this.extra()?.patient].filter(
        x => !isNil(x),
      ),
    );

    if (this.extra()?.date) {
      this.appointmentDay.setValue(
        dateFns.formatISO(this.extra()?.date, { representation: 'date' }),
      );
    }

    if (this.obj()) {
      this.slotSelectorCtr.setValue(this.obj()?.start, {
        emitEvent: false,
      });
      this.appointmentDay.setValue(
        dateFns.formatISO(this.obj()?.start, { representation: 'date' }),
      );
      const start = Temporal.Instant.from(this.obj()?.start);
      const end = Temporal.Instant.from(this.obj()?.end);
      this.expectedDurationCtrl.setValue(
        start
          .until(end, {
            largestUnit: 'hours',
            smallestUnit: 'minutes',
          })
          .toString(),
      );
    }

    this.loadParams();
  }

  displayFn(patient: Patient): string {
    return patient && `${patient.given_name} ${patient.family_name}`;
  }
  toggleEditPatient(): void {
    this.editPatient = true;
  }

  updateExpectedDuration(): void {
    const taskType = this.taskTypes?.find(
      x => x.id === this.form.controls['task_type_id']?.value,
    );
    if (taskType && taskType.default_duration) {
      console.warn(taskType.default_duration);
      this.expectedDurationCtrl.setValue(taskType.default_duration);
      this.loadParams();
    }
  }

  private toSlotFetchParams(): QueryGetAvailableSlotsArgs | null {
    const accountlocation = this.locations?.find(
      x => x.id === this.form.controls['location_id'].value,
    );
    const startDate = this.appointmentDay?.value;
    const expectedDuration = this.expectedDurationCtrl.value;
    if (
      accountlocation &&
      accountlocation.slot_interval &&
      accountlocation.working_periods &&
      startDate &&
      expectedDuration
    ) {
      return {
        ...(this.obj && { appointment: this.obj()?.id.toString() }),
        date: startDate,
        timezone: this.form.get('timezone')?.value as string,
        duration: expectedDuration,
        location_id: accountlocation.id,
        slot_interval: accountlocation.slot_interval,
        working_periods: accountlocation.working_periods as number[][],
      };
    } else {
      return null;
    }
  }
  loadParams(): void {
    this.slotFetchParams = this.toSlotFetchParams();
  }
  get offset() {
    return timezoneToOffset(
      this.form.controls['timezone'].value ??
        Intl.DateTimeFormat().resolvedOptions().timeZone,
    );
  }
  openPatientForm(event: MouseEvent): void {
    this.slideOutPanelService
      .openSlideOutEntityWriteForm<IEntity, IFormComponent, Patient>(
        {
          cmp: AppointmentNewPatientFormComponent,
          entityConfig: new EntityConfig({
            feature: 'patient',
          }),
        },
        this.patientMutationInjector,
      )
      .subscribe(_newPatient => {
        console.warn(_newPatient);
        this.filtredPatients.update(_patients =>
          _newPatient ? _patients.concat(_newPatient) : _patients,
        );
        this.form.get('patient')?.setValue(_newPatient?.id);
      });
    event.stopPropagation();
  }

  compareStr = (a: string, b: string) =>
    a.toLowerCase().trim() === b.toLowerCase().trim();
}
