<form [formGroup]="form">
  <ui-layout-container [border]="false" [title]="propertiesTitle">
    <button
      (click)="inlineArray.pushNewForm()"
      ngProjectAs="actions"
      mat-stroked-button
    >
      <mat-icon>add_circle</mat-icon>
      <span i18n>add medical property</span>
    </button>

    <lib-inline-form-array
      #inlineArray
      [init]="init()"
      [min]="0"
      [extra]="1"
      [formBuildFn]="createProperty"
      controlKey="medical_properties"
    >
      <ng-template let-index="index" #fields>
        <patient-property-inline-form
          [index]="index"
          formArrayKey="medical_properties"
        ></patient-property-inline-form>
      </ng-template>
    </lib-inline-form-array>
  </ui-layout-container>
</form>
