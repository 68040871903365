import { Route } from '@angular/router';
import { BreadcrumbRouteData } from '@doctorus-front-end-monorepo/breadcrumb';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityDeleteMutationService,
  PutEntityService,
  WriteWrapperComponent,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  DeletePhoneNumberGQL,
  PutPhoneNumberGQL,
  resolveMedicalRecordItem,
} from '@doctorus-front-end-monorepo/graphql';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { contactConfig } from './entity-config';
import { WritePhoneNumberComponent } from './write-phone-number/write-phone-number.component';

export const featureContactRoutes: Route = {
  path: 'contacts',
  children: [
    {
      path: '',
      providers: [
        {
          provide: ENTITY_CONFIG,
          useValue: contactConfig,
        },
        {
          provide: EntityDeleteMutationService,
          useClass: DeletePhoneNumberGQL,
        },
        {
          provide: PutEntityService,
          useClass: PutPhoneNumberGQL,
        },
        {
          provide: ENTITY_DETAILS_COMPONENT,
          useValue: ContactDetailsComponent,
        },
        {
          provide: ENTITY_WRITE_COMPONENT,
          useValue: WritePhoneNumberComponent,
        },
      ],
      data: {
        breadcrumbChunck: {
          name: $localize`contacts`,
        },
      },
      children: [
        {
          path: '',
          component: ContactListComponent,
          data: {
            breadcrumbChunck: {
              name: null,
            },
          },
        },
        {
          path: 'new',
          component: WriteWrapperComponent,
          data: {
            obj: null,
            breadcrumbChunck: {
              name: $localize`new`,
            } as BreadcrumbRouteData,
          },
        },
        {
          path: ':id',
          runGuardsAndResolvers: 'always',

          resolve: {
            obj: resolveMedicalRecordItem('phone_numbers'),
          },
          data: {
            breadcrumbChunck: {
              name: $localize`contact:{{obj.phone_number}}`,
            } as BreadcrumbRouteData,
          },
          children: [
            {
              path: '',
              component: DetailsWrapperComponent,
              data: {
                deleteRedirect: ['..'],

                breadcrumbChunck: {
                  name: null,
                } as BreadcrumbRouteData,
              },
            },
            {
              path: 'edit',
              component: WriteWrapperComponent,
              data: {
                breadcrumbChunck: {
                  name: $localize`edit`,
                } as BreadcrumbRouteData,
              },
            },
          ],
        },
      ],
    },
  ],
  data: {
    breadcrumbChunck: { name: null },
  },
};
