import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { BaseEntityDetailsComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { Account } from '@doctorus-front-end-monorepo/graphql';
import {
  ContainerComponent,
  KeyValueComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { CountryInfoPipe } from '@doctorus-front-end-monorepo/util-country';
import { CoalescePipe } from '@doctorus-front-end-monorepo/util-formatting';
import { TimezoneOffsetPipe } from '@doctorus-front-end-monorepo/util-time';

@Component({
  selector: 'account-settings-forms-preferences',
  imports: [
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    ContainerComponent,
    CoalescePipe,
    MatFormFieldModule,
    CountryInfoPipe,
    TimezoneOffsetPipe,
    KeyValueComponent,
    MatSelectModule,
    MatGridListModule,
  ],
  templateUrl: './forms-preferences.component.html',
  styleUrl: './forms-preferences.component.scss',
})
export class FormsPreferencesComponent extends BaseEntityDetailsComponent<Account> {}
