import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import {
  AccountDataService,
  allChecks,
  PatientDataService,
} from '@doctorus-front-end-monorepo/graphql';
import { FeedbackComponent } from '@doctorus-front-end-monorepo/ui-layout';

import { toSignal } from '@angular/core/rxjs-interop';
import { ListWrapperComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { map } from 'rxjs';
import { MeasureGraphComponent } from '../measure-graph/measure-graph.component';
import { MeasureObservationsSummaryComponent } from '../measure-observations-summary.component';

@Component({
  selector: 'medical-measure-feature-medical-measure',
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    RouterModule,
    MatButtonModule,
    MeasureGraphComponent,
    MatTableModule,
    MeasureObservationsSummaryComponent,
    ListWrapperComponent,
    FeedbackComponent,
  ],
  templateUrl: './feature-medical-measure.component.html',
  styleUrl: './feature-medical-measure.component.scss',
})
export class FeatureMedicalMeasureComponent {
  objs = toSignal(
    inject(PatientDataService)?.selectedPatient$.pipe(
      map(x => x?.measures ?? []),
    ),
  );

  measureModels = inject(AccountDataService).account?.measureModels ?? [];

  data = computed(() =>
    allChecks(this.objs() ?? [], this.measureModels, 'name', 'name'),
  );
}
