import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BaseEntityDetailsComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { Patient } from '@doctorus-front-end-monorepo/graphql';
import { EmptyStateComponent } from '@doctorus-front-end-monorepo/ui-layout';
import { QuillViewComponent } from 'ngx-quill';

@Component({
  selector: 'patient-medical-note-details',
  imports: [CommonModule, QuillViewComponent, EmptyStateComponent],
  template: `@if (obj().medical_notes; as notes) {
      <quill-view [content]="notes" format="html" theme="snow"></quill-view>
    } @else {
      <ui-layout-empty-state>
        no medical notes for this patient
      </ui-layout-empty-state>
    }`,
  styles: ``,
})
export class MedicalNoteDetailsComponent extends BaseEntityDetailsComponent<
  Pick<Patient, 'id' | 'medical_notes'>
> {}
