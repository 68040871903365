import { CommonModule } from '@angular/common';
import { Component, model } from '@angular/core';

@Component({
  selector: 'entity-base-entity-list',
  imports: [CommonModule],
  template: `<p>base-entity-list works!</p>`,
  styles: ``,
})
export class BaseEntityListComponent<T> {
  objs = model<Array<T>>();
}
