import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AccountDataService } from '@doctorus-front-end-monorepo/auth';
import { Appointment, Patient } from '@doctorus-front-end-monorepo/graphql';
import { phoneNumberValidator } from '@doctorus-front-end-monorepo/util-form';
import * as dateFns from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class AppointmentInputBuilderService {
  constructor(
    private fb: FormBuilder,
    private ads: AccountDataService,
  ) {}
  createForm(
    patient?: Patient,
    entity?: Partial<Appointment> | undefined,
    timezone?: string,
  ) {
    return this.fb.group(
      {
        id: [entity?.id],
        patient: [patient?.id ?? entity?.patient_info?.id, Validators.required],
        start: [
          entity?.start ? new Date(entity?.start) : undefined,
          [Validators.required],
        ],
        end: [
          entity?.end ? new Date(entity?.end) : undefined,
          [Validators.required],
        ],
        timezone: [
          entity?.timezone ??
            timezone ??
            Intl.DateTimeFormat().resolvedOptions().timeZone,
          [Validators.required],
        ],
        location_id: [
          entity?.location?.id ??
            this.ads.account?.appointmentDefaultLocation?.id,
          [Validators.required],
        ],
        task_type_id: [
          entity?.task_type?.id ??
            this.ads.account?.appointmentDefaultTaskType?.id,
          Validators.required,
        ],
      },
      {
        validators: [this.endAfterStartValidator, this.patientValidator],
      },
    );
  }

  patientMiniForm = (): FormGroup =>
    this.fb.group({
      given_name: ['', Validators.required],
      family_name: ['', Validators.required],
      phone_number: ['', phoneNumberValidator],
    });

  patientValidator: ValidatorFn = (
    control: FormGroup,
  ): ValidationErrors | null => {
    return control &&
      (control.get('patient')?.value || control.get('newPatient')?.valid)
      ? null
      : { patientRequired: true };
  };

  endAfterStartValidator: ValidatorFn = (
    control: FormGroup,
  ): ValidationErrors | null => {
    const start = control && control.get('start').value;
    const end = control && control.get('end').value;
    if (start && end) {
      return dateFns.compareAsc(end, start) === 1
        ? null
        : { endAfterStart: true };
    }
    return null;
  };

  createMedicalForm(entity: Partial<Appointment>): FormGroup {
    return this.fb.group({
      note: [entity?.note],
      status: [entity.status, Validators.required],
      fees: [entity.fees],
      task_type: [entity?.task_type],
      description: [entity?.description],
    });
  }

  slotRequiredValidator: ValidatorFn = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    return control && control.value ? null : { slotRequired: true };
  };
}
