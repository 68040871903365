/* eslint-disable @angular-eslint/directive-selector */
import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { isNil } from 'lodash';
import { Subscription, distinctUntilChanged, filter, map } from 'rxjs';
import { AuthService } from './auth-service/auth.service';
import { AccountRole } from './types';
@Directive({
  selector: '[showForRoles]',
  standalone: true,
})
export class ShowForRolesDirective implements OnInit, OnDestroy {
  @Input({ required: true }) showForRoles!: string[];

  private user$ = inject(AuthService).authUserInfo$;
  private sub = new Subscription();
  //@Input() hasRoles?: AccountRole[] | undefined;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
  ) {}
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  ngOnInit(): void {
    this.sub.add(
      this.user$
        .pipe(
          map(x => (x ? x['cognito:groups'] : null)),

          filter(x => !isNil(x)),

          distinctUntilChanged(),
        )
        .subscribe(roles => {
          this.viewContainer.clear();
          if (
            this.showForRoles &&
            roles?.some((role: AccountRole) =>
              this.showForRoles?.includes(role),
            )
          ) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          }
        }),
    );
  }
}
