import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthError,
  fetchAuthSession,
  getCurrentUser,
  signOut,
  updateUserAttributes,
} from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { BehaviorSubject, map } from 'rxjs';
import { AccountRole, UserAttributes } from '../types';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authenticationSubject = new BehaviorSubject<boolean>(false);
  private userSubject = new BehaviorSubject<UserAttributes | undefined>(
    undefined,
  );

  constructor(private router: Router) {
    this.loadUser();
    Hub.listen('auth', async data => {
      switch (data.payload.event) {
        case 'signedIn':
          await this.loadUser();

          this.authenticationSubject.next(true);
          this.router.navigate(['/']);
          break;

        case 'signedOut':
          this.authenticationSubject.next(false);
          this.userSubject.next(undefined);
          break;

        case 'tokenRefresh':
          await this.loadUser();
          this.authenticationSubject.next(true);
      }
    });
  }

  get user$() {
    return this.userSubject.asObservable();
  }

  async currentAuthenticatedUser() {
    try {
      const user = await getCurrentUser();
      return user;
    } catch (err) {
      console.log(err);
      return undefined;
    }
  }

  async isLoggedIn() {
    try {
      const { username, userId, signInDetails } = await getCurrentUser();
      return true;
    } catch (err) {
      console.warn(err);
      return false;
    }
  }

  authUserInfo$ = this.userSubject.asObservable();
  authUserRoles$ = this.userSubject
    .asObservable()
    .pipe(map(_user => _user?.['cognito:groups']));
  get authUserInfo() {
    return this.userSubject.getValue();
  }
  get isAuthUserDoctor() {
    return (
      this.authUserInfo?.['cognito:groups']?.includes(AccountRole.DOCTOR) ??
      false
    );
  }

  get authUserGroups() {
    return this.authUserInfo?.['cognito:groups'] ?? [];
  }

  isAuthenticated$ = this.authenticationSubject.asObservable();

  async fetchTokens(forceRefresh = false) {
    try {
      const val = (await fetchAuthSession({ forceRefresh })).tokens;
      return val;
    } catch (err) {
      return undefined;
    }
  }

  async updateUser(payload: Partial<UserAttributes>) {
    try {
      const attrs: any = {};
      if (payload.account_id) {
        attrs['custom:account_id'] = payload.account_id;
      }
      delete payload.account_id;
      await updateUserAttributes({
        userAttributes: {
          ...payload,
          ...attrs,
        },
      });
      await this.fetchTokens(true);

      return this.loadUser();
    } catch (err: any) {
      if ((err as AuthError).name === 'NotAuthorizedException') {
        await this.signOut();
      }
      return undefined;
    }
  }

  async signOut() {
    try {
      await signOut();
    } catch (err) {
      console.warn(err);
    }
  }

  async loadUser() {
    const idToken = (await this.fetchTokens())?.idToken
      ?.payload as UserAttributes;
    this.userSubject.next(idToken);
    return idToken;
  }
  // public signout(): Promise<any> {
  //   return Auth.signOut({
  //     global: true,
  //   }).then(x => {
  //     this.authenticationSubject.next(false);
  //     this.userSubject.next(undefined);
  //   });
  // }

  // get isAuthenticated(): Observable<boolean> {
  //   return this.authenticationSubject.asObservable();
  // }
}
