import { EntityConfig } from '@doctorus-front-end-monorepo/feature-entity';
import { Appointment, Patient } from '@doctorus-front-end-monorepo/graphql';
import { STATUS_CONF } from '@doctorus-front-end-monorepo/shared-type';
import { humanize } from '@doctorus-front-end-monorepo/shared-util';
import * as dateFns from 'date-fns';

export type AppointmentDialogData = {
  appointment?: Appointment;
  patient?: Patient;
};

export const appointmentConfig = new EntityConfig<Appointment>({
  //baseUri: 'office/appointments/',
  feature: $localize`appointment`,
  plural: $localize`appointment`,
  title: (entity: Appointment): string =>
    $localize`${
      entity.patient_info ? humanize(entity.patient_info, 'medium', true) : ''
    } ${dateFns.format(new Date(entity.start), 'yyyy-LL-dd kk:mm')} ( ${
      STATUS_CONF.appointment[entity.status].label
    } )`,
  realTimeDataChangeConfig: {
    soundNotify: false,
    visualNotify: true,
    visualDuration: 5000,
  },
  messages: {
    title: {
      new: $localize`:@@appointment.message.title.new:New appointment`,
      edit: $localize`:@@appointment.message.title.edit:Edit appointment`,
    },
    action: {
      created: {
        success: $localize`:@@appointment.message.created.success:appointment was successfully created`,
      },
      updated: {
        success: $localize`:@@appointment.message.updated.success:appointment updated`,
      },
      deleted: {
        confirmation: $localize`:@@appointment.message.deleted.confirmation:Do you really want to delete this appointment ?`,
        success: $localize`:@@appointment.message.deleted.success:appointment was deleted`,
      },
    },
  },
});
