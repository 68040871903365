import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService, UserAttributes } from '@doctorus-front-end-monorepo/auth';
import {
  AccountDataService,
  GetAccountGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { firstValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountResolver {
  constructor(
    private as: AuthService,
    private oss: AccountDataService,
    private getAccountGql: GetAccountGQL,
  ) {}
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const accountId = route.paramMap.get('accountId') as string;
    const userAttrs = (await this.as.loadUser()) as UserAttributes;
    if (
      !Object.hasOwn(userAttrs, 'custom:account_id') ||
      !(userAttrs['custom:account_id'] === accountId)
    ) {
      await this.as.updateUser({
        account_id: accountId?.toString(),
      });
    }
    this.oss.currentAccountId = accountId;

    return await firstValueFrom(
      this.getAccountGql
        .fetch({ id: accountId }, { fetchPolicy: 'cache-first' })
        .pipe(map(x => x.data.getAccount)),
    );
  }
}
