import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BaseEntityDetailsComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { PatientPayment } from '@doctorus-front-end-monorepo/graphql';
import { KeyValueComponent } from '@doctorus-front-end-monorepo/ui-layout';
import {
  AccountCurrencyPipe,
  CoalescePipe,
} from '@doctorus-front-end-monorepo/util-formatting';

@Component({
  selector: 'lib-patient-payment-details',
  imports: [CommonModule, KeyValueComponent, AccountCurrencyPipe, CoalescePipe],
  templateUrl: './patient-payment-details.component.html',
  styleUrl: './patient-payment-details.component.scss',
})
export class PatientPaymentDetailsComponent extends BaseEntityDetailsComponent<PatientPayment> {}
