<entity-list-wrapper [objs]="objs()">
  <table mat-table class="full-width-table" matSort aria-label="Elements">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
      <td mat-cell *matCellDef="let row">
        @if (row.obj) {
          <a class="anchor" [routerLink]="[row.obj.id]">{{ row.name }}</a>
        } @else {
          {{ row.name }}
        }
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>date</th>
      <td mat-cell *matCellDef="let row">
        @if (row.obj) {
          {{ row.obj.date | date }}
        } @else {
          -
        }
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="content">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>content</th>
      <td mat-cell *matCellDef="let row">
        @if (row.obj?.content) {
          <quill-view-html [content]="row.obj.content"></quill-view-html>
        } @else {
          <ui-layout-feedback
            i18n-message
            withIcon="false"
            message="this medical history must be checked"
            pattern="2"
            type="4"
          ></ui-layout-feedback>
        }
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let row">
        @if (!row.obj) {
          <button mat-flat-button><span i18n>check</span></button>
        }
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let row">
        @if (row.obj) {
          status
        } @else {
          <button
            [routerLink]="['new']"
            [queryParams]="{ name: row.name }"
            mat-flat-button
          >
            <span i18n>check</span>
          </button>
        }
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    #paginator
    [length]="dataSource.data.length"
    [pageIndex]="0"
    [pageSize]="50"
    aria-label="Select page"
  >
  </mat-paginator>
</entity-list-wrapper>
