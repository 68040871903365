@if(appointments()|arrayExist) {
<ul class="flex flex-col gap-2">
  @for (appointment of appointments(); track $index;let last = $last) {
  <li (click)="selected.set(appointment)" [class.hover:bg-gray-100]="selectable"
    [class.hover:cursor-pointer]="selectable" class="px-1 py-2 rounded-md ease-out flex flex-col gap-2 ">
    <div class="flex items-center gap-2">
      <button [mat-menu-trigger-for]="actionMenu" [matMenuTriggerData]="{appointment}"
        mat-icon-button><mat-icon>more_vert</mat-icon></button>

      <div class="flex flex-col">

        <appointment-dates [timeOnly]="timeOnly" class=" text-base font-medium"
          [appointment]="appointment"></appointment-dates>
        @if (includePatientInfo) {
        @if(appointment.patient_info) {
        <a [routerLink]="rnhs.getPatientRoute(appointment.patient_info.id)"
          class="text-gray-700 text-sm anchor">{{appointment.patient_info|humanName:'full':true}}</a>

        }

        }
      </div>
      @if(includeMedicalBoardLink) {
      <a *showForRoles="[accountRole.DOCTOR]" class="text-sm self-start ml-auto anchor"
        [routerLink]="rnhs.medicalRecordRoute(appointment.patient_info?.id,appointment.id)" i18n>medical records</a>

      }
    </div>
    <mat-chip-set class="self-end mat-chip-sm text-sm">

      <appointment-status-picker (statusChange)="updateStatus($event,appointment)" [status]="appointment.status">
        @if (appointment.status|resolveStatusConfig:'appointment';as status) {
        <mat-chip>
          <mat-icon matChipAvatar [style.color]="status.color">{{status.svgIcon}}</mat-icon>
          <p [style.color]="status.color">{{status.label}}</p>


        </mat-chip>
        }

      </appointment-status-picker>
      @if(includeMeta) {
      @if(appointment.task_type) {
      <mat-chip>
        <p matChipAvatar class="w-3 h-3 rounded-full" [style.background-color]="appointment.task_type.color"></p>
        <p class="chip-text">{{appointment.task_type.name}}</p>


      </mat-chip>

      }

      @if(appointment.location) {
      <mat-chip *authShowForMultiLocations>
        <mat-icon matChipAvatar>location_on</mat-icon>

        <p class="chip-text">{{appointment.location.name}}</p>

      </mat-chip>

      }
      @if(appointment.fees) {
      <mat-chip>
        <mat-icon matChipAvatar>payments</mat-icon>
        <p class="chip-text"> {{appointment.fees}}</p>

      </mat-chip>

      }
      @if(appointment.description) {
      <mat-chip>
        <mat-icon matChipAvatar>medical_information</mat-icon>
        <p class="chip-text">{{appointment.description}}</p>

      </mat-chip>

      }
      }

    </mat-chip-set>

  </li>
  @if (!(last || appointments.length === 1)) {
  <mat-divider></mat-divider>
  }
  }
</ul>
}
<mat-menu #actionMenu>
  <ng-template matMenuContent let-appointment="appointment">
    <button (click)="writeAppointment(appointment)" mat-menu-item>
      <mat-icon>edit</mat-icon>
      <span i18n>edit</span>
    </button>
    <button (click)="deleteAppointment(appointment)" mat-menu-item>
      <mat-icon>delete</mat-icon>
      <span i18n>delete</span>
    </button>
  </ng-template>
</mat-menu>
