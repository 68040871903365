import { CommonModule } from '@angular/common';
import { Component, inject, model } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNil } from 'lodash';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { takeWhile } from 'rxjs';
import {
  Account,
  UpdatePreferencesGQL,
} from '../../../../../../shared/data-access/graphql/src';

import {
  ContainerComponent,
  KeyValueComponent
} from '../../../../../../shared/ui-layout/src';
import { UpdateGeneralSettingsDialogComponent } from '../update-general-settings-dialog.component';
@Component({
    selector: 'account-settings-general-account-settings',
    imports: [
    CommonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatGridListModule,
    ContainerComponent,
    NgxMatSelectSearchModule,
    MatInputModule,
    KeyValueComponent,
    ReactiveFormsModule,
    MatSelectModule
],
    templateUrl: './general-account-settings.component.html',
    styleUrl: './general-account-settings.component.scss'
})
export class GeneralAccountSettingsComponent {
  account = model.required<Account>();
  matSnackBar = inject(MatSnackBar);
  fb = inject(FormBuilder);
  matDialog = inject(MatDialog);
  updateAccountSettingsGql = inject(UpdatePreferencesGQL);

  form = this.fb.group({
    currency: [''],
    alias: [''],
    defaultPhoneCountryCode: [''],
  });

  updateSettings(): void {
    this.matDialog
      .open(UpdateGeneralSettingsDialogComponent, {
        data: {
          account: this.account(),
        },
      })
      .afterClosed()
      .pipe(takeWhile(x => !isNil(x)))
      .subscribe(account =>
        this.account.update(_account => ({
          ..._account,
          ...account,
        })),
      );
  }
  // constructor() {
  //   this.account$
  //     .pipe(first())
  //     .pipe(
  //       tap(x => {
  //         this.form.patchValue(
  //           {
  //             currency: x?.currency,
  //             alias: x?.alias,
  //             defaultPhoneCountryCode: x?.defaultPhoneCountryCode,
  //           },
  //           { emitEvent: false },
  //         );
  //       }),
  //       switchMap(x =>
  //         this.form.valueChanges.pipe(
  //           debounceTime(200),
  //           switchMap(x =>
  //             this.updateAccountSettingsGql.mutate({
  //               payload: x,
  //             }),
  //           ),
  //           tap(() =>
  //             this.matSnackBar.open(
  //               $localize`account preferences updated`,
  //               undefined,
  //               {
  //                 duration: 5000,
  //               },
  //             ),
  //           ),
  //         ),
  //       ),
  //     )
  //     .subscribe();
  // }
}
