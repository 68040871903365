<div *ngIf="(patient$|async) as patient" class=" grid grid-cols-12 gap-3 items-stretch">
  <mat-accordion class="w-full col-span-4 column">
    <mat-expansion-panel expanded hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="flex gap-2 items-center">
            <!-- <mat-icon>info</mat-icon> -->

            <span class="first-letter:uppercase" i18n>public properties ({{patient.public_properties?.length ??
              0}})</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="panel-container properties-container">
        <ui-layout-key-value key="birthdate" key-i18n>
          {{patient.birthdate | date}}
        </ui-layout-key-value>
        @for (property of patient.public_properties; track $index) {
        <ui-layout-key-value [key]="property.key" key-i18n>
          {{property.value}}
        </ui-layout-key-value>
        }
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel expanded hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="flex gap-2 items-center">
            <!-- <mat-icon>info</mat-icon> -->

            <span class="first-letter:uppercase" i18n>medical properties ({{patient.medical_properties?.length ??
              0}})</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="panel-container">
        @if (patient.medical_properties) {
        <div class="properties-container">
          @for (property of patient.medical_properties; track $index) {
          <ui-layout-key-value [key]="property.key" key-i18n>
            {{property.value}}
          </ui-layout-key-value>
          }
        </div>
        }@else {
        <ui-layout-empty-state>
          <span i18n class="first-letter:uppercase">no medical properties for this patients</span>
          <button ngProjectAs="actions" (click)="editMedicalProperties(patient)" mat-stroked-button><span i18n>add
              medical properties
            </span></button>
        </ui-layout-empty-state>
        }

      </div>
      <mat-action-row>
        <button (click)="editMedicalProperties(patient)" mat-button>edit medical properties</button>
      </mat-action-row>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div [class.text-amber-500]="uncheckedMedicalHistory()" class="flex gap-2 items-center">
            @if ((uncheckedMedicalHistory())) {
            <mat-icon>report</mat-icon>

            }

            <span class="first-letter:uppercase" i18n>histories ({{patient.medical_histories?.length ?? 0}})</span>

          </div>

        </mat-panel-title>

        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="panel-container">
        <medical-history-medical-history-list [patient]="$any(patient)"></medical-history-medical-history-list>
      </div>
      <mat-action-row>
        <button mat-button>new medical history</button>
      </mat-action-row>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="flex gap-2 items-center">
            <!-- <mat-icon>event</mat-icon> -->

            <span class="first-letter:uppercase" i18n>appointments ({{patient.appointments?.length ?? 0}})</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="panel-container">
        <appointment-appointments-list selectable="true" #appointmentsList="appointmentsList"
          [(selected)]="selectedAppointment" mode="medical-record" [patient]="patient"
          [appointments]="patient.appointments"></appointment-appointments-list>
      </div>


      <mat-action-row>
        <button (click)="appointmentsList.writeAppointment()" mat-button>new appointment</button>
      </mat-action-row>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="flex gap-2 items-center">
            <!-- <mat-icon>payments</mat-icon> -->

            <span class="first-letter:uppercase" i18n>payments ({{patient.payments?.length ?? 0}})</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="panel-container">
        <doctorus-front-end-monorepo-patient-payment-list #paymentsList="paymentsList" [patient]="patient"
          [entities]="patient.payments"></doctorus-front-end-monorepo-patient-payment-list>
      </div>

      <mat-action-row>
        <button (click)="paymentsList.onWrite()" mat-button>new payment</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
  <div [class]="selectedAppointment() ? 'col-span-5':'col-span-8'"
    class="entity-key-value-container p-0  h-full   column  bg-white">
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
      @for (link of links; track link) {
      <a routerLinkActive #routerLinkActiveVar="routerLinkActive" [active]="routerLinkActiveVar.isActive"
        [routerLink]="['.',{outlets:{center:link.routerLink}}]" mat-tab-link> {{link.name}} </a>
      }
    </nav>
    <mat-tab-nav-panel #tabPanel>
      <div class="center-outlet relative"> <router-outlet name="center"></router-outlet>
      </div>
    </mat-tab-nav-panel>

  </div>


  @if (selectedAppointment()) {
  <aside class="bg-white flex-grow w-full col-span-3 max-w-md h-full">
    <appointment-medical-record-appointment-details [patient]="patient" (deselect)="selectAppointment()"
      [appointment]="selectedAppointment()"></appointment-medical-record-appointment-details>


  </aside>
  }
</div>