import { Route } from '@angular/router';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_LIST_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityConfig,
  EntityDeleteMutationService,
  ListWrapperComponent,
  PutEntityService,
  WriteWrapperComponent,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  DeleteDocumentModelGQL,
  PutDocumentModelGQL,
  resolveAccountSettingsCollection,
  resolveAccountSettingsItem,
} from '@doctorus-front-end-monorepo/graphql';
import { DocumentModelDetailsComponent } from './document-model-details.component';
import { DocumentModelFormComponent } from './document-model-form.component';
import { UiDocumentModelsComponent } from './ui-document-models/ui-document-models.component';

export const uiDocumentModelsRoutes: Route = {
  path: 'document-models',
  data: {
    breadcrumbChunck: { name: $localize`document model` },
  },
  providers: [
    {
      provide: ENTITY_CONFIG,
      useValue: new EntityConfig({ feature: $localize`document model` }),
    },
    {
      provide: ENTITY_LIST_COMPONENT,
      useValue: UiDocumentModelsComponent,
    },
    {
      provide: ENTITY_WRITE_COMPONENT,
      useValue: DocumentModelFormComponent,
    },
    {
      provide: PutEntityService,
      useClass: PutDocumentModelGQL,
    },
  ],
  children: [
    {
      path: '',
      component: ListWrapperComponent,
      resolve: {
        objs: resolveAccountSettingsCollection('documentModels'),
      },
      data: {
        breadcrumbChunck: { name: null },
      },
    },
    {
      path: 'new',
      component: WriteWrapperComponent,
      data: {
        successRedirect: ['..'],
        cancelRedirect: ['..'],
        obj: null,
        breadcrumbChunck: { name: $localize`new` },
      },
    },
    {
      path: ':id',
      runGuardsAndResolvers: 'always',

      resolve: {
        obj: resolveAccountSettingsItem('documentModels'),
      },
      data: {
        deleteRedirect: ['..'],

        breadcrumbChunck: { name: $localize`document model:{{obj.name}}` },
      },
      providers: [
        {
          provide: ENTITY_DETAILS_COMPONENT,
          useValue: DocumentModelDetailsComponent,
        },
      ],
      children: [
        {
          path: '',
          data: {
            breadcrumbChunck: { name: null },
          },
          providers: [
            {
              provide: EntityDeleteMutationService,
              useClass: DeleteDocumentModelGQL,
            },
          ],
          component: DetailsWrapperComponent,
        },
        {
          path: 'edit',
          data: {
            breadcrumbChunck: { name: $localize`edit` },
          },
          component: WriteWrapperComponent,
        },
      ],
    },
  ],
};
