import { Route } from '@angular/router';
import { BreadcrumbRouteData } from '@doctorus-front-end-monorepo/breadcrumb';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityDeleteMutationService,
  PutEntityService,
  WriteWrapperComponent,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  DeleteGeneratedDocumentGQL,
  PutGeneratedDocumentGQL,
  resolveMedicalRecordItem,
} from '@doctorus-front-end-monorepo/graphql';
import { GeneratedDocumentBoxComponent } from './generated-document-box/generated-document-box.component';
import { generatedDocumentConfig } from './types';
import { WriteGeneratedDocumentComponent } from './write-generated-document/write-generated-document.component';

export const featureGeneratedDocumentRoutes: Route = {
  path: 'generated-document',
  data: {
    breadcrumbChunck: {
      name: null,
    } as BreadcrumbRouteData,
  },
  providers: [
    {
      provide: ENTITY_CONFIG,
      useValue: generatedDocumentConfig,
    },
    {
      provide: EntityDeleteMutationService,
      useClass: DeleteGeneratedDocumentGQL,
    },
    {
      provide: PutEntityService,
      useClass: PutGeneratedDocumentGQL,
    },
    {
      provide: ENTITY_DETAILS_COMPONENT,
      useValue: GeneratedDocumentBoxComponent,
    },
    {
      provide: ENTITY_WRITE_COMPONENT,
      useValue: WriteGeneratedDocumentComponent,
    },
  ],
  children: [
    {
      path: 'new',
      component: WriteWrapperComponent,
      data: {
        successRedirect: ['../..'],
        cancelRedirect: ['../..'],

        obj: null,
        breadcrumbChunck: {
          name: $localize`new`,
        } as BreadcrumbRouteData,
      },
    },
    {
      path: ':id',
      runGuardsAndResolvers: 'always',
      resolve: {
        obj: resolveMedicalRecordItem('generated_documents'),
      },
      data: {
        breadcrumbChunck: {
          name: $localize`document:{{obj.id}}`,
        } as BreadcrumbRouteData,
      },
      children: [
        {
          path: '',
          component: DetailsWrapperComponent,

          data: {
            deleteRedirect: ['../..'],

            breadcrumbChunck: {
              name: null,
            } as BreadcrumbRouteData,
          },
        },
        {
          path: 'edit',
          component: WriteWrapperComponent,
          data: {
            breadcrumbChunck: {
              name: $localize`edit`,
            } as BreadcrumbRouteData,
          },
        },
      ],
    },
  ],
};
