import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { UploadDocument } from '@doctorus-front-end-monorepo/graphql';

import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { FileUploadFieldComponent } from '@doctorus-front-end-monorepo/util-document';
import { formatISO } from 'date-fns';

import { BaseEntityFormComponent } from '../../../../../shared/feature-entity/src';


@Component({
    selector: 'uploaded-document-write-upload-document',
    templateUrl: './write-upload-document.component.html',
    styleUrls: ['./write-upload-document.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FileUploadFieldComponent,
    MatIconModule,
    MatFormFieldModule,
    UiMatFormFieldErrorDisplayerDirective,
    MatInputModule,
    MatButtonModule
]
})
export class WriteUploadDocumentComponent extends BaseEntityFormComponent<UploadDocument> {
  //form!: FormGroup;
  fileCtr = new FormControl('');
  patient = computed(() => this.extra()?.patient);

  override initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id],
      file: [this.obj()?.file, [Validators.required]],
      name: [this.obj()?.name],
      description: [this.obj()?.description],
      patient: [this.patient().id, [Validators.required]],
      date: [
        this.obj()?.date ??
          formatISO(new Date(), {
            representation: 'date',
          }),
      ],
    });
}
