import { CommonModule } from '@angular/common';
import {
  Component,
  Injector,
  computed,
  inject,
  model,
  signal,
} from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { FetchPolicy } from '@apollo/client/core';
import {
  EntityConfig,
  EntityDeleteMutationService,
  EntityDialogService,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  GetInactivePatientsGQL,
  InactivePatientsFilterInput,
  RestLogEntry,
  RestoreObjectGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import {
  ContainerComponent,
  EmptyStateComponent,
  LoadingComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { first, map, tap } from 'rxjs';

@Component({
  selector: 'doctorus-front-end-monorepo-inactive-patients-list',
  providers: [
    {
      provide: EntityDeleteMutationService,
      useExisting: RestoreObjectGQL,
    },
  ],
  imports: [
    CommonModule,
    EmptyStateComponent,
    ContainerComponent,
    MatTableModule,
    LoadingComponent,
    HumanNamePipe,
    MatPaginatorModule,
    MatButtonModule,
  ],
  templateUrl: './inactive-patients-list.component.html',
  styleUrl: './inactive-patients-list.component.scss',
})
export class InactivePatientsListComponent {
  loading = model(true);
  count = model(0);
  fetchPolicy = signal<FetchPolicy>('network-only');
  pageSize = signal(50);
  pageIndex = signal<number | null>(null);
  ordering = signal<string | null | undefined>(null);
  queryParams = computed<InactivePatientsFilterInput>(() => ({
    commons: {
      page_size: this.pageSize(),
      ...(this.pageIndex() && { page: this.pageIndex()! + 1 }),
      ordering: this.ordering(),
    },
  }));
  patientListGql = inject(GetInactivePatientsGQL);
  patientsRessource = rxResource({
    request: this.queryParams,
    loader: params =>
      this.patientListGql
        .fetch({ payload: params.request }, { fetchPolicy: this.fetchPolicy() })
        .pipe(
          first(),
          tap(res => {
            this.count.set(res.data.getInactivePatients.count);
            this.fetchPolicy.set('cache-first');
          }),
          map(res => res.data.getInactivePatients.results),
        ),
  });
  columns = ['name', 'reference', 'by', 'date', 'action'];

  eds = inject(EntityDialogService);
  injector = inject(Injector);

  restoreObject(entry: RestLogEntry): void {
    this.eds
      .openEntityDeleteDialog(
        entry,
        this.injector,
        new EntityConfig({
          messages: {
            title: {
              delete: $localize`Object Recovery`,
            },
            action: {
              deleted: {
                success: $localize`object successfully restored`,
                confirmation: $localize`Do you want to restore this object : ${entry.object_repr} ?`,
                buttonLabel: $localize`yes, restore`,
              },
            },
          },
        }),
      )
      .subscribe(() => {
        this.patientsRessource.reload();
      });
  }
}
