import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  computed,
  inject,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import {
  BaseEntityDetailsComponent,
  EntityMutationService,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  Measure,
  PutObservationGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { SlideOutPanelService } from '@doctorus-front-end-monorepo/slide-out-panel';
import * as _ from 'lodash';
import {
  ContainerComponent,
  KeyValueComponent,
} from '../../../../../shared/ui-layout/src';
import { CoalescePipe } from '../../../../../shared/util-formatting/src';
import { MeasureGraphComponent } from '../measure-graph/measure-graph.component';
import { MeasureObservationsSummaryComponent } from '../measure-observations-summary.component';
import { ObservationBoxComponent } from '../observation-box/observation-box.component';
import { observationConfig } from '../utils';
import { WriteObservationDialogComponent } from '../write-observation-dialog/write-observation-dialog.component';

@Component({
  selector: 'medical-measure-measure-box',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatDividerModule,
    ObservationBoxComponent,
    CoalescePipe,
    KeyValueComponent,
    MatDividerModule,
    MatListModule,
    RouterModule,
    ContainerComponent,
    MeasureGraphComponent,
    MeasureObservationsSummaryComponent,
  ],
  templateUrl: './measure-box.component.html',
  styleUrls: ['./measure-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeasureBoxComponent extends BaseEntityDetailsComponent<Measure> {
  mode = signal<'LARGE' | 'SMALL'>('SMALL');
  observations = computed(() => _.sortBy(this.obj().observations, ['date']));
  get observationsTitle() {
    return $localize`observations\:(${this.obj().observations.length})`;
  }
  //chartHeight = computed(() => (this.mode() === 'LARGE' ? '100px' : '50px'));
  private sds = inject(SlideOutPanelService);
  private injector = inject(Injector);
  private observationInjector = Injector.create({
    providers: [
      {
        provide: EntityMutationService,
        useExisting: PutObservationGQL,
      },
    ],
    parent: this.injector,
  });

  addObservation(): void {
    this.sds
      .openSlideOutEntityWriteForm(
        {
          cmp: WriteObservationDialogComponent,
          entityConfig: observationConfig,
          extra: {
            measure: this.obj(),
          },
        },
        this.observationInjector,
      )
      .subscribe();
  }
}
