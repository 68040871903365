<!-- <div class="flex gap-2">
  <mat-icon>{{feedbackAttrs.icon}}</mat-icon>
  <div>
    @if (title) {
    <h4 class="first-letter:uppercase">{{title}}</h4>
    }
    @if (message) {
    <p>{{message}}</p>
    }
  </div>

</div> -->

<!-- <ui-layout-container
  level="1"
  paddingLevel="1"
  border="false"
  [matIcon]="feedbackAttrs.icon"
  class="w-full"
  [title]="title"
>
  @if (message) {
    <p ngProjectAs="description">{{ message }}</p>
  }

  <ng-content select="actions"> </ng-content>
</ui-layout-container> -->
@if (withIcon) {
  <mat-icon [class]="iconClasses">{{ feedbackAttrs.icon }}</mat-icon>
}
<div>
  @if (title) {
    <h4>{{ title }}</h4>
  }
  @if (message) {
    <p>{{ message }}</p>
  }
</div>
<div class="ml-auto">
  <ng-content select="actions"></ng-content>
</div>
@if (removable) {
  <mat-icon (click)="dismiss()" class="ml-auto hover:cursor-pointer text-lg w-4"
    >close</mat-icon
  >
}
