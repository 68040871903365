import { Pipe, PipeTransform } from '@angular/core';
import * as dateFns from 'date-fns';
import { duration } from '../utils';

@Pipe({
  name: 'duration',
  standalone: true,
})
export class DurationPipe implements PipeTransform {
  transform(
    isoDuration: string | dateFns.Duration | null | undefined,
    format?: Array<keyof dateFns.Duration>,
  ): string {
    return duration(isoDuration, format);
  }
}
