@if (!patient()) {
  <ui-layout-loading></ui-layout-loading>
} @else {
  <mat-sidenav-container class="h-full">
    <mat-sidenav class="max-w-xs border-r border-r-gray-200" mode="side" opened>
      <h2
        [routerLink]="['.']"
        class="p-4 border-b border-b-gray-200 hover:cursor-pointer"
      >
        {{ patient() | humanName: 'full' : true }}
      </h2>
      @if (feeds().length > 0) {
        <div class="px-2 flex p-2 gap-2 flex-col border-b border-b-gray-200">
          @for (feed of feeds(); track $index) {
            <ui-layout-feedback
              removable="true"
              withIcon="false"
              [type]="feed.type"
              [pattern]="2"
              [message]="feed.message"
            ></ui-layout-feedback>
          }
        </div>
      }
      @if (isDoctor) {
        @if (currentMedicalHistory().size > 0) {
          <dl class="p-4 border-b border-b-gray-200">
            @for (item of currentMedicalHistory() | keyvalue; track $index) {
              <dt class="text-gray-800 font-medium">{{ item.key }}</dt>
              <ul class="text-gray-700">
                @for (entry of item.value; track $index) {
                  <li>
                    <quill-view-html
                      [content]="entry.content"
                    ></quill-view-html>
                  </li>
                }
              </ul>
            }
          </dl>
        }
      }

      <ui-layout-nav-tree [navLinks]="generalRoutes()"></ui-layout-nav-tree>
      <ng-container *showForRoles="[accountRole.DOCTOR]">
        <h3 class="p-2 first-letter:uppercase" i18n>medical notes</h3>
        <ui-layout-nav-tree [navLinks]="medicalRoutes()"></ui-layout-nav-tree>
      </ng-container>
    </mat-sidenav>
    <mat-sidenav-content class="p-6">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
}
