import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Injector,
  model,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EntityConfig,
  EntityDeleteMutationService,
  EntityDialogService,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  Account,
  CreateMembershipByEmailGQL,
  DeleteMembershipGQL,
  Membership,
  PutMembershipGQL,
} from '@doctorus-front-end-monorepo/graphql';
import {
  AvatarLoaderDirective,
  HumanNamePipe,
} from '@doctorus-front-end-monorepo/shared-util';
import {
  ContainerComponent,
  KeyValueComponent
} from '@doctorus-front-end-monorepo/ui-layout';
import { isNil } from 'lodash';
import { EMPTY, first, switchMap, takeWhile } from 'rxjs';
import { CreateMemberDialogComponent } from '../create-member-dialog/create-member-dialog.component';
import { UpdateAccountOwnerComponent } from '../update-account-owner.component';

@Component({
  selector: 'doctorus-front-end-monorepo-office-memberships',
  templateUrl: './office-memberships.component.html',
  styleUrls: ['./office-memberships.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: EntityDeleteMutationService,
      useClass: DeleteMembershipGQL,
    },
  ],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatListModule,
    CommonModule,
    MatMenuModule,
    MatTableModule,
    ContainerComponent,
    KeyValueComponent,
    MatChipsModule,
    AvatarLoaderDirective,
    HumanNamePipe
],
})
export class OfficeMembershipsComponent {
  account = model.required<Account>();
  sds = inject(EntityDialogService);
  injector = inject(Injector);
  columns = ['member', 'email', 'roles', 'action'];
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  constructor(
    private putMembershipGQL: PutMembershipGQL,
    private createMembershipByEmailGQL: CreateMembershipByEmailGQL,
    private dialog: MatDialog,
    private matSnack: MatSnackBar,
  ) {}

  updateOwnership(): void {
    this.dialog
      .open(UpdateAccountOwnerComponent, { width: '420px' })
      .afterClosed()
      .pipe(takeWhile(x => !isNil(x)))
      .subscribe(incomming =>
        this.account.update(origin => ({
          ...origin,
          ...incomming,
        })),
      );
  }

  updateAccount(): void {
    this.router.navigate(['.'], { relativeTo: this.route });
  }

  deleteMembership(membership: Membership): void {
    this.sds.openEntityDeleteDialog(
      {
        id: membership.user?.sub,
        ...membership,
      },
      this.injector,
      new EntityConfig({
        feature: $localize`membership`,
      }),
    );
  }

  editMembership(payload: Membership): void {
    this.dialog
      .open(CreateMemberDialogComponent, {
        data: {
          membership: payload,
        },
      })
      .afterClosed()
      .pipe(
        first(),
        switchMap(membership =>
          membership
            ? this.putMembershipGQL.mutate({
                userId: membership.userId,
                roles: membership.roles,
              })
            : EMPTY,
        ),
      )
      .subscribe(x => {
        this.updateAccount();
        this.matSnack?.open($localize`account membership updated`);
      });
  }

  createMembership(): void {
    this.dialog
      .open(CreateMemberDialogComponent, {
        data: {
          memberships: this.account().memberships,
        },
      })
      .afterClosed()
      .pipe(
        first(),
        switchMap(membership =>
          membership
            ? this.createMembershipByEmailGQL.mutate({
                email: membership.email,
                roles: membership.roles,
              })
            : EMPTY,
        ),
      )
      .subscribe(x => {
        this.updateAccount();
        this.matSnack?.open($localize`account membership created`);
      });
  }
}
