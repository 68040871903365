<section class="grid grid-cols-3 gap-4">
  <ui-layout-key-value key="date" i18n-key>
    {{ obj().date | date }}
  </ui-layout-key-value>

  <ui-layout-key-value class="col-span-2 dense-list" key="treatments" i18n-key>
    <mat-list>
      @for (treatment of obj().treatment_set; track $index) {
        <mat-list-item>
          <span matListItemTitle>{{ treatment.name }}</span>
          <span matListItemLine>{{ treatment.note | coalesce: '-' }}</span>
        </mat-list-item>
      }
    </mat-list>
  </ui-layout-key-value>
  <button
    (click)="print()"
    class="col-span-full justify-self-end"
    mat-stroked-button
  >
    <mat-icon>preview</mat-icon><span i18n>preview</span>
  </button>
</section>
