import { CommonModule } from '@angular/common';
import { Component, computed, inject, model, signal } from '@angular/core';
import { rxResource, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { FetchPolicy } from '@apollo/client/core';
import { PhoneNumberBoxComponent } from '@doctorus-front-end-monorepo/feature-patient';
import {
  AccountDataService,
  ActivePatientsFilterInput,
  GetAllPatientsGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import {
  ContainerComponent,
  EmptyStateComponent,
  LoadingComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { ArrayExistPipe } from '@doctorus-front-end-monorepo/util-array';
import { CoalescePipe } from '@doctorus-front-end-monorepo/util-formatting';
import { isNil } from 'lodash';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  tap,
} from 'rxjs';

@Component({
  selector: 'doctorus-front-end-monorepo-active-patients-list',
  imports: [
    CommonModule,
    ContainerComponent,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    RouterModule,
    MatSortModule,
    MatPaginatorModule,
    LoadingComponent,
    PhoneNumberBoxComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    HumanNamePipe,
    EmptyStateComponent,
    ArrayExistPipe,
    CoalescePipe,
  ],
  templateUrl: './active-patients-list.component.html',
  styleUrl: './active-patients-list.component.scss',
})
export class ActivePatientsListComponent {
  currency = inject(AccountDataService).account?.currency;
  count = model(0);
  fetchPolicy = signal<FetchPolicy>('network-only');
  pageSize = signal(50);
  pageIndex = signal<number | null>(null);
  ordering = signal<string | null | undefined>(null);
  searchCtrl = new FormControl('');
  searchTerm = toSignal(
    this.searchCtrl.valueChanges.pipe(
      map(x => x?.trim()),
      filter(x => (!!x && x.length > 2) || isNil(x) || x === ''),
      distinctUntilChanged(),
      debounceTime(500),
    ),
  );
  displayedColumns = signal<string[]>([
    'name',
    'reference',
    'phone_number',
    'added',
    'total_due',
  ]);

  containerDescription = computed(
    () => $localize`found ${this.count() ?? 'no'} patient(s)`,
  );

  queryParams = computed<ActivePatientsFilterInput>(() => ({
    commons: {
      page_size: this.pageSize(),
      ...(this.pageIndex() && { page: this.pageIndex()! + 1 }),
      ordering: this.ordering(),
    },
    search: this.searchTerm(),
  }));
  patientListGql = inject(GetAllPatientsGQL);
  patientsRessource = rxResource({
    request: this.queryParams,
    loader: params =>
      this.patientListGql
        .fetch(
          {
            payload: params.request,
          },
          { fetchPolicy: this.fetchPolicy() },
        )
        .pipe(
          first(),
          tap(res => {
            this.count.set(res.data.getPatients.count);
            this.fetchPolicy.set('cache-first');
          }),
          map(res => res.data.getPatients.results),
        ),
  });

  // constructor(
  //   private patientListGql: GetAllPatientsGQL,
  //   private getPatientsByMedicalNotesFilterGQL: GetPatientsByMedicalNotesFilterGQL,
  // ) {
  //   combineLatest({
  //     pageSize: toObservable(this.pageSize),
  //     pageIndex: toObservable(this.pageIndex),
  //     searchType: toObservable(this.searchType),
  //     ordering: toObservable(this.ordering),
  //     searchTerm: toObservable(this.searchTerm),
  //   })
  //     .pipe(
  //       filter(x => !(x.searchType === 'medical-tag' && !x.searchTerm)),
  //       tap(x =>
  //         this.displayedColumns.set(SEARCH_CONFIG_MAP[x.searchType].fields),
  //       ),
  //       map(x => ({
  //         payload: {
  //           page_size: x.pageSize,
  //           ...(x.pageIndex && { page: x.pageIndex + 1 }),
  //           ordering: x.ordering,
  //           [SEARCH_CONFIG_MAP[x.searchType].filterKey]: x.searchTerm,
  //         },
  //       })),
  //       debounceTime(500),
  //       tap(() => {
  //         this.loading.set(true);
  //         this.queryResponse.set(null);
  //       }),
  //       switchMap(params => {
  //         switch (this.searchType()) {
  //           case 'medical-tag':
  //             return this.getPatientsByMedicalNotesFilterGQL
  //               .watch(params, { fetchPolicy: 'no-cache' })
  //               .valueChanges.pipe(
  //                 map(x => ({
  //                   ...x.data.getPatientsByMedicalNotesFilter,
  //                   loading: x.loading,
  //                 })),
  //               );
  //           case 'profile':
  //             return this.patientListGql
  //               .watch(params, { fetchPolicy: 'no-cache' })
  //               .valueChanges.pipe(
  //                 map(x => ({
  //                   ...x.data.getPatients,
  //                   loading: x.loading,
  //                 })),
  //               );
  //           default:
  //             return this.getPatientsByMedicalNotesFilterGQL
  //               .watch(params, { fetchPolicy: 'no-cache' })
  //               .valueChanges.pipe(
  //                 map(x => ({
  //                   ...x.data.getPatientsByMedicalNotesFilter,
  //                   loading: x.loading,
  //                 })),
  //               );
  //         }
  //       }),
  //       tap(x => {
  //         this.loading.set(false);
  //         this.count.set(x?.count ?? 0);
  //       }),
  //     )
  //     .subscribe(x =>
  //       this.queryResponse.set(x as GetAllPatientsQuery['getPatients']),
  //     );
  // }
  // changeSearchType(type: searchType): void {
  //   this.searchType.set(type);
  //   this.searchTerm.set(null);
  // }

  // updatePage(event: PageEvent): void {
  //   this.pageSize.set(event.pageSize);
  //   this.pageIndex.set(event.pageIndex);
  // }
  updateSort(event: Sort): void {
    const direction = event.direction === 'asc' ? '' : '-';
    const value = event.direction ? `${direction}${event.active}` : '';
    this.ordering.set(value);
  }
}
