import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
} from '@angular/router';
import { AccountRole, AuthService } from '@doctorus-front-end-monorepo/auth';
import { BreadcrumbRouteData } from '@doctorus-front-end-monorepo/breadcrumb';
import { featureAppointmentRoutes } from '@doctorus-front-end-monorepo/feature-appointment';
import { featureCarePlanMedicalRecordRoutes } from '@doctorus-front-end-monorepo/feature-care-plan';
import { featureContactRoutes } from '@doctorus-front-end-monorepo/feature-contact';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityConfig,
  EntityDeleteMutationService,
  LAYOUT_CONFIG,
  LayoutConfig,
  PutEntityService,
  WriteWrapperComponent,
} from '@doctorus-front-end-monorepo/feature-entity';
import { featureGeneratedDocumentRoutes } from '@doctorus-front-end-monorepo/feature-generated-document';
import { featureMedicalHistoryRoutes } from '@doctorus-front-end-monorepo/feature-medical-history';
import { MEASURE_ROUTE } from '@doctorus-front-end-monorepo/feature-medical-measure';
import {
  MedicalNoteDetailsComponent,
  MedicalNoteFormComponent,
  patientConfig,
  PatientWriteFormComponent,
} from '@doctorus-front-end-monorepo/feature-patient';
import { featurePatientPaymentRoutes } from '@doctorus-front-end-monorepo/feature-patient-payment';
import { featurePrescriptionRoutes } from '@doctorus-front-end-monorepo/feature-prescription';
import { featureUploadedDocumentRoutes } from '@doctorus-front-end-monorepo/feature-uploaded-document';
import {
  CreatePatientGQL,
  DeletePatientGQL,
  FetchPatientService,
  GetCorePatientProfileGQL,
  GetPatientMedicalNotesGQL,
  GetPatientMedicalProfileGQL,
  GetPatientPublicProfileGQL,
  PutPatientMedicalNotesGQL,
  UpdatePatientProfileGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { map } from 'rxjs';
import { ActivePatientsListComponent } from './active-patients-list/active-patients-list.component';
import { FeaturePatientsViewerComponent } from './feature-patients-viewer/feature-patients-viewer.component';
import { InactivePatientsListComponent } from './inactive-patients-list/inactive-patients-list.component';
import { MedicalDocumentsListComponent } from './medical-documents-list/medical-documents-list.component';
import { MedicalTimelineComponent } from './medical-timeline/medical-timeline.component';
import { PatientDashboardComponent } from './patient-dashboard/patient-dashboard.component';
import { PatientDetailsComponent } from './patient-details/patient-details.component';
import { PatientsListComponent } from './patients-list/patients-list.component';
import { PatientsNotesExplorerComponent } from './patients-notes-explorer/patients-notes-explorer.component';

const patientFetchServiceFactory = (
  as: AuthService,
  patientPublicSerice: GetPatientPublicProfileGQL,
  patientMedicalService: GetPatientMedicalProfileGQL,
) => (as.isAuthUserDoctor ? patientMedicalService : patientPublicSerice);

const patientProfileResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(GetCorePatientProfileGQL)
    .fetch({ id: route.params['patId'] })
    .pipe(map(x => x.data.getPatient));
};

const patientMedicalNotes = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(GetPatientMedicalNotesGQL)
    .fetch({ id: route.parent?.params['patId'] })
    .pipe(map(x => x.data.getPatient));
};

export const patientsRoutes: Route = {
  path: 'patients',
  data: {
    authorizeFor: [AccountRole.MEDICAL_ASSISTANT, AccountRole.DOCTOR],
    breadcrumbChunck: { name: $localize`patients` },
  },
  component: FeaturePatientsViewerComponent,
  children: [
    {
      path: 'list',
      component: PatientsListComponent,
      data: {
        breadcrumbChunck: {
          name: null,
        } as BreadcrumbRouteData,
      },
      children: [
        {
          path: 'active',
          component: ActivePatientsListComponent,
        },
        {
          path: 'inactive',
          component: InactivePatientsListComponent,
        },
        {
          path: 'notes-explorer',
          component: PatientsNotesExplorerComponent,
        },
        {
          path: '',
          redirectTo: 'active',
          pathMatch: 'full',
        },
      ],
    },
    {
      providers: [
        {
          provide: LAYOUT_CONFIG,
          useValue: {
            withBorder: false,
            contentBorder: false,
            paddingLevel: 2,
          } as LayoutConfig,
        },
        {
          provide: ENTITY_CONFIG,
          useValue: patientConfig,
        },
        {
          provide: PutEntityService,
          useClass: CreatePatientGQL,
        },
        {
          provide: ENTITY_WRITE_COMPONENT,
          useValue: PatientWriteFormComponent,
        },
      ],
      path: 'new',

      component: WriteWrapperComponent,
      data: {
        obj: null,
        breadcrumbChunck: {
          name: $localize`create`,
        } as BreadcrumbRouteData,
      },
    },
    {
      path: ':patId',
      component: PatientDetailsComponent,
      providers: [
        {
          provide: FetchPatientService,
          useFactory: patientFetchServiceFactory,
          deps: [
            AuthService,
            GetPatientPublicProfileGQL,
            GetPatientMedicalProfileGQL,
          ],
        },
        {
          provide: LAYOUT_CONFIG,
          useValue: {
            withBorder: true,
            contentBorder: true,
          } as LayoutConfig,
        },
        {
          provide: EntityDeleteMutationService,
          useClass: DeletePatientGQL,
        },
      ],
      resolve: {
        patient: patientProfileResolver,
      },
      data: {
        breadcrumbChunck: {
          name: `{{patient.given_name}} {{patient.family_name}}`,
        } as BreadcrumbRouteData,
      },
      children: [
        {
          providers: [
            {
              provide: LAYOUT_CONFIG,
              useValue: {
                withBorder: false,
                contentBorder: false,
              } as LayoutConfig,
            },
            {
              provide: ENTITY_CONFIG,
              useValue: patientConfig,
            },
            {
              provide: PutEntityService,
              useClass: UpdatePatientProfileGQL,
            },
            {
              provide: ENTITY_WRITE_COMPONENT,
              useValue: PatientWriteFormComponent,
            },
          ],
          path: 'edit',
          component: WriteWrapperComponent,
          resolve: {
            obj: patientProfileResolver,
          },
          data: {
            breadcrumbChunck: {
              name: $localize`edit profile`,
            } as BreadcrumbRouteData,
          },
        },
        {
          path: 'overview',
          component: PatientDashboardComponent,
          data: {
            breadcrumbChunck: { name: 'dashboard' },
          },
        },
        {
          path: 'profile-information',
          component: PatientDetailsComponent,
          data: {
            breadcrumbChunck: {
              name: $localize`profile informations`,
            } as BreadcrumbRouteData,
          },
          children: [
            {
              path: 'edit-profile',
              component: PatientWriteFormComponent,
              data: {
                breadcrumbChunck: {
                  name: $localize`edit`,
                } as BreadcrumbRouteData,
              },
            },
          ],
        },
        {
          path: 'summary',
          component: MedicalTimelineComponent,
          data: {
            breadcrumbChunck: { name: $localize`summary` },
          },
        },
        featureAppointmentRoutes,
        featurePatientPaymentRoutes,
        featureContactRoutes,
        featureMedicalHistoryRoutes,
        featureCarePlanMedicalRecordRoutes,
        MEASURE_ROUTE,
        {
          path: 'documents',
          data: {
            breadcrumbChunck: {
              name: $localize`documents`,
            } as BreadcrumbRouteData,
          },

          children: [
            {
              path: '',
              component: MedicalDocumentsListComponent,
              data: {
                deleteRedirect: ['../..'],
                breadcrumbChunck: {
                  name: null,
                } as BreadcrumbRouteData,
              },
            },
            featureGeneratedDocumentRoutes,
            featureUploadedDocumentRoutes,
            featurePrescriptionRoutes,
          ],
        },

        {
          path: 'general-notes',
          providers: [
            {
              provide: LAYOUT_CONFIG,
              useValue: {
                canDelete: false,
                withBorder: false,
              } as LayoutConfig,
            },
            {
              provide: ENTITY_CONFIG,
              useValue: new EntityConfig({
                feature: $localize`general notes`,
              }),
            },
            {
              provide: PutEntityService,
              useClass: PutPatientMedicalNotesGQL,
            },
            {
              provide: ENTITY_DETAILS_COMPONENT,
              useValue: MedicalNoteDetailsComponent,
            },
            {
              provide: ENTITY_WRITE_COMPONENT,
              useValue: MedicalNoteFormComponent,
            },
          ],
          resolve: {
            obj: patientMedicalNotes,
          },
          data: {
            breadcrumbChunck: {
              name: $localize`medical notes`,
            },
          },
          children: [
            {
              path: '',
              component: DetailsWrapperComponent,
              data: {
                breadcrumbChunck: {
                  name: null,
                },
              },
            },
            {
              path: 'edit',
              component: WriteWrapperComponent,
              data: {
                breadcrumbChunck: {
                  name: $localize`edit`,
                },
              },
            },
          ],
        },
        {
          path: '',
          redirectTo: 'overview',
          pathMatch: 'full',
        },
      ],
    },
    {
      path: '',
      redirectTo: 'list',
      pathMatch: 'full',
    },
  ],
};
