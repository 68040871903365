import { CurrencyPipe } from '@angular/common';
import { inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { AccountDataService } from '@doctorus-front-end-monorepo/graphql';

@Pipe({
  name: 'accountCurrency',
  standalone: true,
})
export class AccountCurrencyPipe implements PipeTransform {
  _localId = inject(LOCALE_ID);
  _defaultCurrencyCode = inject(AccountDataService).account?.currency;
  transform(value: number | string | undefined | null): unknown {
    return new CurrencyPipe(
      this._localId,
      this._defaultCurrencyCode ?? undefined,
    ).transform(value);
  }
}
