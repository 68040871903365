import { CommonModule, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { BaseEntityFormComponent } from '@doctorus-front-end-monorepo/feature-entity';
import {
  AccountDataService,
  Patient,
} from '@doctorus-front-end-monorepo/graphql';
import { InlineFormArrayComponent } from '@doctorus-front-end-monorepo/inline-form-array';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { ContainerComponent } from '@doctorus-front-end-monorepo/ui-layout';
import { UiPhoneNumberPickerComponent } from '@doctorus-front-end-monorepo/ui-phone-number-picker';
import { PatientFormBuilderService } from '../patient-form-builder.service';
import { PatientPropertyInlineFormComponent } from '../patient-property-inline-form/patient-property-inline-form.component';
import { PHONE_NUMBER_AVAILABILTIES } from '../types';
@Component({
  selector: 'patient-patient-write-form',
  templateUrl: './patient-write-form.component.html',
  styleUrls: ['./patient-write-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconModule,
    CommonModule,
    NgIf,
    MatButtonModule,
    MatListModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatChipsModule,
    MatDividerModule,
    ContainerComponent,
    RouterModule,
    InlineFormArrayComponent,
    UiMatFormFieldErrorDisplayerDirective,
    ContainerComponent,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    UiPhoneNumberPickerComponent,
    PatientPropertyInlineFormComponent,
    NgFor,
  ],
})
export class PatientWriteFormComponent extends BaseEntityFormComponent<Patient> {
  account = inject(AccountDataService).account;
  availabilities = PHONE_NUMBER_AVAILABILTIES;
  private fbs = inject(PatientFormBuilderService);
  override initFormFun = () => this.fbs.createForm(this.obj());

  initPublicProperties = computed(() =>
    this.account?.patientPropertyModels
      ?.filter(
        _prop =>
          !_prop.is_medical &&
          !this.obj()
            ?.public_properties?.map(x => x.key.toLowerCase())
            .includes(_prop.key.toLowerCase()),
      )
      .map(_prop => ({
        key: _prop.key,
        value: null,
      }))
      .concat(this.obj()?.public_properties ?? []),
  );

  get phoneNumberCtrls() {
    return (this.form.get('phone_numbers') as FormArray)
      ?.controls as FormGroup[];
  }
  get phoneNumberCount() {
    return this.phoneNumberCtrls.length;
  }
  get phoneNumberTitle() {
    return $localize`phone number (${this.phoneNumberCount})`;
  }
  newPhoneNumber(): void {
    this.addPhoneNumberCtrl();
  }

  removePhoneNumberCtrl(index: number): void {
    (this.form.get('phone_numbers') as FormArray).removeAt(index);
  }
  addPhoneNumberCtrl(): void {
    (this.form.get('phone_numbers') as FormArray).push(
      this.fbs.createPhoneNumberForm(),
    );
  }

  get propertiesCtrls() {
    return (this.form.get('public_properties') as FormArray)
      ?.controls as FormGroup[];
  }
  get propertiesCount() {
    return this.propertiesCtrls.length;
  }
  get propertiesTitle() {
    return $localize`public properties (${this.propertiesCount})`;
  }
  newProperty(): void {
    this.addProperyCtrl();
  }

  // removeProperyCtrl(index: number): void {
  //   (this.patientForm.get('public_properties') as FormArray).removeAt(index);
  // }
  addProperyCtrl(): void {
    (this.form.get('public_properties') as FormArray).push(
      this.fbs.createPropertyForm(),
    );
  }
  createProperty = (payload?: any) => this.fbs.createPropertyForm(payload);

  // deletePatient(): void {
  //   this.eds
  //     .openEntityDeleteDialog(this.obj(), this.injector, patientConfig)
  //     .subscribe(() => this.router.navigate(this.rhns.getPatientListRoute()));
  // }

  // submit(): void {
  //   this.loading.set(true);
  //   checkForm(this.patientForm)
  //     .pipe(
  //       switchMap(x =>
  //         this.isNewPatient()
  //           ? this.createPatientGql
  //               .mutate({
  //                 payload: patientConfig.serializerFun(this.patientForm.value),
  //               })
  //               .pipe(map(x => x.data?.createPatient))
  //           : this.updatePatientGql
  //               .mutate({
  //                 payload: patientConfig.serializerFun(this.patientForm.value),
  //               })
  //               .pipe(map(x => x.data?.updatePatientProfile)),
  //       ),
  //       first(),
  //       finalize(() => this.loading.set(false)),
  //     )
  //     .subscribe(x => {
  //       this.matSnackBar.open(
  //         patientConfig.onPutSuccessMsg(this.patient() ? true : false),
  //         'ok',
  //       );

  //       if (this.isNewPatient()) {
  //         this.router.navigate(['..', x.id], { relativeTo: this.route });
  //       } else {
  //         this.router.navigate(['..'], { relativeTo: this.route });
  //       }
  //     });
  // }
}
