<main class="grid grid-cols-3 gap-4">
  <ui-layout-key-value key="schedules" i18n-key>
    <appointment-dates [appointment]="obj()"></appointment-dates>
  </ui-layout-key-value>
  <ui-layout-key-value key="status" i18n-key>
    <appointment-status-picker
      (statusChange)="setStatus($event)"
      [status]="obj().status"
    >
      @if (obj().status | resolveStatusConfig: 'appointment'; as status) {
        <mat-chip-row [editable]="true">
          <mat-icon matChipAvatar [style.color]="status.color">{{
            status.svgIcon
          }}</mat-icon>
          <span class="first-letter:uppercase">{{ status.label }}</span>
        </mat-chip-row>
      }
    </appointment-status-picker>
  </ui-layout-key-value>
  <ui-layout-key-value key="time zone" i18n-key>
    <span>{{ obj().timezone }}</span>
  </ui-layout-key-value>
  <ui-layout-key-value key="location" i18n-key>
    @if (obj().location) {
      <mat-chip-row>
        <mat-icon matChipAvatar>location_on</mat-icon>
        {{ obj().location?.name }}
      </mat-chip-row>
    }
  </ui-layout-key-value>
  <ui-layout-key-value key="task type" i18n-key>
    @if (obj().task_type) {
      <mat-chip>
        <p
          matChipAvatar
          class="w-3 h-3 rounded-full"
          [style.background-color]="obj().task_type?.color"
        ></p>
        <p class="chip-text">{{ obj().task_type?.name }}</p>
      </mat-chip>
    }
  </ui-layout-key-value>
  <ui-layout-key-value key="fees" i18n-key>
    <mat-chip-row [editable]="true" (click)="setFees()">
      <mat-icon matChipAvatar>payments</mat-icon>
      {{ obj().fees | coalesce: '-' }}
    </mat-chip-row>
  </ui-layout-key-value>
</main>
@if (isDoctor$ | async) {
  <ui-layout-container
    [border]="false"
    [level]="3"
    paddingLevel=""
    i18n-title
    title="medical notes"
  >
    <span ngProjectAs="description">
      {{ obj().description }}
    </span>
    <div ngProjectAs="actions">
      <button (click)="editMedicalNote()" mat-stroked-button>
        <span i18n>edit </span>
      </button>
    </div>

    <quill-view
      [content]="obj().note | coalesce: '-'"
      format="html"
      theme="snow"
    ></quill-view>
  </ui-layout-container>
}
