import { Injectable, inject } from '@angular/core';
import { map, merge, tap } from 'rxjs';
import { ApolloCacheService } from './apollo-cache.service';
import {
  ActionType,
  AppointmentPublicFieldsFragment,
  OnAppointmentDeleteGQL,
  OnAppointmentFeesSetGQL,
  OnAppointmentPutGQL,
  OnAppointmentStatusSetGQL,
  OnAppointmentUpdateGQL,
  OnPatientAppointmentPutGQL,
  OnPatientPaimentDeleteGQL,
  OnPatientPaimentPutGQL,
  Patient,
} from './generated';

@Injectable({
  providedIn: 'root',
})
export class NotificationsHandlerService {
  private notificationSound = new Audio(
    '../../../assets/utils/notification-sound.mp3',
  );
  private cs = inject(ApolloCacheService);
  private onAppointmentPutGql = inject(OnAppointmentPutGQL);
  private onAppointmentStatusSetGql = inject(OnAppointmentStatusSetGQL);
  private onAppointmentFeesSetGql = inject(OnAppointmentFeesSetGQL);
  private onPatientAppointmentPutGql = inject(OnPatientAppointmentPutGQL);
  private onAppointmentDeleteGql = inject(OnAppointmentDeleteGQL);
  private onAppointmentUpdateGql = inject(OnAppointmentUpdateGQL);
  private onPaymentPutGql = inject(OnPatientPaimentPutGQL);
  private onPatientPaymentDeleteGql = inject(OnPatientPaimentDeleteGQL);

  listenAppointment() {
    return merge(
      merge(
        this.onAppointmentDeleteGql.subscribe().pipe(
          map(x => x.data!.appointmentDeleted),
          tap(console.warn),
          tap(x => {
            if (x) {
              this.cs.deleteCache(x);
            }
          }),
        ),
        this.onAppointmentUpdateGql
          .subscribe()
          .pipe(map(x => x.data?.appointmentUpdate)),
        this.onAppointmentPutGql.subscribe().pipe(
          map(x => x.data!.appointmentPut),
          tap(appointment => {
            if (appointment) {
              console.warn(appointment);
              this.cs.updateParentCache<
                Patient,
                AppointmentPublicFieldsFragment,
                'appointments'
              >({
                parentId: `Patient:${appointment?.patient_info.id}`,
                childObj: appointment,
                childKey: 'appointments',
                del: appointment?.metadata?.action === ActionType.Delete,
              });
            }
          }),
        ),
      ),
    );
  }
  listenToPatientAppointments() {
    return this.onPatientAppointmentPutGql
      .subscribe()
      .pipe(map(x => x.data?.patientAppointmentPut));
  }

  listentPatientPaymentPut() {
    return this.onPaymentPutGql
      .subscribe()
      .pipe(map(x => x.data?.patientPaymentPut));
  }
  listentPatientPaymentDelete() {
    return this.onPatientPaymentDeleteGql.subscribe().pipe(
      map(x => x.data?.patientPaymentDeleted),
      tap(x => {
        if (x) {
          this.cs.deleteCache(x);
        }
      }),
    );
  }
}
