import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { Patient } from '../../../../../data-access/graphql/src';
import { KeyValueEditorComponent } from '../../../../../form/key-value-editor/src';
import {
  ISlideOutFormComponent,
  SLIDE_OUT_DATA_TOKEN,
  SlideOutFormPanelData,
} from '../../../../../slide-out-panel/src';

@Component({
  selector: 'patient-write-patient-properties',
  standalone: true,
  imports: [
    CommonModule,
    KeyValueEditorComponent,
    ReactiveFormsModule,
    MatButtonModule,
  ],
  templateUrl: './write-patient-properties.component.html',
  styleUrl: './write-patient-properties.component.scss',
})
export class WritePatientPropertiesComponent implements ISlideOutFormComponent {
  /**
   *
   */
  medicalProperties = this.data.entity?.medical_properties;
  constructor(
    @Inject(SLIDE_OUT_DATA_TOKEN)
    public data: SlideOutFormPanelData<
      Pick<Patient, 'id' | 'medical_properties'>,
      WritePatientPropertiesComponent
    >,
    private fb: FormBuilder,
  ) {}
  checkForm(): void {
    this.form.markAllAsTouched();
  }
  form = this.fb.group({
    id: [this.data.entity?.id, Validators.required],
    medical_properties: [
      this.data.entity?.medical_properties,
      Validators.required,
    ],
  });
}
