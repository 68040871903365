<section class="flex flex-col gap-4" [formArrayName]="controlKey">
  @for (control of ctrls.controls; track $index) {
    <form [formGroupName]="$index" class="flex gap-8 w-full">
      <!-- <ng-content class="flex-auto"></ng-content> -->
      @if (formTemplateRef) {
        <div class="flex-1">
          <ng-container
            [ngTemplateOutletContext]="{ index: $index, controlKey }"
            [ngTemplateOutlet]="formTemplateRef"
          >
          </ng-container>
        </div>
      }

      <button
        [disabled]="removeDisabled"
        type="button"
        (click)="removeForm($index)"
        mat-stroked-button
      >
        <span i18n>remove</span>
      </button>
    </form>
  }
</section>
