<ui-layout-container
  [contentBorder]="withBorder"
  border="false"
  [paddingLevel]="paddingLevel"
  level="4"
  [title]="title"
>
  <!-- <ng-template (attached)="setAttachedForm($event)" [cdkPortalOutlet]="portalComponent"></ng-template> -->

  <!-- <ng-container [ngComponentOutletInputs]="componentInputs()" [ngComponentOutletInputs]=""
    [ngComponentOutlet]="component"></ng-container> -->

  <!-- <div class="p-4">
    <ng-template (attached)="setAttachedForm($event)" [cdkPortalOutlet]="portal"></ng-template>

  </div> -->

  <ng-container
    #form
    [ngComponentOutletInputs]="{
      obj: obj(),
      extra: (extra$ | async),
      queryParams: queryParams$ | async,
    }"
    [ngComponentOutlet]="$any(component)"
  ></ng-container>

  <footer class="flex mt-6 justify-end gap-4">
    <button [routerLink]="cancelRoute()" mat-button>
      <span i18n>cancel</span>
    </button>
    <button (click)="submit()" mat-flat-button>
      <span i18n>save changes</span>
    </button>
  </footer>
</ui-layout-container>
