import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { resolveParam } from '@doctorus-front-end-monorepo/util-navigation';
import { map, tap } from 'rxjs';
import { FetchPatientService } from './fetch-patient.service';
import { Account, GetAccountGQL, Patient } from './generated';
import { PatientDataService } from './patient-data.service';

type RequiredKeys<T> = {
  [K in keyof T]-?: {} extends { [P in K]: T[K] } ? never : K;
}[keyof T];

type TypeMatchingKeys<T, V> = {
  [K in keyof T]: T[K] extends V ? K : never;
}[keyof T];

type ArrayMatchingKey<T, C> = keyof {
  [K in keyof T]: Array<C>;
};

type NonOptionalTypeMatchingKeys<T, V> = TypeMatchingKeys<T, V> &
  keyof Pick<T, RequiredKeys<T>>;

export const resolvePatientCollection = <C>(
  collectionProp: NonOptionalTypeMatchingKeys<Patient, Array<C>>,
) => {
  return function (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.warn('actif');
    return inject(PatientDataService).selectedPatient$.pipe(
      map(x => (x ? x[collectionProp] : [])),
    );
  };
};

export const resolveMedicalRecordItem = <C>(
  collectionProp: NonOptionalTypeMatchingKeys<
    Patient,
    Array<C & { id: string }>
  >,
) => {
  return function (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const patientId = resolveParam('patId', route);
    const itemId = resolveParam('id', route);
    return inject(FetchPatientService)
      .fetch({ id: patientId! })
      .pipe(
        map(res =>
          (res.data.getPatient as any)[collectionProp].find(
            x => x['id'] === itemId,
          ),
        ),
      );
  };
};

export const resolveAccountSettingsCollection = <C>(
  collectionProp: ArrayMatchingKey<Account, C>,
) => {
  return function (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const accountId = resolveParam('accountId', route);
    return inject(GetAccountGQL)
      .fetch({
        id: accountId,
      })
      .pipe(map(x => x.data.getAccount![collectionProp]));
  };
};

export const resolveAccountSettingsItem = <C>(
  collectionProp: ArrayMatchingKey<Account, C>,
) => {
  return function (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const accountId = resolveParam('accountId', route);
    const itemId = resolveParam('id', route);
    console.warn(accountId, itemId);
    return inject(GetAccountGQL)
      .fetch({
        id: accountId,
      })
      .pipe(
        tap(console.warn),
        map(x =>
          (x.data.getAccount[collectionProp] as Array<any>).find(
            x => x['id'] === itemId,
          ),
        ),
      );
  };
};
