import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { formatDuration } from 'date-fns';
import { fr } from 'date-fns/locale';
import { TaskType } from '../../../../../shared/data-access/graphql/src';
import {
  EmptyStateComponent
} from '../../../../../shared/ui-layout/src';
import { parseDuration } from '../../../../../shared/util-formatting/src';
import { DurationPipe } from '../../../../../shared/util-time/src';

@Component({
    selector: 'lib-ui-task-types',
    imports: [
    MatButtonModule,
    MatListModule,
    EmptyStateComponent,
    RouterLink,
    DurationPipe,
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatTableModule
],
    templateUrl: './ui-task-types.component.html',
    styleUrl: './ui-task-types.component.scss'
})
export class UiTaskTypesComponent {
  objs = input.required<TaskType[]>();

  displayedColumns: string[] = [`name`, `default_duration`, `default_fees`];
  getDefaultDuration = (data: TaskType): string => {
    return formatDuration(parseDuration(data.default_duration), {
      locale: fr,
    });
  };

  // matSnackBar = inject(MatSnackBar);
  // injector = inject(Injector);
  // sds = inject(SlideOutPanelService);
  // eds = inject(EntityDialogService);

  // deleteItem(item: TaskType): void {
  //   this.eds
  //     .openEntityDeleteDialog(
  //       item,
  //       this.injector,
  //       new EntityConfig({
  //         feature: $localize`appointment type`,
  //       }),
  //     )
  //     .subscribe();
  // }
}
