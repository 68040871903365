import { ComponentPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule, NgComponentOutlet } from '@angular/common';
import {
  Component,
  Input,
  ViewChild,
  computed,
  inject,
  input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ContainerComponent } from '@doctorus-front-end-monorepo/ui-layout';
import { updateAllValidityMarkAllTouched } from '@doctorus-front-end-monorepo/util-form';
import { isNil } from 'lodash';
import { combineLatest, map } from 'rxjs';
import { BaseEntityFormComponent } from '../base-entity-form.component';
import { BaseWrapperComponent } from '../base-wrapper.component';
import {
  CreateEntityService,
  ENTITY_CONFIG,
  ENTITY_WRITE_COMPONENT,
  PutEntityService,
} from '../tokens';

@Component({
  selector: 'entity-write-wrapper',
  imports: [
    CommonModule,
    ContainerComponent,
    RouterModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    PortalModule,
  ],
  templateUrl: './write-wrapper.component.html',
  styleUrl: './write-wrapper.component.scss',
})
export class WriteWrapperComponent<
  T,
  C extends BaseEntityFormComponent<T>,
> extends BaseWrapperComponent {
  obj = input<T>();
  cancelRedirect = input<any[]>(['..']);
  @Input() successRedirect?: any[];
  component = inject<C>(ENTITY_WRITE_COMPONENT);
  portal = new ComponentPortal(this.component as any);

  cancelRoute = computed(() => this.cancelRedirect() ?? ['..']);
  private matSnackBar = inject(MatSnackBar);
  private putEntityService = inject(PutEntityService);
  private createEntityService = inject(CreateEntityService, { optional: true });
  private entityConfig = inject(ENTITY_CONFIG);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  extra$ = combineLatest([this.route.data, this.route.queryParams]).pipe(
    map(x => ({ ...x[0], ...x[1] })),
  );
  queryParams$ = this.route.queryParams;

  @ViewChild(NgComponentOutlet, { static: false })
  ngComponentOutlet!: NgComponentOutlet;

  get title(): string {
    return this.entityConfig.writeTitle(isNil(this.obj()));
  }

  componentInputs = computed(() => ({
    obj: this.obj(),
  }));

  submit(): void {
    const form = this.ngComponentOutlet['_componentRef'].instance.form;
    if (form) {
      updateAllValidityMarkAllTouched(form);
      if (form.valid) {
        (this.createEntityService && !this.obj()
          ? this.createEntityService
          : this.putEntityService
        )
          .mutate({
            payload: form.value,
          })
          .subscribe(x => {
            this.matSnackBar.open(
              this.entityConfig.onPutSuccessMsg(!isNil(this.obj())),
            );
            this.router.navigate(this.successRedirect ?? ['..'], {
              relativeTo: this.route,
            });
          });
      }
    }
  }
}
