import { ComponentType } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import {
  ContainerComponent,
  EmptyStateComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { BaseEntityListComponent } from '../base-entity-list.component';
import { BaseWrapperComponent } from '../base-wrapper.component';
import { ENTITY_CONFIG, ENTITY_LIST_COMPONENT } from '../tokens';

@Component({
  selector: 'entity-list-wrapper',
  imports: [
    EmptyStateComponent,
    CommonModule,
    RouterModule,
    ContainerComponent,
    MatButtonModule,
    MatIconModule,
    EmptyStateComponent,
  ],
  templateUrl: './list-wrapper.component.html',
  styleUrl: './list-wrapper.component.scss',
})
export class ListWrapperComponent<
  T,
  C extends ComponentType<C extends BaseEntityListComponent<T> ? C : never>,
> extends BaseWrapperComponent {
  objs = input.required<T[]>();
  component = inject<C>(ENTITY_LIST_COMPONENT, { optional: true });

  private entityConfig = inject(ENTITY_CONFIG);

  get title(): string {
    return $localize`${this.entityConfig.plural} (${this.objs().length})`;
  }
  get createActionTitle(): string {
    return $localize`new ${this.entityConfig.feature}`;
  }

  get emptyStateContent(): string {
    return $localize`no ${this.entityConfig.feature}s`;
  }

  componentInputs = computed(() => ({
    objs: this.objs(),
  }));
}
