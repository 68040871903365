import {
  DatePipe
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { EntityDialogService } from '@doctorus-front-end-monorepo/feature-entity';
import {
  CorePatientFieldsFragment,
  DocumentType,
  Prescription,
} from '@doctorus-front-end-monorepo/graphql';
import { PdfConfigDialogService } from '@doctorus-front-end-monorepo/util-document';
import { BaseEntityDetailsComponent } from '../../../../../shared/feature-entity/src';
import {
  KeyValueComponent
} from '../../../../../shared/ui-layout/src';
import { CoalescePipe } from '../../../../../shared/util-formatting/src';

import { prescriptionConfig } from '../prescription.entity.model';
@Component({
  selector: 'prescription-prescription-box',
  templateUrl: './prescription-box.component.html',
  styleUrls: ['./prescription-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    RouterModule,
    MatDividerModule,
    CoalescePipe,
    MatListModule,
    KeyValueComponent,
    MatCardModule,
    DatePipe
],
})
export class PrescriptionBoxComponent extends BaseEntityDetailsComponent<Prescription> {
  //obj = input.required<Prescription>();
  @Input()
  patient!: CorePatientFieldsFragment;
  @Input() mode: 'large' | 'small' = 'large';
  @Input() noBorder = false;
  expanded = false;
  entityConfig = prescriptionConfig;
  private injector = inject(Injector);
  private pcds = inject(PdfConfigDialogService);
  private eds = inject(EntityDialogService);
  get title() {
    return $localize`prescription\:${this.obj().id}`;
  }
  print(): void {
    this.pcds
      .openPDFConfigDialog(this.obj().id, DocumentType.Prescription)
      .subscribe();
  }

  get iconStr(): string {
    return this.expanded ? 'unfold_less' : 'unfold_more';
  }
  toggle(): void {
    this.expanded = !this.expanded;
  }
  delete() {
    this.eds
      .openEntityDeleteDialog(this.obj(), this.injector, prescriptionConfig)
      .subscribe();
  }
}
