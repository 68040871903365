<form class=" p-4 py-8 bg-white rounded-sm   sm:grid-cols-4 flex flex-wrap   gap-4 w-full" [formGroup]="form">
  <mat-form-field uiFormMatFormFieldErrorDisplayer class="w-full mb-4" subscriptSizing="dynamic">
    <mat-label i18n>patient</mat-label>
    <mat-icon matPrefix>person</mat-icon>
    <mat-icon (click)="openPatientForm($event)" class="hover:cursor-pointer" matSuffix>person_add</mat-icon>
    <mat-select formControlName="patient" placeholder="patient">
      <mat-option>
        <div class="fles justify-between items-center">
          <ngx-mat-select-search [searching]="patientsLoading" i18n-placeholderLabel
            placeholderLabel="search by reference, name, phone number or email" i18n-noEntriesFoundLabel
            noEntriesFoundLabel="no matching patient found" [formControl]="patientFilterCtrl">
          </ngx-mat-select-search>
        </div>


      </mat-option>
      <ng-container *ngFor="let patient of filtredPatients()">
        <mat-option *ngIf="patientsLoading === false" [value]="patient.id">
          <span>{{ patient | humanName:'full':true }}</span>&nbsp;
          <span>{{ patient?.reference }}</span>
        </mat-option>
      </ng-container>
    </mat-select>

  </mat-form-field>

  <mat-form-field class="flex-1" appearance="outline" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n="appointment-type">type</mat-label>
    <mat-icon matPrefix>info</mat-icon>

    <mat-select (selectionChange)="updateExpectedDuration()" formControlName="task_type_id">
      <mat-option [value]="taskType.id" *ngFor="let taskType of taskTypes">
        {{taskType?.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="flex-1" appearance="outline" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>location</mat-label>
    <mat-icon matPrefix>location_on</mat-icon>
    <mat-select (selectionChange)="loadParams()" formControlName="location_id">
      <mat-option [value]="location.id" *ngFor="let location of locations">
        {{ location?.name}}
      </mat-option>
    </mat-select>
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field class="flex-1" appearance="outline" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>time zone</mat-label>
    <mat-icon matPrefix>language</mat-icon>

    <mtx-select [compareWith]="compareStr" (change)="loadParams()" formControlName="timezone">
      <mtx-option [value]="_timezone" *ngFor="let _timezone of timezones">
        {{ _timezone}}
      </mtx-option>
    </mtx-select>
    <mat-error></mat-error>
  </mat-form-field>
  <div class="flex flex-wrap w-full justify-center gap-2  text-gray-800" *ngIf="entity">
    <p>{{entity.start | date:'medium':offset}}</p>
    <p>-</p>
    <p>{{entity.end | date:'medium':offset}}</p>
  </div>

  <mat-radio-group class="flex-col w-full gap-4 flex" [formControl]="slotEntry">
    <div class="slot-entry-container">
      <mat-radio-button value="select">
        <p class="first-letter:uppercase text-base" i18n>select one slot time</p>
      </mat-radio-button>
      <ng-container *ngIf="slotEntry.value === 'select'">

        <div class="flex gap-2 w-full flex-wrap">
          <mat-form-field class="flex-1">
            <mat-label i18n>choose a date</mat-label>
            <input (change)="loadParams()" #dateInput type="date" [formControl]="appointmentDay" matInput>
            <mat-icon (click)="dateInput.showPicker()" matSuffix>calendar_month</mat-icon>

          </mat-form-field>
          <mat-form-field class="flex-1" appearance="outline" uiFormMatFormFieldErrorDisplayer>
            <mat-label i18n>expected duration</mat-label>
            <mat-icon matPrefix>timer</mat-icon>

            <mat-select (valueChange)="loadParams()" [formControl]="expectedDurationCtrl">
              <mat-option [value]="duration" *ngFor="let duration of durations">
                {{ duration |duration}}
              </mat-option>
            </mat-select>
            <mat-error></mat-error>
          </mat-form-field>
        </div>
        <appointment-slot-picker [formControl]="slotSelectorCtr" [offset]="offset"
          [params]="slotFetchParams"></appointment-slot-picker>
        <!-- <ng-container uiFormMatFormFieldErrorDisplayer>

          <div *ngIf="!slotsLoading() else loading">
            <mat-chip-listbox class="text-sm" [formControl]="slotSelectorCtr">
              <mat-chip-option [selected]="entity?.start === slot" [value]="slot" *ngFor="let  slot of availableSlots">
                {{ slot | date:'HH:mm':offset}}
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
          <mat-error class="text-sm" *ngIf="form.get('start')?.invalid">
            select one slot time
          </mat-error>
        </ng-container>

        <ng-template #loading>
          <div class="flex flex-col items-center gap-6 col-span-4">
            <mat-progress-spinner [diameter]="32" mode="indeterminate"></mat-progress-spinner>
            <p i18n>fetching available slots ...</p>
          </div>

        </ng-template> -->
      </ng-container>
    </div>

    <div class="slot-entry-container">
      <mat-radio-button value="manual">
        <p class="first-letter:uppercase text-base" i18n>manually insert the appointment slot</p>
      </mat-radio-button>
      <div *ngIf="slotEntry.value === 'manual'" class="flex gap-2 w-full flex-wrap">

        <mat-form-field class="flex-1">
          <mat-label>start</mat-label>
          <input #startInput type="datetime-local" formControlName="start" matInput>
          <mat-icon matIconSuffix class="hover:cursor-pointer"
            (click)="startInput.showPicker()">calendar_month</mat-icon>

          <!-- <mtx-datetimepicker [twelvehour]="false" type="datetime" #datetimePickerStart></mtx-datetimepicker>
          <input [mtxDatetimepicker]="datetimePickerStart" formControlName="start" matInput required>
          <mtx-datetimepicker-toggle [for]="datetimePickerStart" matSuffix></mtx-datetimepicker-toggle> -->
        </mat-form-field>
        <mat-form-field class="flex-1">
          <mat-label>end</mat-label>
          <input #endInput type="datetime-local" formControlName="end" matInput>
          <mat-icon matIconSuffix class="hover:cursor-pointer" (click)="endInput.showPicker()">calendar_month</mat-icon>

          <!-- <mtx-datetimepicker [twelvehour]="false" type="datetime" #datetimePickerEnd></mtx-datetimepicker>
          <input [mtxDatetimepicker]="datetimePickerEnd" formControlName="end" matInput required>
          <mtx-datetimepicker-toggle [for]="datetimePickerEnd" matSuffix></mtx-datetimepicker-toggle> -->
        </mat-form-field>
      </div>
      <p class=" text-red-700 first-letter:capitalize" i18n *ngIf="form.errors && form.errors['endAfterStart']">
        appointment end date can't be earlier than the start date
      </p>

    </div>

  </mat-radio-group>





</form>