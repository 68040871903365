<header class="flex justify-between items-center">
  <h1 class="capitalize" i18n>memberships</h1>
  <button mat-stroked-button (click)="createMembership()"><span i18n>new membership</span></button>
</header>

<mat-table class="bg-white w-full" [dataSource]="account().memberships">
  <!-- User name Definition -->
  <ng-container matColumnDef="member">
    <mat-header-cell *matHeaderCellDef i18n>member</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <div class="flex items-center gap-2">
        <img class="w-8 rounded-full" authAvatarLoader [user]="$any(row.user)">
        <p>{{row.user | humanName:'full'}}</p>
        @if (account().owner.sub === row.user?.sub) {
        <span i18n class="text-gray-600">( account owner
          )</span>
        }
      </div>
    </mat-cell>
  </ng-container>

  <!-- Age Definition -->
  <ng-container matColumnDef="roles">
    <mat-header-cell *matHeaderCellDef>roles</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <div class="flex gap-2">
        @for (item of row.roles; track $index) {
        <span class="tag">{{item}}</span>
        }
      </div>

    </mat-cell>
  </ng-container>

  <!-- Title Definition -->
  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let row">
      <div class="flex gap-2 justify-end">
        <button (click)="deleteMembership(row)" color="warn" mat-icon-button><mat-icon>delete</mat-icon></button>
        <button class="text-black" (click)="editMembership(row)" mat-icon-button><mat-icon>edit</mat-icon></button>
      </div>

    </mat-cell>
  </ng-container>


  <!-- Header and Row Declarations -->
  <mat-header-row *matHeaderRowDef="['member', 'roles', 'action']"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['member', 'roles', 'action']"></mat-row>
</mat-table>