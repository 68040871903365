<h2 mat-dialog-title i18n class="first-letter:uppercase">
  new medical account
</h2>
<form
  #form
  matDialogContent
  [formGroup]="officeForm"
  class="flex flex-col gap-8 p-6"
>
  <mat-form-field
    appearance="outline"
    subscriptSizing="dynamic"
    uiFormMatFormFieldErrorDisplayer
  >
    <mat-label i18n>name</mat-label>
    <input matInput required formControlName="alias" matInput />
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>country</mat-label>
    <mat-select
      [compareWith]="countryCompare"
      formControlName="country"
      placeholder="select country"
    >
      <mat-option>
        <ngx-mat-select-search
          placeholderLabel="search countries"
          i18n-placeholderLabel
          i18n-noEntriesFoundLabel
          noEntriesFoundLabel="no countries found"
          [formControl]="countrySearchCrl"
        ></ngx-mat-select-search>
      </mat-option>

      <mat-option
        *ngFor="let country of filtredCountries$ | async"
        [value]="country"
      >
        <span>{{ getFlag(country.iso2) }}</span>
        {{ country.name | titlecase }}
      </mat-option>
    </mat-select>
    <mat-error></mat-error>
  </mat-form-field>
  <footer mat-dialog-actions class="gap-4">
    <button
      [disabled]="!officeForm.valid"
      type="submit"
      (click)="submit()"
      color="primary"
      mat-flat-button
    >
      <span i18n>create</span>
    </button>
    <button [matDialogClose]="null" mat-stroked-button>
      <span i18n>cancel</span>
    </button>
  </footer>
</form>
