import { CommonModule, NgFor, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';

import { CreateAccountGQL } from '@doctorus-front-end-monorepo/graphql';
import {
  ICountry,
  ICountryData,
  TCountryCode,
  getCountryDataList,
  getEmojiFlag,
} from 'countries-list';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'doctorus-front-end-monorepo-new-office-dialog',
  templateUrl: './new-office-dialog.component.html',
  styleUrls: ['./new-office-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    UiMatFormFieldErrorDisplayerDirective,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatIconModule,
    CommonModule,
    NgFor,
    MatOptionModule,
    MatButtonModule,
    TitleCasePipe,
  ],
})
export class NewOfficeDialogComponent {
  officeForm = this.fb.group({
    alias: [null, Validators.required],
    country: [null, Validators.required],
  });
  private createAccountGql = inject(CreateAccountGQL);
  constructor(
    public dialogRef: MatDialogRef<NewOfficeDialogComponent>,
    private fb: FormBuilder,
  ) {}

  countries = getCountryDataList().sort((a, b) => (a.name > b.name ? 1 : -1));
  countrySearchCrl = new FormControl('');
  filtredCountries$ = this.countrySearchCrl.valueChanges.pipe(
    startWith(null),
    map(value =>
      value
        ? this.countries.filter(country =>
            country.name?.toLowerCase()?.includes(value.toLowerCase()),
          )
        : this.countries,
    ),
  );
  countryCompare = (a: ICountry, b: ICountry) =>
    a?.name?.toLowerCase() === b?.name?.toLowerCase();

  submit(): void {
    const country = this.officeForm.value.country as ICountryData;
    const alias = this.officeForm.value.alias as string;
    this.createAccountGql
      .mutate({
        payload: {
          alias,
          countryIso2: country.iso2,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          currency: country.currency.at(0).toUpperCase(),
        },
      })
      .subscribe(res => this.dialogRef.close(res.data.createAccount));
  }

  getFlag(code: string | ICountry) {
    return getEmojiFlag(code as TCountryCode);
  }
}
