import { Pipe, PipeTransform } from '@angular/core';
import { age } from '../utils';

@Pipe({
  name: 'age',
  standalone: true,
})
export class AgePipe implements PipeTransform {
  transform(value: string | Date): string {
    return age(value);
  }
}
