import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { AccountRole, AuthService } from '@doctorus-front-end-monorepo/auth';
import {
  NavLink,
  NavTreeComponent,
} from '@doctorus-front-end-monorepo/ui-layout';

@Component({
  selector: 'doctorus-front-end-monorepo-patients-list',
  imports: [
    CommonModule,
    NavTreeComponent,
    RouterModule,
    MatSidenavModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    FormsModule,
  ],
  templateUrl: './patients-list.component.html',
  styleUrl: './patients-list.component.scss',
})
export class PatientsListComponent {
  loading = signal(false);
  count = signal(0);
  pageIndex = signal<number | null>(null);
  pageSize = signal<number>(50);
  status: 'active' | 'inactive' = 'active';
  roles$ = inject(AuthService).authUserRoles$;
  title = computed(() => $localize`patients list (${this.count()})`);

  routes: Array<NavLink> = [
    {
      name: $localize`active patients`,
      routerLink: ['active'],
    },
    {
      name: $localize`inactive patients`,
      routerLink: ['inactive'],
    },
    {
      name: $localize`notes explorer`,
      routerLink: ['notes-explorer'],
      requiredRoles: [AccountRole.DOCTOR],
    },
  ];

  updatePage(event: PageEvent): void {
    this.pageSize.set(event.pageSize);
    this.pageIndex.set(event.pageIndex);
  }
}
