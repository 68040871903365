import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  AbstractControl,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  AccountLocation,
  Maybe,
} from '../../../../../shared/data-access/graphql/src';
import { BaseEntityFormComponent } from '../../../../../shared/feature-entity/src/lib/base-entity-form.component';
import { InlineFormArrayComponent } from '../../../../../shared/form/inline-form-array/src';
import { UiMatFormFieldErrorDisplayerDirective } from '../../../../../shared/form/ui-form/src';
import { ContainerComponent } from '../../../../../shared/ui-layout/src';
import { minutesToTime } from '../../../../../shared/util-formatting/src';

import { WorkingPeriodInlineFormComponent } from '../working-period-inline-form/working-period-inline-form.component';

@Component({
    selector: 'lib-location-form',
    imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    ContainerComponent,
    InlineFormArrayComponent,
    WorkingPeriodInlineFormComponent,
    UiMatFormFieldErrorDisplayerDirective,
    MatIconModule
],
    templateUrl: './location-form.component.html',
    styleUrl: './location-form.component.scss'
})
export class LocationFormComponent extends BaseEntityFormComponent<AccountLocation> {
  override initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id],
      name: [this.obj()?.name, Validators.required],
      slot_interval: [this.obj()?.slot_interval ?? 30, Validators.required],
      disabled: [this.obj()?.disabled ?? false, Validators.required],
      working_periods: this.fb.array([]),
    });

  get workingRuledTitle() {
    return $localize`working rules (${this.form.value?.working_periods?.length ?? 0})`;
  }

  createWorkingPeriodFg = (payload?: Maybe<[number, number]>) =>
    this.fb.array(
      [
        [
          payload && payload[0],
          [Validators.required, Validators.min(0), Validators.max(60 * 24)],
        ],
        [
          payload && payload[1],
          [Validators.required, Validators.min(0), Validators.max(60 * 24)],
        ],
      ],
      {
        validators: this.timeRangeValidator,
      },
    );

  timeRangeValidator = (
    control: AbstractControl<any[]>,
  ): ValidationErrors | null => {
    try {
      const lower = control.value && control.value[0];
      const upper = control.value && control.value[1];
      return lower && upper && parseInt(upper) <= parseInt(lower)
        ? {
            invalidTimeRange: $localize`lower bound ${minutesToTime(
              lower,
            )} must be lower than the upper bound ${minutesToTime(upper)}`,
          }
        : null;
    } catch (e) {
      return null;
    }
  };
}
