import { DatePipe, NgFor, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import {
  MatChipSelectionChange,
  MatChipsModule,
} from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Appointment } from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import {
  ISlideOutFormComponent,
  SLIDE_OUT_DATA_TOKEN,
  SlideOutFormPanelData,
} from '@doctorus-front-end-monorepo/slide-out-panel';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';

@Component({
  selector: 'appointment-fees-dialog',
  templateUrl: './fees-dialog.component.html',
  styleUrls: ['./fees-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    UiMatFormFieldErrorDisplayerDirective,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatChipsModule,
    MatAutocompleteModule,
    NgFor,
    MatOptionModule,
    MatButtonModule,
    TitleCasePipe,
    DatePipe,
    HumanNamePipe,
  ],
})
export class FeesDialogComponent implements ISlideOutFormComponent {
  suggestions = this.data.extra?.suggestions ?? [];

  form = new FormGroup({
    id: new FormControl(this.data.entity?.id),
    fees: new FormControl(this.data.entity?.fees, [Validators.min(0)]),
  });

  constructor(
    @Inject(SLIDE_OUT_DATA_TOKEN)
    public data: SlideOutFormPanelData<Appointment>
  ) {}
  checkForm(): void {
    this.form.markAllAsTouched();
  }
  updateTextField(event: MatChipSelectionChange): void {
    this.form.controls.fees.setValue(event.source.value);
  }
  clear(): void {
    this.form.controls.fees.reset();
  }
}
