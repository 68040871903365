<div *ngIf="!fetchingSlots() else loading">
  <mat-chip-listbox (change)="updateSelection($event)" class="text-sm" [formControl]="slotSelectorCtr">
    <mat-chip-option [value]="slot" *ngFor="let  slot of availableSlots">
      {{ slot | date:'HH:mm':offset}}
    </mat-chip-option>
  </mat-chip-listbox>
</div>
<ng-template #loading>
  <div class="flex flex-col items-center gap-6 col-span-4">
    <mat-progress-spinner [diameter]="32" mode="indeterminate"></mat-progress-spinner>
    <p i18n>fetching available slots ...</p>
  </div>

</ng-template>
