import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { BehaviorSubject, map, of, switchMap } from 'rxjs';
import { GetAccountGQL, GetAccountQuery } from './generated';

@Injectable({
  providedIn: 'root',
})
export class AccountDataService {
  private currentAccountIdSubject = new BehaviorSubject<string | null>(null);
  set currentAccountId(id: string) {
    this.currentAccountIdSubject.next(id);
  }
  currentAccount$ = this.currentAccountIdSubject
    .asObservable()
    .pipe(
      switchMap(x =>
        x
          ? this.getAccountGQL
              .watch({ id: x })
              .valueChanges.pipe(map(x => x.data.getAccount))
          : of(null),
      ),
    );

  private _account!: GetAccountQuery['getAccount'] | null;
  public get account(): GetAccountQuery['getAccount'] | null {
    return this._account;
  }
  public set account(value: GetAccountQuery['getAccount'] | null) {
    if (value) {
      localStorage.setItem('account-id', value?.id);
      this._account = value;
    } else {
      localStorage.removeItem('account-id');
      this._account = null;
    }
  }

  public hasMultipleLocations$ = this.currentAccount$.pipe(
    map(x => x?.locations && x.locations?.length > 1),
  );

  constructor(
    private getAccountGQL: GetAccountGQL,
    private apollo: Apollo,
  ) {
    this.currentAccount$.subscribe(x => (this.account = x));
  }

  async clear() {
    await this.apollo.client.clearStore();
    this.account = null;
  }
}
