import { CommonModule } from '@angular/common';
import {
  Component,
  Injector,
  Input,
  Type,
  ViewContainerRef,
  computed,
  inject,
  input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ContainerComponent } from '@doctorus-front-end-monorepo/ui-layout';
import { BaseWrapperComponent } from '../base-wrapper.component';
import { EntityDialogService } from '../entity-dialog.service';
import { ENTITY_CONFIG, ENTITY_DETAILS_COMPONENT } from '../tokens';
import { IEntity } from '../types';

@Component({
  selector: 'entity-details-wrapper',
  imports: [
    CommonModule,
    ContainerComponent,
    RouterModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
  ],
  templateUrl: './details-wrapper.component.html',
  styleUrl: './details-wrapper.component.scss',
})
export class DetailsWrapperComponent<
  T extends IEntity,
  C extends Type<C>,
> extends BaseWrapperComponent {
  obj = input.required<T>();
  @Input() deleteRedirect = ['..'];

  component = inject<C>(ENTITY_DETAILS_COMPONENT);
  title = computed(() => this.obj() && this.entityConfig.title(this.obj()));
  description = computed(() =>
    this.entityConfig.description
      ? this.entityConfig.description(this.obj())
      : null,
  );
  private vcr = inject(ViewContainerRef);
  private entityConfig = inject(ENTITY_CONFIG);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private injector = inject(Injector);
  private dialogService = inject(EntityDialogService);
  canDelete = this.layoutConfig?.canDelete ?? true;

  componentInputs = computed(() => ({
    obj: this.obj(),
  }));

  delete() {
    this.dialogService
      .openEntityDeleteDialog(
        this.obj(),
        this.injector,
        this.entityConfig,
        this.vcr,
      )
      .subscribe(x => {
        this.router.navigate(this.deleteRedirect ?? ['..'], {
          relativeTo: this.route,
        });
      });
  }
}
