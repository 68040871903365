import {
  ChangeDetectionStrategy,
  Component,
  Input,
  booleanAttribute,
  computed,
  input,
  numberAttribute,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ImgSrcPipe } from '../pipes/img-src/img-src.pipe';
import { IsImagePipe } from '../pipes/is-image.pipe';
import { fileIcon } from '../utils';

@Component({
  selector: 'util-document-file-box',
  templateUrl: './file-box.component.html',
  styleUrls: ['./file-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, IsImagePipe, ImgSrcPipe],
})
export class FileBoxComponent {
  fileSrc = input.required<string | Blob | File>();
  ext = computed(() => fileIcon(this.fileSrc()));
  @Input({ transform: numberAttribute }) height = 56;
  @Input({ transform: numberAttribute }) width = 128;
  @Input({ transform: booleanAttribute }) medicalOnly = false;
  @Input({ transform: booleanAttribute }) rounded = false;
}
