import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BaseEntityFormComponent } from '@doctorus-front-end-monorepo/feature-entity';
import {
  AccountDataService,
  PhoneNumber,
} from '@doctorus-front-end-monorepo/graphql';
import { UiPhoneNumberPickerComponent } from '@doctorus-front-end-monorepo/ui-phone-number-picker';

@Component({
  selector: 'lib-write-phone-number',
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    UiPhoneNumberPickerComponent,
  ],
  templateUrl: './write-phone-number.component.html',
  styleUrl: './write-phone-number.component.scss',
})
export class WritePhoneNumberComponent extends BaseEntityFormComponent<PhoneNumber> {
  ads = inject(AccountDataService);

  override initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id],
      patient: [
        this.obj()?.patient ?? this.extra()?.patient?.id,
        Validators.required,
      ],
      phone_number: [this.obj()?.phone_number, Validators.required],
      availabilities: [this.obj()?.availabilities],
      remarks: [this.obj()?.remarks],
    });
}
