import { inject, Injectable } from '@angular/core';
import { AuthService } from '@doctorus-front-end-monorepo/auth';
import { compareDesc } from 'date-fns';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { AccountDataService } from './account-data.service';
import { MedicalDocumentType, Patient } from './generated';
import { toGenericDocuments } from './type-policies/patient/utils';

@Injectable({ providedIn: 'root' })
export class PatientDataService {
  private account$ = inject(AccountDataService).currentAccount$;
  private selectedPatientSubject = new BehaviorSubject<Patient | null>(null);
  private as = inject(AuthService);
  set selectedPatient(patient: Patient) {
    this.selectedPatientSubject.next(patient);
  }
  selectedPatient$ = this.selectedPatientSubject.asObservable();

  allDocuments$ = this.selectedPatient$.pipe(
    map(patient =>
      [
        ...toGenericDocuments(
          MedicalDocumentType.Uploaded,
          patient?.upload_documents ?? [],
        ),
        ...toGenericDocuments(
          MedicalDocumentType.Generated,
          patient?.generated_documents ?? [],
        ),
        ...toGenericDocuments(
          MedicalDocumentType.Prescription,
          patient?.prescriptions ?? [],
        ),
      ].sort((a, b) => compareDesc(new Date(a.date), new Date(b.date))),
    ),
  );

  missingMedicalHistoryChecks$ = combineLatest([
    this.account$,
    this.selectedPatient$,
  ]).pipe(
    map(
      combine =>
        combine[0]?.medicalHistoryModels?.filter(
          _hist =>
            !combine[1]?.medical_histories
              ?.map(_mhist => _mhist.name.toLowerCase().trim())
              .includes(_hist.type.toLowerCase().trim()),
        ) ?? [],
    ),
  );

  missingMeasureChecks$ = combineLatest([
    this.account$,
    this.selectedPatient$,
  ]).pipe(
    map(
      combine =>
        combine[0]?.measureModels?.filter(
          _hist =>
            !combine[1]?.measures
              ?.map(_mhist => _mhist.name.toLowerCase().trim())
              .includes(_hist.name.toLowerCase().trim()),
        ) ?? [],
    ),
  );

  paymentStatus$ = this.selectedPatient$.pipe(
    map(patient =>
      patient?.appointments && patient?.payments
        ? {
            total_due:
              patient.payments.reduce(
                (acc: number, current) => acc + current.amount,
                0,
              ) -
              patient.appointments.reduce(
                (acc: number, current) => acc + (current?.fees ?? 0),
                0,
              ),

            total_paid: patient.payments.reduce(
              (acc: number, current) => acc + current.amount,
              0,
            ),
          }
        : { total_due: 0, total_paid: 0 },
    ),
  );
}
