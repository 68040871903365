@if (authUser$ | async; as authUser) {
  <h1 class="first-letter:uppercase" i18n>staff dashboard</h1>

  <ui-layout-container title="profile informations">
    <button
      ngProjectAs="actions"
      (click)="editProfileDialog(authUser)"
      mat-stroked-button
    >
      <span i18n>edit</span>
    </button>
    <section class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
      <ui-layout-key-value key="family name" i18n-key>
        {{ authUser.family_name }}
      </ui-layout-key-value>
      <ui-layout-key-value key="given name" i18n-key>
        {{ authUser.given_name }}
      </ui-layout-key-value>
      <ui-layout-key-value key="email" i18n-key>
        <a [href]="'mailto:' + authUser.email">{{ authUser.email }}</a>
      </ui-layout-key-value>

      <ui-layout-key-value key="phone number" i18n-key>
        {{ authUser.phone_number | coalesce: '-' }}
      </ui-layout-key-value>
    </section>
  </ui-layout-container>

  <ui-layout-container
    [title]="'medical accounts' + '(' + (memberships$ | async)?.length + ')'"
  >
    <button
      ngProjectAs="actions"
      (click)="openNewOfficeDialog()"
      mat-stroked-button
    >
      <span i18n>new medical account</span>
    </button>
    <mat-nav-list>
      <a
        mat-list-item
        [routerLink]="['/', membership?.account?.id]"
        *ngFor="let membership of memberships$ | async"
      >
        <div matListItemTitle>{{ membership?.account?.alias }}</div>
        <div matListItemLine>{{ membership?.roles?.join(' - ') }}</div>
      </a>
    </mat-nav-list>
  </ui-layout-container>
}
