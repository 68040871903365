import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Appointment } from '@doctorus-front-end-monorepo/graphql';
import { RteComponent } from '@doctorus-front-end-monorepo/rte';
import {
  ISlideOutFormComponent,
  SLIDE_OUT_DATA_TOKEN,
  SlideOutFormPanelData,
} from '@doctorus-front-end-monorepo/slide-out-panel';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'appointment-write-medica-note-from',
  standalone: true,
  imports: [
    CommonModule,
    QuillEditorComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    RteComponent,
  ],
  templateUrl: './write-medica-note-from.component.html',
  styleUrl: './write-medica-note-from.component.scss',
})
export class WriteMedicaNoteFromComponent implements ISlideOutFormComponent {
  checkForm(): void {
    this.form.markAllAsTouched();
  }
  //quillConfig = mentionsConfig();
  form = new FormGroup({
    id: new FormControl(this.data?.entity?.id),
    note: new FormControl(this.data?.entity?.note),
    description: new FormControl(this.data?.entity?.description),
  });

  constructor(
    private matSnackBar: MatSnackBar,
    @Inject(SLIDE_OUT_DATA_TOKEN)
    public data: SlideOutFormPanelData<Appointment>,
  ) {}
}
