<header mat-dialog-title class="flex mb-2 dense-icon text-gray-800">
  <button type="button" (click)="closeWith('EDIT')" mat-icon-button>
    <mat-icon>edit</mat-icon>
  </button>
  <button type="button" (click)="closeWith('DELETE')" mat-icon-button>
    <mat-icon>delete</mat-icon>
  </button>
  <button
    type="button"
    (click)="closeWith('NONE')"
    class="ml-auto"
    mat-icon-button
  >
    <mat-icon>close</mat-icon>
  </button>
</header>
<mat-divider></mat-divider>

<mat-dialog-content class="flex mt-1 flex-col gap-2">
  <a
    [routerLink]="
      rnhs.getApppointmentRoute(event.extendedProps.patient_info.id, event.id)
    "
    class="text-lg font-medium"
    >{{ event.start | date: 'fullDate' }}</a
  >
  <div class="flex gap-2 flex-wrap">
    <mat-icon>schedule</mat-icon>
    <div>
      <p>
        {{ event.start | date: 'HH:mm ' }} - {{ event.end | date: 'HH:mm' }}
        @if (differentTimeZone) {
          <span>({{ currentTimezone }} {{ event.end | date: 'OO' }})</span>
        }
      </p>
      @if (differentTimeZone) {
        <span
          >{{ event.start | date: 'HH:mm' : appointmentOffset }} -
          {{ event.end | date: 'HH:mm' : appointmentOffset }}
          <span
            >({{ event.extendedProps.timezone }}
            {{ event.end | date: 'OO' : appointmentOffset }})</span
          ></span
        >
      }
    </div>
  </div>
  @if (event.extendedProps.task_type?.name) {
    <div class="flex gap-2 items-center">
      <!-- <mat-icon>medical_services</mat-icon> -->
      <span
        class="w-4 h-4 rounded-full mx-1"
        [style.background-color]="event.extendedProps.task_type?.color"
      ></span>
      <span>{{ event.extendedProps.task_type?.name | titlecase }}</span>
    </div>
  }
  <div class="flex gap-2 items-center">
    <mat-icon>location_on</mat-icon>
    <span>{{ event.extendedProps.location?.name | titlecase }}</span>
  </div>
  @if (
    event.extendedProps.status | resolveStatusConfig: 'appointment';
    as status
  ) {
    <appointment-status-picker
      (statusChange)="updateStatus($event)"
      [status]="event.extendedProps.status"
    >
      <div class="flex gap-2 items-center">
        <mat-icon [style.color]="status.color">{{ status.svgIcon }}</mat-icon>
        <span [style.color]="status.color">{{ status.label }}</span>
      </div>
    </appointment-status-picker>
  }

  <mat-divider></mat-divider>
  <div class="flex gap-2">
    <mat-icon>badge</mat-icon>
    <div class="flex flex-col gap-1">
      <a
        (click)="closeWith('NONE')"
        [routerLink]="rnhs.getPatientRoute(event.extendedProps.patient_info)"
        class="anchor flex items-center gap-2"
        [class.text-gray-600]="
          event.extendedProps.patient_info.is_active === false
        "
        >{{
          event.extendedProps.patient_info
            | humanName: 'full' : true
            | titlecase
        }}
        @if (event.extendedProps.patient_info.is_active === false) {
          <small i18n>( deleted )</small>
        }
      </a>

      <section class="flex flex-wrap gap-1 items-center">
        @for (
          phonenumber of event.extendedProps.patient_info.phone_numbers;
          track $index;
          let last = $last
        ) {
          <patient-phone-number-box
            class="text-black"
            mode="SMALL"
            [phonenumber]="phonenumber"
          ></patient-phone-number-box>
          @if (!last) {
            &nbsp;/&nbsp;
          }
        }
      </section>
      <div i18n>REF : {{ event.extendedProps.patient_info.reference }}</div>
    </div>
  </div>
</mat-dialog-content>

<!-- <mat-dialog-actions class="flex justify-end mt-4">
  <button (click)="closeWith('NONE')" *showForRoles="[accountRole.DOCTOR]" [queryParams]="{initAppointmentId:event.id}"
    [routerLink]="rnhs.medicalRecordRoute(event.extendedProps.patient_info.id,event.id)"
    mat-flat-button><mat-icon>medical_information</mat-icon><span>medical
      record</span></button>

</mat-dialog-actions> -->
