import { Injectable } from '@angular/core';
import { Account, GetAccountGQL } from '@doctorus-front-end-monorepo/graphql';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, map, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountDataService {
  private currentAccountIdSubject = new BehaviorSubject<string | null>(null);
  set currentAccountId(id: string) {
    this.currentAccountIdSubject.next(id);
  }
  currentAccount$ = this.currentAccountIdSubject
    .asObservable()
    .pipe(
      switchMap(x =>
        x
          ? this.getAccountGQL
              .watch({ id: x })
              .valueChanges.pipe(map(x => x.data.getAccount))
          : of(null),
      ),
    );

  private _account!: Account | null;
  public get account(): Account | null {
    return this._account;
  }
  public set account(value: Account | null) {
    if (value) {
      localStorage.setItem('account-id', value?.id);
      this._account = value;
    } else {
      localStorage.removeItem('account-id');
      this._account = null;
    }
  }

  public hasMultipleLocations$ = this.currentAccount$.pipe(
    map(x => x?.locations && x.locations?.length > 1),
  );

  constructor(
    private getAccountGQL: GetAccountGQL,
    private apollo: Apollo,
  ) {
    this.currentAccount$.subscribe(x => (this.account = x));
  }

  // private createAccountConfig = (account: Account) => ({
  //   ...account,
  //   taskTypes: sortBy(account.taskTypes, [a => a.name]),
  //   locations: sortBy(account.locations, [a => a.name]),
  //   measureModels: sortBy(account.measureModels, [a => a.name]),
  //   medicalHistoryModels: sortBy(account.medicalHistoryModels, [a => a.order]),
  //   documentModels: sortBy(account.documentModels, [a => a.name]),
  //   hasMultipleLocations: account.locations && account.locations.length > 1,
  // });

  async clear() {
    await this.apollo.client.clearStore();
    this.account = null;
  }
}
