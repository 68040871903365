import { ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';
import * as Handlebars from 'handlebars';
import { BreadcrumbService } from './breadcrumb-service/breadcrumb.service';
import { Breadcrumb } from './types';
export const breadcrumbServiceFactory = (
  route: ActivatedRoute,
  group?: string,
) => new BreadcrumbService(route);

export const resolveBreadcrumbs = (
  route: ActivatedRoute,
  group?: string,
  breadcrumbs: Breadcrumb[] = [],
  link: any[] = [],
): Breadcrumb[] => {
  const ROUTE_DATA_BREADCRUMB_DEF_KEY = 'breadcrumbChunck';
  const ROUTE_DATA_BREADCRUMB_GROUP_KEY = 'breadcrumbGroup';

  const children: ActivatedRoute[] = route.children;

  if (children.length === 0) {
    return breadcrumbs;
  }

  for (const child of children) {
    if (
      group &&
      child.snapshot.data[ROUTE_DATA_BREADCRUMB_GROUP_KEY] !== group
    ) {
      continue;
    }

    // if (
    //   !child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB_DEF_KEY) ||
    //   child.snapshot.data[ROUTE_DATA_BREADCRUMB_DEF_KEY].byPass === true
    // ) {
    //   return resolveBreadcrumbs(child, group, breadcrumbs, link);
    // }
    const _childLink = [...link, ...child.snapshot.url.map(x => x.path)];
    const name = child.snapshot.data[ROUTE_DATA_BREADCRUMB_DEF_KEY]?.name;

    if (name) {
      const compiledName = Handlebars.compile(name, {})(
        child.snapshot.data,
        {},
      );
      const breadcrumb: Breadcrumb = {
        name: compiledName,
        group:
          child.snapshot.data[ROUTE_DATA_BREADCRUMB_DEF_KEY]?.group ??
          PRIMARY_OUTLET,
        params: child.snapshot.params,
        queryParams: child.snapshot.queryParams,
        link: _childLink,
      };
      if (breadcrumb.name) {
        breadcrumbs.push(breadcrumb);
      }
    }

    return resolveBreadcrumbs(child, group, breadcrumbs, _childLink);
  }
  return breadcrumbs;
};
