import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { LAYOUT_CONFIG } from './tokens';

@Component({
  imports: [CommonModule],
  template: ``,
  styles: ``,
})
export abstract class BaseWrapperComponent {
  layoutConfig = inject(LAYOUT_CONFIG, { optional: true });
  withBorder = this.layoutConfig?.withBorder ?? false;
  contentBorder = this.layoutConfig?.contentBorder ?? true;
  paddingLevel = this.layoutConfig?.paddingLevel ?? 0;
}
