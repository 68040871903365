<entity-list-wrapper [objs]="objs()">
  <table mat-table [dataSource]="objs()">
    <ng-container matColumnDef="phone_number">
      <th mat-header-cell i18n *matHeaderCellDef>phone number</th>
      <td mat-cell *matCellDef="let element">
        <a class="anchor" [routerLink]="[element.id]">{{
          element.phone_number | phoneNumber
        }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="availabilities">
      <th mat-header-cell *matHeaderCellDef i18n>availabilities</th>
      <td mat-cell *matCellDef="let element">
        @if (element.availabilities) {
          <mat-chip-set class="mat-chip-sm text-sm">
            @for (availability of element.availabilities; track $index) {
              <mat-chip>{{ availability }}</mat-chip>
            }
          </mat-chip-set>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="remarks">
      <th mat-header-cell *matHeaderCellDef i18n>remarks</th>
      <td mat-cell *matCellDef="let element">
        {{ element.remarks | coalesce: '-' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>
</entity-list-wrapper>
