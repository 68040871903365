import { Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';

import { Injectable, Injector, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IEntity } from '@doctorus-front-end-monorepo/feature-entity';
import { filter, tap } from 'rxjs';
import { SlideOutFormPanelComponent } from '../slide-out-form-panel/slide-out-form-panel.component';
import {
  ISlideOutFormComponent,
  SlideOutFormPanelData,
} from '../slide-out-panel-types';
import { SlideOutPanelComponent } from '../slide-out-panel/slide-out-panel.component';
@Injectable({
  providedIn: 'root',
})
export class SlideOutPanelService {
  private dialog = inject(Dialog);
  private overlay = inject(Overlay);
  private snackBar = inject(MatSnackBar);

  open<T, C, R>(title: string, cmp: C, data: T) {
    return this.dialog.open<R>(SlideOutPanelComponent<T, C>, {
      width: 'calc(100vw - 148px)',
      height: '100vh',
      hasBackdrop: true,
      panelClass: ['rounded-md'],
      positionStrategy: this.overlay.position().global().top('0').right('0'),
      data: {
        title,
        data,
        cmp,
      },
    });
  }
  openSlideOutEntityWriteForm<
    E extends IEntity,
    C extends ISlideOutFormComponent,
    R,
  >(data: SlideOutFormPanelData<E>, injector: Injector) {
    return this.dialog
      .open<R>(SlideOutFormPanelComponent<E, C>, {
        width: '100vw',
        maxWidth: 1280,
        height: '100vh',
        hasBackdrop: true,
        panelClass: ['rounded-md'],
        positionStrategy: this.overlay.position().global().top('0').right('0'),
        data,
        injector,
      })
      .closed.pipe(
        filter(x => (x ? true : false)),
        tap(x =>
          this.snackBar.open(
            data.entityConfig?.onPutSuccessMsg(data.entity ? true : false) ??
              'Action performed',
          ),
        ),
      );
  }
}
