import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BaseEntityDetailsComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { MedicalHistory } from '@doctorus-front-end-monorepo/graphql';
import { KeyValueComponent } from '@doctorus-front-end-monorepo/ui-layout';
import { QuillViewHTMLComponent } from 'ngx-quill';

@Component({
  selector: 'medical-history-medical-history-details',
  imports: [
    CommonModule,
    KeyValueComponent,
    MatIconModule,
    QuillViewHTMLComponent,
  ],
  templateUrl: './medical-history-details.component.html',
  styleUrl: './medical-history-details.component.scss',
})
export class MedicalHistoryDetailsComponent extends BaseEntityDetailsComponent<MedicalHistory> {}
