// /* eslint-disable @typescript-eslint/no-empty-interface */
// import { EntityConfig, IEntity } from '@doctorus-front-end-monorepo/feature-entity';
// import { AccountLocation } from '../../../models/entities/account/location.entity';
// import { TaskType } from '../../../graphql/generated';
// import { ApiUser } from '../../../models/entities/user.entity';

import { FetchUserAttributesOutput, JWT } from 'aws-amplify/auth';

export enum AccountRole {
  MEDICAL_ASSISTANT = 'ASSISTANT',
  DOCTOR = 'DOCTOR',
  MANAGER = 'MANAGER',
  OWNER = 'OWNER',
}
export enum SubscriptionStatus {
  PENDING = 0,
  ACCEPTED = 1,
  REFUSED = 2,
}

export type UserAttributes = JWT &
  FetchUserAttributesOutput & {
    'cognito:groups'?: AccountRole[];
    account_id?: string;
  } & Required<
    Pick<FetchUserAttributesOutput, 'family_name' | 'given_name' | 'email'>
  >;
