import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { getCountryDataList } from 'countries-list';
import { Account } from '../../../../../../../shared/data-access/graphql/src';
import { BaseEntityFormComponent } from '../../../../../../../shared/feature-entity/src';
import { UiMatFormFieldErrorDisplayerDirective } from '../../../../../../../shared/form/ui-form/src';
import { ContainerComponent } from '../../../../../../../shared/ui-layout/src';
import { TimezoneOffsetPipe } from '../../../../../../../shared/util-time/src';

@Component({
  selector: 'account-settings-form-preferences-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ContainerComponent,
    UiMatFormFieldErrorDisplayerDirective,
    MatFormFieldModule,
    MatSelectModule,
    TimezoneOffsetPipe,
  ],
  templateUrl: './form-preferences-form.component.html',
  styleUrl: './form-preferences-form.component.scss',
})
export class FormPreferencesFormComponent extends BaseEntityFormComponent<Account> {
  countries = getCountryDataList().sort((a, b) => (a.name > b.name ? 1 : -1));
  timeZones = Intl.supportedValuesOf('timeZone');

  override initFormFun = () =>
    this.fb.group({
      appointmentDefaultLocation: [
        this.obj()?.appointmentDefaultLocation?.id,
        Validators.required,
      ],
      appointmentDefaultTaskType: [
        this.obj()?.appointmentDefaultTaskType?.id,
        Validators.required,
      ],
      appointmentDefaultTimeZone: [
        this.obj()?.appointmentDefaultTimeZone,
        Validators.required,
      ],
      defaultCountryCode: [this.obj()?.defaultCountryCode, Validators.required],
      defaultPrintLayout: [this.obj()?.defaultPrintLayout, Validators.required],
      defaultDocumentModel: [
        this.obj()?.defaultDocumentModel,
        Validators.required,
      ],
    });
}
