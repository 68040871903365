<ul>
  @for (item of navLinks(); track $index) {
    @if (hasRole(item)) {
      <ui-layout-nav-tree-element
        #ref
        [item]="item"
        [level]="level()"
      ></ui-layout-nav-tree-element>
      @if (item.children && item.children.length > 0 && ref.expanded()) {
        <ui-layout-nav-tree
          [authUserRoles]="authUserRoles()"
          [level]="level() + 1"
          [navLinks]="item.children"
        ></ui-layout-nav-tree>
      }
    }
  }
</ul>
