/* eslint-disable @typescript-eslint/no-empty-interface */
import { EntityConfig } from '@doctorus-front-end-monorepo/feature-entity';
import { UploadDocument } from '@doctorus-front-end-monorepo/graphql';

export type MedicalImageType = 'photography' | 'radio';
// export enum ImageSizeEnum {
//   ORIGINAL = 'O',
//   SMALL = 'S',
//   MEDIUM = 'M',
//   LARGE = 'L',
// }
// export interface UploadDocument extends IEntity {
//   id: number | string;
//   file: string;
//   type: string;
//   date: string;
//   description: string;
//   patient: number | string;
//   multi_sizes_urls: Record<'O' | 'S' | 'M' | 'L', string>;
// }

// export type UploadDocumentForm = Omit<
//   UploadDocument,
//   'id' | 'multi_sizes_urls' | 'file'
// > & {
//   file: UploadParam;
// };
// export type WriteApiUploadDocument = Omit<UploadDocumentForm, 'file'> & {
//   file: string;
// };

export const uploadDocumentConfig = new EntityConfig({
  //baseUri: 'medical_note/uploaddocuments/',
  feature: $localize`uploaded document`,
  title: (entity: UploadDocument) => entity.name ?? entity.id,
  description: (entity: UploadDocument) => entity.created,
  //serializerFun: serialize,
  // graphQl: {
  //   type: 'UploadDocument',
  //   // parent: {
  //   //   parentProp: 'patient',
  //   //   typeName: 'Patient',
  //   //   childProp: 'upload_documents',
  //   // },
  //   mutation: {
  //     delete: gql`
  //       mutation deleteUploadDocument($id: ID!) {
  //         deleteUploadDocument(id: $id) {
  //           id
  //         }
  //       }
  //     `
  //   }
  // },
  // messages: {
  //   title: {
  //     new: $localize`:@@medicalImage.message.title.new:New medical image`,
  //     edit: $localize`:@@medicalImage.message.title.edit:Edit medical image`,
  //   },
  //   action: {
  //     created: {
  //       success: $localize`:@@medicalImage.message.created.success:New medical image created successfully`,
  //     },
  //     updated: {
  //       success: $localize`:@@medicalImage.message.updated.success:Medical image updated successfully`,
  //     },
  //     deleted: {
  //       confirmation: $localize`:@@medicalImage.message.deleted.confirmation:Do you really want to delete this medical image ?`,
  //       success: $localize`:@@medicalImage.message.deleted.success:Medical image was deleted`,
  //     },
  //   },
  // },
});
