import { Injectable, inject } from '@angular/core';
import { StoreObject } from '@apollo/client/cache';
import {
  deleteFn,
  upsertArrayFn,
} from '@doctorus-front-end-monorepo/util-entity';
import { Apollo, gql } from 'apollo-angular';
import { Maybe } from './generated';
@Injectable({
  providedIn: 'root',
})
export class ApolloCacheService {
  apollo = inject(Apollo);

  updateParentCache<
    T extends { [P in K]?: Maybe<C[]> | undefined },
    C extends { id: string },
    K extends keyof Omit<T, 'id'>,
  >(args: { parentId: string; childKey: K; childObj: C; del?: boolean }): void {
    const parentId = args.parentId;
    const updateFn = upsertArrayFn<C & { id: string }>;
    const parentObj = this.apollo.client.cache.readFragment<T>({
      returnPartialData: true,
      id: parentId,
      fragment: gql`
        fragment ParentFrag1 on parent {
          id
          appointments {
            id
          }
        }
      `,
    });
    if (parentObj) {
      this.apollo.client.cache.updateFragment<{ [P in K]: C[] }>(
        {
          id: parentId,
          fragment: gql`
              fragment ParentFrag2 on parent {
                ${args.childKey} {
                  id
                }
              }
            `,
        },
        data => {
          const res =
            data && Object.hasOwn(data, args.childKey)
              ? ({
                  [args.childKey]: args.del
                    ? deleteFn(
                        (data[args.childKey] ?? []) as C[],
                        args.childObj.id,
                        'id',
                      )
                    : updateFn(data[args.childKey] ?? [], args.childObj, 'id'),
                } as { [P in K]: C[] })
              : null;
          return res;
        },
      );
    }
  }

  deleteCache(obj: StoreObject): void {
    const normalizedId = this.apollo.client.cache.identify(obj);
    this.apollo.client.cache.evict({
      id: normalizedId,
      broadcast: true,
    });
    this.apollo.client.cache.gc();
  }
}
