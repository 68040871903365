import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Observation } from '@doctorus-front-end-monorepo/graphql';
import {
  ISlideOutFormComponent,
  SLIDE_OUT_DATA_TOKEN,
  SlideOutFormPanelData,
} from '@doctorus-front-end-monorepo/slide-out-panel';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { updateAllValidityMarkAllTouched } from '@doctorus-front-end-monorepo/util-form';
import * as dateFns from 'date-fns';

@Component({
  selector: 'medical-measure-observation-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatButtonModule,
    UiMatFormFieldErrorDisplayerDirective,
    MatInputModule,
  ],
  templateUrl: './write-observation-dialog.component.html',
  styleUrls: ['./write-observation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WriteObservationDialogComponent implements ISlideOutFormComponent {
  form: FormGroup;
  constructor(
    @Inject(SLIDE_OUT_DATA_TOKEN)
    public data: SlideOutFormPanelData<Observation>,
    private fb: FormBuilder
  ) //public data: { measure: Measure; observation: Observation }
  {
    this.form = this.fb.group({
      date: [
        this.data.entity?.date ??
          dateFns.formatISO(new Date(), { representation: 'date' }),
        Validators.required,
      ],
      value: [this.data.entity?.value, Validators.required],
      measure: [this.data.extra.measure.id, Validators.required],
      note: [this.data.entity?.note],
    });
  }
  checkForm(): void {
    updateAllValidityMarkAllTouched(this.form);
  }

  // submit(): void {
  //   this.form.markAllAsTouched();
  //   if (this.form.valid) {
  //     this.dialogRef.close({
  //       ...this.form.value,
  //       date: dateFns.formatISO(new Date(this.form.value.date), {
  //         representation: 'date',
  //       }),
  //     });
  //   }
  // }
}
