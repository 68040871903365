import { CommonModule, CurrencyPipe } from '@angular/common';
import {
  Component,
  computed,
  inject,
  input,
  LOCALE_ID,
  OnInit,
  Signal,
} from '@angular/core';
import { rxResource, toSignal } from '@angular/core/rxjs-interop';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import {
  AccountRole,
  AuthService,
  ShowForRolesDirective,
} from '@doctorus-front-end-monorepo/auth';
import {
  AccountDataService,
  FetchPatientService,
  MedicalHistory,
  PatientDataService,
  RealTimeSubscriptionsService,
} from '@doctorus-front-end-monorepo/graphql';
import { AppointmentStatus } from '@doctorus-front-end-monorepo/shared-type';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import {
  FeedbackComponent,
  LoadingComponent,
  MessageType,
  NavLink,
  NavTreeComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { isBefore } from 'date-fns';
import { isNil } from 'lodash';
import { QuillViewHTMLComponent } from 'ngx-quill';
import { map, merge, tap } from 'rxjs';

@Component({
  selector: 'doctorus-front-end-monorepo-patient-details',
  imports: [
    ShowForRolesDirective,
    CommonModule,
    MatSidenavModule,
    FeedbackComponent,
    MatChipsModule,
    LoadingComponent,
    QuillViewHTMLComponent,
    MatListModule,
    RouterModule,
    MatIconModule,
    NavTreeComponent,
    HumanNamePipe,
  ],
  templateUrl: './patient-details.component.html',
  styleUrl: './patient-details.component.scss',
})
export class PatientDetailsComponent implements OnInit {
  patId = input.required<string>();
  currency = inject(AccountDataService).account?.currency;
  localeId = inject(LOCALE_ID);
  currencyPipe = new CurrencyPipe(this.localeId);
  accountRole = AccountRole;
  fetchPatientService = inject(FetchPatientService);
  isDoctor = inject(AuthService).isAuthUserDoctor;
  rss = inject(RealTimeSubscriptionsService);

  patientRx = rxResource({
    request: () => ({ id: this.patId() }),
    loader: params =>
      this.fetchPatientService
        .watch({ id: params.request.id }, { fetchPolicy: 'network-only' })
        .valueChanges.pipe(
          map(res => res.data.getPatient),
          tap(patient => (this.pds.selectedPatient = patient)),
        ),
  });
  pds = inject(PatientDataService);
  patient = this.patientRx.value;
  outdatedPendingAppointments = computed(
    () =>
      this.patient()?.appointments.filter(
        _app =>
          _app?.status === AppointmentStatus.PENDING &&
          isBefore(_app.end ?? new Date(), new Date()),
      ) ?? [],
  );

  paymentStatus = toSignal(this.pds.paymentStatus$, {
    initialValue: { total_due: 0, total_paid: 0 },
  });
  missingMedicalHistoryChecks = toSignal(
    this.pds.missingMedicalHistoryChecks$,
    { initialValue: [] },
  );
  missingMedicalMeasureChecks = toSignal(this.pds.missingMeasureChecks$, {
    initialValue: [],
  });

  feeds = computed(() =>
    !isNil(this.patient())
      ? [
          {
            condition: this.outdatedPendingAppointments().length > 0,
            message: $localize`${this.outdatedPendingAppointments().length} outdated appointments`,
            type: MessageType.INFO,
          },
          {
            condition: this.paymentStatus().total_due < 0,
            message: $localize`${this.currencyPipe.transform(Math.abs(this.paymentStatus()?.total_due ?? 0), this.currency ?? 'EUR', 'symbol', '1.0-1')} due`,
            type: MessageType.WARNING,
          },
          {
            condition:
              this.paymentStatus() && this.paymentStatus().total_due > 0,
            message: $localize`${this.currencyPipe.transform(this.paymentStatus()?.total_due, this.currency ?? 'EUR', 'symbol', '1.0-1')} advance`,
            type: MessageType.INFO,
          },
          {
            condition:
              this.isDoctor &&
              this.missingMedicalHistoryChecks() &&
              this.missingMedicalHistoryChecks().length > 0,
            message: $localize`${this.missingMedicalHistoryChecks().length} missing medical history checks`,
            type: MessageType.WARNING,
          },
          {
            condition:
              this.isDoctor && this.missingMedicalMeasureChecks().length > 0,
            message: $localize`${this.missingMedicalMeasureChecks().length} missing measure checks`,
            type: MessageType.WARNING,
          },
        ].filter(feed => feed.condition)
      : [],
  );

  medicalHistoryModels =
    inject(AccountDataService).account?.medicalHistoryModels;

  currentMedicalHistory = computed(
    () =>
      this.patient()
        ?.medical_histories.filter(medHist => medHist.is_current)
        .reduce(
          (acc: Map<string, MedicalHistory[]>, current) =>
            acc.set(current.name, [...(acc.get(current.name) ?? []), current]),
          new Map(),
        ) ?? new Map(),
  );
  generalRoutes: Signal<NavLink[]> = computed(() => [
    {
      name: $localize`appointments (${this.patient()?.appointments?.length})`,
      active: true,
      routerLink: ['appointments'],
    },
    {
      name: $localize`payments (${this.patient()?.payments?.length})`,

      active: true,

      routerLink: ['payments'],
    },
    {
      name: $localize`contacts (${this.patient()?.phone_numbers?.length})`,

      active: true,
      routerLink: ['contacts'],
    },
  ]);

  medicalRoutes: Signal<NavLink[]> = computed(() => [
    {
      name: 'summary',
      routerLink: ['summary'],
    },
    {
      name: $localize`medical histories (${this.patient()?.medical_histories?.length})`,
      routerLink: ['medical-histories'],
    },
    {
      name: $localize`measures (${this.patient()?.medical_histories?.length})`,
      routerLink: ['measures'],
    },
    {
      name: $localize`documents (${this.patient()?.allDocuments?.length})`,
      routerLink: ['documents'],
    },
    {
      name: $localize`care plans (${this.patient()?.care_plans?.length})`,
      routerLink: ['care-plans'],
    },
    {
      name: 'general notes',
      routerLink: ['general-notes'],
    },
  ]);

  ngOnInit(): void {
    merge(
      this.rss.listenAppointmentSubscriptions(this.patId()),
      this.rss.listenPaymentsSubscriptions(this.patId()),
    ).subscribe(() => this.patientRx.reload());
  }
}
