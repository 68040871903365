import { Component, Inject, Input, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterLink } from '@angular/router';
import { AuthService } from '@doctorus-front-end-monorepo/auth';
import { AccountDataService } from '@doctorus-front-end-monorepo/graphql';
import { AvatarLoaderDirective } from '@doctorus-front-end-monorepo/shared-util';

@Component({
  selector: 'doctorus-front-end-monorepo-auth-user-card',
  templateUrl: './auth-user-card.component.html',
  styleUrls: ['./auth-user-card.component.scss'],
  imports: [
    MatDialogModule,
    MatListModule,
    AvatarLoaderDirective,
    MatDividerModule,
    RouterLink,
    MatIconModule,
  ],
})
export class AuthUserCardComponent {
  @Input() user: any = this.data?.user;
  @Input() mode: 'full' | 'side' = this.data?.mode ?? 'full';
  private acds = inject(AccountDataService);
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { mode: 'full' | 'side'; user: any },
    private as: AuthService,
  ) {}

  async logOut() {
    await this.acds.clear();
    await this.as.signOut();
  }
  get isFullMode(): boolean {
    return this.mode === 'full';
  }
}
