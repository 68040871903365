import { AsyncPipe, CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Injector,
  model,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import {
  Account,
  CreateMembershipByEmailGQL,
  DeleteMembershipGQL,
  Membership,
  PutMembershipGQL,
} from '@doctorus-front-end-monorepo/graphql';
import {
  AvatarLoaderDirective,
  HumanNamePipe,
} from '@doctorus-front-end-monorepo/shared-util';
import { EMPTY, first, switchMap } from 'rxjs';
import { EntityConfig } from '../../../../../../shared/feature-entity/src';
import {
  EntityDeleteMutationService,
  EntityDialogService,
} from '../../../../../../shared/ui-entity-dialog/src';
import { GroupHeaderComponent } from '../../../../../../shared/ui-layout/src';
import { CreateMemberDialogComponent } from '../create-member-dialog/create-member-dialog.component';

@Component({
  selector: 'doctorus-front-end-monorepo-office-memberships',
  templateUrl: './office-memberships.component.html',
  styleUrls: ['./office-memberships.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: EntityDeleteMutationService,
      useClass: DeleteMembershipGQL,
    },
  ],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatListModule,
    CommonModule,
    MatTableModule,
    GroupHeaderComponent,
    AvatarLoaderDirective,
    AsyncPipe,
    HumanNamePipe,
  ],
})
export class OfficeMembershipsComponent {
  account = model.required<Account>();
  sds = inject(EntityDialogService);
  injector = inject(Injector);

  constructor(
    private putMembershipGQL: PutMembershipGQL,
    private createMembershipByEmailGQL: CreateMembershipByEmailGQL,
    private dialog: MatDialog,
    private matSnack: MatSnackBar,
  ) {}

  deleteMembership(membership: Membership): void {
    this.sds.openEntityDeleteDialog(
      {
        id: membership.user?.sub,
        ...membership,
      },
      this.injector,
      new EntityConfig({
        feature: $localize`membership`,
      }),
    );
  }

  editMembership(payload: Membership): void {
    this.dialog
      .open(CreateMemberDialogComponent, {
        data: {
          membership: payload,
        },
      })
      .afterClosed()
      .pipe(
        first(),
        switchMap(membership =>
          membership
            ? this.putMembershipGQL.mutate({
                userId: membership.userId,
                roles: membership.roles,
              })
            : EMPTY,
        ),
      )
      .subscribe(x => {
        this.updateMemberships(x.data?.putMembership?.memberships ?? []);
        this.matSnack?.open($localize`account membership updated`);
      });
  }

  updateMemberships(memberships: Membership[]): void {
    this.account.update(_account => ({
      ..._account,
      memberships,
    }));
  }
  createMembership(): void {
    this.dialog
      .open(CreateMemberDialogComponent, {
        data: {
          memberships: this.account().memberships,
        },
      })
      .afterClosed()
      .pipe(
        first(),
        switchMap(membership =>
          membership
            ? this.createMembershipByEmailGQL.mutate({
                email: membership.email,
                roles: membership.roles,
              })
            : EMPTY,
        ),
      )
      .subscribe(x => {
        this.updateMemberships(
          x.data?.createMembershipByEmail?.memberships ?? [],
        );
        this.matSnack?.open($localize`account membership created`);
      });
  }
}
