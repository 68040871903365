<ui-layout-container title="appointment" i18n-title>
  <section class="grid grid-cols-3 gap-4">
    <ui-layout-key-value key="time zone" i18n-key>
      @if (obj().appointmentDefaultTimeZone; as timezone) {
        {{ timezone }} ({{ timezone | timezoneOffset }})
      }
    </ui-layout-key-value>
    <ui-layout-key-value key="task type" i18n-key>
      {{ obj().appointmentDefaultTaskType?.name | coalesce: '-' }}
    </ui-layout-key-value>
    <ui-layout-key-value key="location" i18n-key>
      {{ obj().appointmentDefaultLocation?.name | coalesce: '-' }}
    </ui-layout-key-value>
  </section>
</ui-layout-container>

<ui-layout-container title="patient" i18n-title>
  <mat-grid-list cols="3" rowHeight="56">
    <mat-grid-tile>
      <ui-layout-key-value key="country code" i18n-key>
        {{ (obj().defaultCountryCode | countryInfo)?.name | coalesce: '-' }}
        ({{ (obj().defaultCountryCode | countryInfo)?.phone | coalesce: '-' }})
      </ui-layout-key-value></mat-grid-tile
    >
  </mat-grid-list>
</ui-layout-container>
<ui-layout-container title="document" i18n-title>
  <section class="grid grid-cols-3 gap-4">
    <ui-layout-key-value key="default print layout" i18n-key>
      {{ obj().defaultPrintLayout?.name | coalesce: '-' }}
    </ui-layout-key-value>
    <ui-layout-key-value key="default document model" i18n-key>
      {{ obj().defaultDocumentModel?.name | coalesce: '-' }}
    </ui-layout-key-value>
  </section>
</ui-layout-container>
