<ui-layout-container
  headerDivider="true"
  border="false"
  sticky="true"
  [title]="today | date: 'mediumDate'"
>
  <button ngProjectAs="actions" (click)="close()" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <div ngProjectAs="description">
    @if (loading()) {
      <mat-progress-bar mode="query"></mat-progress-bar>
    } @else {
      <span i18n
        >appointments(s) found {{ (appointments$ | async)?.length }}</span
      >
    }
  </div>
  @if (appointments$ | async; as appointments) {
    @if (appointments.length > 0) {
      <!-- <appointment-appointments-list
        editableStatus="false"
        editable="false"
        timeOnly="true"
        includeMedicalBoardLink="true"
        includeMeta="false"
        [appointments]="appointments"
        includePatientInfo="true"
      >
      </appointment-appointments-list> -->
    } @else {
      <ui-layout-empty-state>
        <h3 i18n>There are no appointments for today</h3>
      </ui-layout-empty-state>
    }
  }
</ui-layout-container>
