import { CommonModule, NgFor, TitleCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  AccountDataService,
  PrintLayout,
} from '@doctorus-front-end-monorepo/graphql';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { PdfConfigFormBuilderService } from '../pdf-config-form-builder.service';
import { PAPER_FORMATS } from '../utils';

@Component({
  selector: 'util-document-pdf-config-dialog',
  templateUrl: './pdf-config-dialog.component.html',
  styleUrls: ['./pdf-config-dialog.component.scss'],
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    UiMatFormFieldErrorDisplayerDirective,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    TitleCasePipe,
  ],
})
export class PdfConfigDialogComponent {
  configForm = this.pcfbs.createForm(this.ads.account?.defaultPrintLayout);
  configCtrl = new FormControl(this.ads.account?.defaultPrintLayout?.id);
  paperFormats = PAPER_FORMATS;
  printLayouts = this.ads.account?.printLayouts;

  constructor(
    private ads: AccountDataService,
    private pcfbs: PdfConfigFormBuilderService,
    public dialogRef: MatDialogRef<PdfConfigDialogComponent>,
  ) {}

  compareFun = (a: any, b: any): boolean => a?.name === b?.name;
  updateForm(x: PrintLayout): void {
    this.configForm = this.pcfbs.createForm(x);
  }
  generatePdf() {
    this.configForm.enable();
    this.configForm.markAllAsTouched();
    //console.warn(this.configForm);
    if (this.configForm.valid) {
      this.dialogRef.close(this.configForm.value);
    }
  }
}
