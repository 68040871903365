import { Routes } from '@angular/router';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityConfig,
  LAYOUT_CONFIG,
  LayoutConfig,
  PutEntityService,
  WriteWrapperComponent,
} from '@doctorus-front-end-monorepo/feature-entity';
import { UpdatePreferencesGQL } from '@doctorus-front-end-monorepo/graphql';
import { uiMeasureModelsRoutes } from '@doctorus-front-end-monorepo/medical-space-web/ui-measure-models';
import { uiDocumentModelsRoutes } from '@doctorus-front-end-monorepo/ui-document-models';
import { uiLocationsRoutes } from '@doctorus-front-end-monorepo/ui-locations';
import { uiMedicalHistoryModelRoutes } from '@doctorus-front-end-monorepo/ui-medical-history-model';
import { uiPatientPropertiesRoutes } from '@doctorus-front-end-monorepo/ui-patient-properties';
import { uiPrintLayoutRoutes } from '@doctorus-front-end-monorepo/ui-print-layout';
import { uiTaskTypesRoute } from '@doctorus-front-end-monorepo/ui-task-types';
import { FormPreferencesFormComponent } from './components/forms-preferences/form-preferences-form/form-preferences-form.component';
import { FormsPreferencesComponent } from './components/forms-preferences/forms-preferences.component';
import { GeneralAccountSettingsComponent } from './components/general-account-settings/general-account-settings.component';
import { OfficeMembershipsComponent } from './components/office-memberships/office-memberships.component';
import { PatientSettingsComponent } from './components/patient-settings/patient-settings.component';

export const settingsRoutes: Routes = [
  {
    path: '',
    data: {
      breadcrumbChunck: { name: null },
    },
    providers: [
      {
        provide: LAYOUT_CONFIG,
        useValue: { canDelete: false },
      },
      {
        provide: PutEntityService,
        useClass: UpdatePreferencesGQL,
      },
    ],
    children: [
      {
        path: '',
        redirectTo: 'general-settings',
        pathMatch: 'full',
      },
      {
        path: 'general-settings',
        component: GeneralAccountSettingsComponent,
        data: {
          breadcrumbChunck: { name: $localize`general settings` },
        },
      },
      {
        path: 'memberships',
        component: OfficeMembershipsComponent,
        data: {
          breadcrumbChunck: { name: $localize`memberships` },
        },
      },
      uiLocationsRoutes,
      {
        path: 'forms-preferences',
        providers: [
          {
            provide: LAYOUT_CONFIG,
            useValue: {
              withBorder: false,
              contentBorder: false,
              canDelete: false,
            } as LayoutConfig,
          },
          {
            provide: ENTITY_CONFIG,
            useValue: new EntityConfig({
              feature: $localize`forms preferences`,
            }),
          },
          {
            provide: ENTITY_DETAILS_COMPONENT,
            useValue: FormsPreferencesComponent,
          },
          {
            provide: ENTITY_WRITE_COMPONENT,
            useValue: FormPreferencesFormComponent,
          },
        ],
        data: {
          breadcrumbChunck: { name: null },
        },
        children: [
          {
            path: '',
            component: DetailsWrapperComponent,
            data: {
              breadcrumbChunck: { name: $localize`forms preferences` },
            },
          },
          {
            path: 'edit',
            component: WriteWrapperComponent,
            data: {
              breadcrumbChunck: { name: $localize`edit` },
            },
          },
        ],
      },
      {
        path: 'patient-settings',
        component: PatientSettingsComponent,
        data: {
          breadcrumbChunck: { name: $localize`patient` },
        },
      },
      {
        path: '',
        providers: [
          {
            provide: LAYOUT_CONFIG,
            useValue: { canDelete: true },
          },
          {
            provide: PutEntityService,
            useClass: UpdatePreferencesGQL,
          },
        ],
        children: [
          uiTaskTypesRoute,
          uiPrintLayoutRoutes,
          uiDocumentModelsRoutes,
          uiMedicalHistoryModelRoutes,
          uiMeasureModelsRoutes,
        ],
      },
      uiPatientPropertiesRoutes,
    ],
  },
];
