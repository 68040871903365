import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe, CommonModule, NgIf } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, RouterOutlet } from '@angular/router';
import { Observable, map } from 'rxjs';
import {
  AccountRole,
  ShowForRolesDirective,
} from '../../../../../libs/shared/auth/src';
import { BreadcrumbComponent } from '../../../../../libs/shared/breadcrumb/src';
import {
  NavLink,
  NavTreeComponent,
} from '../../../../../libs/shared/ui-layout/src';

@Component({
  selector: 'doctorus-front-end-monorepo-office-manager',
  templateUrl: './office-manager.component.html',
  styleUrls: ['./office-manager.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    CommonModule,
    MatIconModule,
    MatDividerModule,
    NavTreeComponent,
    BreadcrumbComponent,
    ShowForRolesDirective,
    BreadcrumbComponent,
    MatButtonModule,
    RouterModule,
    RouterOutlet,
    AsyncPipe,
    MatSidenavModule,
    MatTooltipModule,
  ],
})
export class OfficeManagerComponent {
  isXSmall$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall])
    .pipe(map(result => result.matches));

  @ViewChild('drawer') matSideNav?: MatSidenav;
  open = true;
  navLinks: NavLink[] = [
    {
      name: $localize`calendar`,
      svgIcon: 'calendar_month',
      active: true,
      routerLink: ['scheduler'],
      requiredRoles: [AccountRole.DOCTOR, AccountRole.MEDICAL_ASSISTANT],
    },
    {
      name: $localize`patients`,
      svgIcon: 'patient_list',
      active: true,
      requiredRoles: [AccountRole.DOCTOR, AccountRole.MEDICAL_ASSISTANT],
      routerLink: ['patients'],
    },
    {
      name: $localize`preferences and settings`,
      svgIcon: 'settings',
      requiredRoles: [AccountRole.MANAGER],
      active: true,
      routerLink: ['settings'],
    },
  ];

  constructor(private breakpointObserver: BreakpointObserver) {}

  toggle(): void {
    this.open = !this.open;
  }
}
