import {
  AsyncPipe,
  CommonModule,
  DatePipe,
  I18nPluralPipe,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  TitleCasePipe,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  booleanAttribute,
  inject,
  input,
  model,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterLink, RouterModule } from '@angular/router';
import {
  Appointment,
  AppointmentFieldsFragment,
  AppointmentPublicFieldsFragment,
  CorePatientFieldsFragment,
  DeleteAppointmentGQL,
  PutPatientAppointmentGQL,
  SetAppointmentStatusGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { AppointmentStatus } from '@doctorus-front-end-monorepo/shared-type';
import {
  HumanNamePipe,
  ResolveStatusConfigPipe,
} from '@doctorus-front-end-monorepo/shared-util';

import { MatChipsModule } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AccountRole,
  ShowForMultiLocationsDirective,
  ShowForRolesDirective,
} from '@doctorus-front-end-monorepo/auth';
import { EntityDeleteMutationService } from '@doctorus-front-end-monorepo/ui-entity-dialog';
import {
  EmptyStateComponent,
  GroupContainerComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { ArrayExistPipe } from '@doctorus-front-end-monorepo/util-array';
import { CoalescePipe } from '@doctorus-front-end-monorepo/util-formatting';
import { RouterNavigationHelperService } from '@doctorus-front-end-monorepo/util-navigation';
import { EntityMutationService } from '../../../../../feature-entity/src';
import { AppointmentBase } from '../appointment-base';
import { DatesComponent } from '../dates/dates.component';
import { AppointmentDialogsService } from '../services/appointment-dialogs.service';
import { StatusPickerComponent } from '../status-picker/status-picker.component';
@Component({
  selector: 'appointment-appointments-list',
  templateUrl: './appointments-list.component.html',
  styleUrls: ['./appointments-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  exportAs: 'appointmentsList',
  providers: [
    {
      provide: EntityMutationService,
      useExisting: PutPatientAppointmentGQL,
    },
    {
      provide: EntityDeleteMutationService,
      useExisting: DeleteAppointmentGQL,
    },
  ],
  imports: [
    NgSwitch,
    NgSwitchCase,
    NgFor,
    NgIf,
    GroupContainerComponent,
    ResolveStatusConfigPipe,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    StatusPickerComponent,
    MatMenuModule,
    MatListModule,
    MatChipsModule,
    RouterLink,
    ShowForMultiLocationsDirective,
    MatDividerModule,
    EmptyStateComponent,
    AsyncPipe,
    RouterModule,
    TitleCasePipe,
    DatePipe,
    I18nPluralPipe,
    CoalescePipe,
    ArrayExistPipe,
    CommonModule,
    HumanNamePipe,
    DatesComponent,
    ShowForRolesDirective,
  ],
})
export class AppointmentsListComponent extends AppointmentBase {
  appointments = input<Array<Partial<Appointment> & AppointmentFieldsFragment>>(
    [],
  );
  @Input() patient?: CorePatientFieldsFragment;
  @Input({ transform: booleanAttribute }) selectable = false;
  @Input({ transform: booleanAttribute }) includePatientInfo = false;
  @Input({ transform: booleanAttribute }) includeMeta = true;
  @Input({ transform: booleanAttribute }) timeOnly = false;
  @Input({ transform: booleanAttribute }) includeMedicalBoardLink = false;
  selected = model<AppointmentPublicFieldsFragment | undefined>(undefined);
  accountRole = AccountRole;
  @Output() delete = new EventEmitter<Appointment>();
  rnhs = inject(RouterNavigationHelperService);
  constructor(
    private injector: Injector,
    private matSnackBar: MatSnackBar,
    private ads: AppointmentDialogsService,
    private setAppointmentStatusGql: SetAppointmentStatusGQL,
  ) {
    super();
  }
  writeAppointment(appointment?: Appointment): void {
    this.ads
      .openWriteForm(
        {
          ...(appointment && { entity: appointment }),
          patient: this.patient ?? appointment?.patient_info,
        },
        this.injector,
      )
      .subscribe();
  }
  deleteAppointment(appointment: Appointment): void {
    this.ads.openDeleteDialog(appointment, this.injector).subscribe();
  }

  updateStatus(
    status: AppointmentStatus,
    appointment: AppointmentFieldsFragment,
  ): void {
    this.setAppointmentStatusGql
      .mutate({
        payload: {
          status,
          id: appointment.id,
        },
      })
      .subscribe(() =>
        this.matSnackBar.open($localize`Appointment status updated`),
      );
    //this.appds.updateStatus(status, appointment).subscribe();
  }
}
