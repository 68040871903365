import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MedicalHistory } from '@doctorus-front-end-monorepo/graphql';
import { RteComponent } from '@doctorus-front-end-monorepo/rte';
import {
  ISlideOutFormComponent,
  SLIDE_OUT_DATA_TOKEN,
  SlideOutFormPanelData,
} from '@doctorus-front-end-monorepo/slide-out-panel';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { updateAllValidityMarkAllTouched } from '@doctorus-front-end-monorepo/util-form';
import { QuillEditorComponent } from 'ngx-quill';
import { MedicalHistoryFormBuilderService } from '../medical-history-form-builder.service';

@Component({
  selector: 'medical-history-medical-history-form',
  templateUrl: './write-medical-history-form.component.html',
  styleUrls: ['./write-medical-history-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    UiMatFormFieldErrorDisplayerDirective,
    MatInputModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    NgFor,
    MatIconModule,
    MatOptionModule,
    QuillEditorComponent,
    RteComponent,
    NgIf,
    MatButtonModule,
  ],
})
export class WriteMedicalHistoryFormComponent
  implements ISlideOutFormComponent
{
  medicalhistoriesTypeProps: Set<string> =
    this.data.extra.medicalhistoriesTypeProps ?? new Set<string>();
  constructor(
    @Inject(SLIDE_OUT_DATA_TOKEN)
    public data: SlideOutFormPanelData<MedicalHistory>,
    private fbs: MedicalHistoryFormBuilderService,
  ) {
    this.form = this.fbs.createForm(
      this.data.extra.patient,
      this.data.entity,
      this.data.extra.name,
    );
  }
  checkForm(): void {
    updateAllValidityMarkAllTouched(this.form);
  }
  form: FormGroup;
}
