import { CommonModule, KeyValue } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, inject } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Subscription } from 'rxjs';
@Component({
  selector: 'lib-key-value-editor',
  standalone: true,
  exportAs: 'keyValueEditor',
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  templateUrl: './key-value-editor.component.html',
  styleUrl: './key-value-editor.component.scss',
})
export class KeyValueEditorComponent implements OnDestroy, OnChanges {
  @Input({ required: true }) controlKey = '';
  @Input() init: Array<KeyValue<string, string>> = [];
  subscription = new Subscription();

  fb = inject(FormBuilder);
  parentContainer = inject(ControlContainer);

  get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  ngOnDestroy(): void {
    this.parentFormGroup.removeControl(this.controlKey);
  }
  ngOnChanges() {
    const formArray = this.fb.array(
      this.init?.map(x => this.keyvalueFrom(x)) ?? [],
    );
    this.parentFormGroup.setControl(this.controlKey, formArray);
  }

  get ctrls(): FormArray {
    return this.parentFormGroup.get(this.controlKey) as FormArray;
  }

  removeKeyValue(index: number): void {
    (this.parentFormGroup.get(this.controlKey) as FormArray).removeAt(index);
  }
  pushNewForm(): void {
    (this.parentFormGroup.get(this.controlKey) as FormArray).push(
      this.keyvalueFrom(),
    );
  }

  private keyvalueFrom = (input?: KeyValue<string, string>): FormGroup =>
    this.fb.group({
      key: [input?.key, Validators.required],
      value: [input?.value, Validators.required],
    });
}
