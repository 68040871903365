import { Route } from '@angular/router';
import { BreadcrumbRouteData } from '@doctorus-front-end-monorepo/breadcrumb';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityDeleteMutationService,
  PutEntityService,
  WriteWrapperComponent,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  DeleteMedicalHistoryGQL,
  PutMedicalHistoryGQL,
  resolveMedicalRecordItem,
} from '@doctorus-front-end-monorepo/graphql';
import { MedicalHistoryDetailsComponent } from './medical-history-details/medical-history-details.component';
import { MedicalHistoryTableComponent } from './medical-history-table/medical-history-table/medical-history-table.component';
import { medicalHistoryConfig } from './medical-history.entity.model';
import { WriteMedicalHistoryFormComponent } from './write-medical-history-form/write-medical-history-form.component';

export const featureMedicalHistoryRoutes: Route = {
  path: 'medical-histories',
  data: {
    breadcrumbChunck: {
      name: null,
    },
  },
  children: [
    {
      path: '',
      providers: [
        {
          provide: ENTITY_CONFIG,
          useValue: medicalHistoryConfig,
        },
        {
          provide: EntityDeleteMutationService,
          useClass: DeleteMedicalHistoryGQL,
        },
        {
          provide: PutEntityService,
          useClass: PutMedicalHistoryGQL,
        },
        {
          provide: ENTITY_DETAILS_COMPONENT,
          useValue: MedicalHistoryDetailsComponent,
        },
        {
          provide: ENTITY_WRITE_COMPONENT,
          useValue: WriteMedicalHistoryFormComponent,
        },
      ],
      data: {
        breadcrumbChunck: {
          name: $localize`medical histories`,
        },
      },
      children: [
        {
          path: '',
          component: MedicalHistoryTableComponent,
          data: {
            breadcrumbChunck: {
              name: null,
            },
          },
        },
        {
          path: 'new',
          component: WriteWrapperComponent,
          data: {
            obj: null,
            breadcrumbChunck: {
              name: $localize`new`,
            } as BreadcrumbRouteData,
          },
        },
        {
          path: ':id',
          runGuardsAndResolvers: 'always',

          resolve: {
            obj: resolveMedicalRecordItem('medical_histories'),
          },
          data: {
            breadcrumbChunck: {
              name: $localize`medical history:{{obj.name}}`,
            } as BreadcrumbRouteData,
          },
          children: [
            {
              path: '',
              component: DetailsWrapperComponent,
              data: {
                deleteRedirect: ['..'],

                breadcrumbChunck: {
                  name: null,
                } as BreadcrumbRouteData,
              },
            },
            {
              path: 'edit',
              component: WriteWrapperComponent,
              data: {
                breadcrumbChunck: {
                  name: $localize`edit`,
                } as BreadcrumbRouteData,
              },
            },
          ],
        },
      ],
    },
  ],
};
