import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AccountDataService } from '@doctorus-front-end-monorepo/auth';
import { PhoneNumber } from '@doctorus-front-end-monorepo/graphql';
import {
  ISlideOutFormComponent,
  SLIDE_OUT_DATA_TOKEN,
  SlideOutFormPanelData,
} from '@doctorus-front-end-monorepo/slide-out-panel';
import { UiPhoneNumberPickerComponent } from '@doctorus-front-end-monorepo/ui-phone-number-picker';

@Component({
  selector: 'patient-write-phone-number',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    UiPhoneNumberPickerComponent,
  ],
  templateUrl: './write-phone-number.component.html',
  styleUrl: './write-phone-number.component.scss',
})
export class WritePhoneNumberComponent implements ISlideOutFormComponent {
  /**
   *
   */
  constructor(
    @Inject(SLIDE_OUT_DATA_TOKEN)
    public data: SlideOutFormPanelData<PhoneNumber, WritePhoneNumberComponent>,
    private fb: FormBuilder,
    public ads: AccountDataService,
  ) {}
  checkForm(): void {
    this.form.markAllAsTouched();
  }
  form = this.fb.group({
    id: [this.data.entity?.id],
    patient: [
      this.data.entity?.patient ?? this.data.extra.patient.id,
      Validators.required,
    ],
    phone_number: [this.data.entity?.phone_number, Validators.required],
    availabilities: [this.data.entity?.availabilities],
    remarks: [this.data.entity?.remarks],
  });
}
