<ui-layout-container
  sticky="true"
  [contentBorder]="contentBorder"
  border="false"
  paddingLevel="0"
  level="4"
  [title]="title()"
>
  <p ngProjectAs="description">{{ description() }}</p>
  <div ngProjectAs="actions">
    <div class="flex gap-2">
      @if (canDelete) {
        <button type="button" (click)="delete()" mat-stroked-button>
          <span i18n>delete</span>
        </button>
      }

      <button mat-stroked-button [routerLink]="['edit']">
        <span i18n>edit</span>
      </button>
    </div>
  </div>
  <div class="p-4">
    <ng-container
      *ngComponentOutlet="component; inputs: componentInputs()"
    ></ng-container>
  </div>
</ui-layout-container>
