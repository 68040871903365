import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { BaseEntityDetailsComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { PhoneNumber } from '@doctorus-front-end-monorepo/graphql';
import { KeyValueComponent } from '@doctorus-front-end-monorepo/ui-layout';
import {
  CoalescePipe,
  PhoneNumberPipe,
} from '@doctorus-front-end-monorepo/util-formatting';

@Component({
  selector: 'lib-contact-details',
  imports: [
    CommonModule,
    KeyValueComponent,
    PhoneNumberPipe,
    MatChipsModule,
    CoalescePipe,
  ],
  templateUrl: './contact-details.component.html',
  styleUrl: './contact-details.component.scss',
})
export class ContactDetailsComponent extends BaseEntityDetailsComponent<PhoneNumber> {}
