import { EntityConfig } from '@doctorus-front-end-monorepo/feature-entity';
import { PatientPayment } from '@doctorus-front-end-monorepo/graphql';
import * as dateFns from 'date-fns';

const toString = (entity: PatientPayment): string =>
  $localize`${entity.amount} paid on ${dateFns.format(
    new Date(entity.date),
    'P',
  )}`;
export const patientPaimentConfig: EntityConfig<PatientPayment> =
  new EntityConfig({
    feature: $localize`paiment`,
    title: (payment: PatientPayment) => $localize`payment:${payment.id}`,
    realTimeDataChangeConfig: {
      soundNotify: true,
      visualNotify: true,
      visualDuration: 20000,
    },
  });
