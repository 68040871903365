import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  booleanAttribute,
  computed,
  input,
  numberAttribute,
} from '@angular/core';
import { Measure } from '@doctorus-front-end-monorepo/graphql';
import { ChartConfiguration } from 'chart.js';
import { compareAsc } from 'date-fns';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'medical-measure-measure-graph',
  imports: [CommonModule, BaseChartDirective],
  templateUrl: './measure-graph.component.html',
  styleUrl: './measure-graph.component.scss',
  host: { '[style.height.px]': 'height()' },
})
export class MeasureGraphComponent {
  measure = input.required<Measure>();
  observations = computed(() =>
    [...this.measure().observations].sort((a, b) => compareAsc(a.date, b.date)),
  );
  @Input({ transform: booleanAttribute }) withScales = true;
  height = input(80, { transform: numberAttribute });

  borderWidth = 1;
  lineChartData = computed<ChartConfiguration['data']>(() => {
    return {
      datasets: [
        {
          data: this.observations().map(x => x.value),
          label: this.measure().name,
          backgroundColor: 'rgba(148,159,177,0.2)',
          borderColor: 'rgba(148,159,177,1)',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)',
          fill: 'origin',
        },
      ],
      labels: this.observations().map(x => x.date),
    };
  });
  public lineChartOptions = computed<ChartConfiguration['options']>(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0.5,
        },
      },
      scales: {
        y: {
          display: this.withScales,
        },
        x: {
          display: this.withScales,
        },
      },

      plugins: {
        legend: { display: false },
      },
    };
  });
}
