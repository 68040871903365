import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { ListWrapperComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { PatientDataService } from '@doctorus-front-end-monorepo/graphql';
import {
  CoalescePipe,
  PhoneNumberPipe,
} from '@doctorus-front-end-monorepo/util-formatting';
import { map } from 'rxjs';

@Component({
  selector: 'lib-contact-list',
  imports: [
    CommonModule,
    MatTableModule,
    RouterModule,
    PhoneNumberPipe,
    CoalescePipe,
    MatChipsModule,
    ListWrapperComponent,
  ],
  templateUrl: './contact-list.component.html',
  styleUrl: './contact-list.component.scss',
})
export class ContactListComponent {
  objs = toSignal(
    inject(PatientDataService).selectedPatient$.pipe(
      map(x => x?.phone_numbers ?? []),
    ),
  );
  columns = ['phone_number', 'availabilities', 'remarks'];
}
