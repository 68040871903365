import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  booleanAttribute,
  numberAttribute,
} from '@angular/core';
enum ContainerLevel {
  PAGE = 1,
  CONTAINER,
  SECTION,
}
enum PadingLevel {
  NONE = 0,
  SMALL,
  MEDIUM,
  LARGE,
}
@Component({
  selector: 'ui-layout-container',
  standalone: true,
  imports: [CommonModule],
  template: `<div [ngClass]="classes">
    <header
      [ngClass]="{
        'sticky top-0 bg-white z-10': sticky,
        'border-b pb-4 border-b-gray-200': headerDivider,
      }"
      class="mb-8"
    >
      <section class="flex justify-between items-center">
        @switch (level) {
          @case (1) {
            <h1 class="first-letter:uppercase">{{ title }}</h1>
          }
          @case (2) {
            <h2 class="first-letter:uppercase">{{ title }}</h2>
          }
          @default {
            <h3 class="first-letter:uppercase">{{ title }}</h3>
          }
        }
        <ng-content select="actions"></ng-content>
      </section>
      <ng-content
        class="text-gray-600 first-letter:uppercase"
        select="description"
      ></ng-content>
    </header>
    <main [ngClass]="contentClasses">
      <ng-content></ng-content>
    </main>
  </div>`,
})
export class ContainerComponent {
  @Input() title!: string | null;
  @Input() description?: string | null;
  @Input({ transform: booleanAttribute }) border = true;
  @Input({ transform: booleanAttribute }) contentBorder = false;
  @Input({ transform: booleanAttribute }) headerDivider = false;
  @Input({ transform: booleanAttribute }) sticky = false;
  @Input({ transform: numberAttribute })
  level: ContainerLevel = 2;
  @Input({ transform: numberAttribute })
  paddingLevel: PadingLevel = 1;

  get paddingClass() {
    return `p-${(4 * this.paddingLevel).toString()}`;
  }

  get classes(): string {
    return `${this.paddingClass} ${this.border ? 'rounded-md border-gray-200 border' : ''} h-full`;
  }

  get contentClasses(): string {
    return `${this.contentBorder ? 'rounded-md border-gray-200 border p-4' : ''} flex flex-col gap-4`;
  }
}
