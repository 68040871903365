<nav aria-label="Breadcrumb" class="flex gap-1 flex-wrap">
  @for (item of breadcrumbs(); track $index;let first = $first, last = $last) {
  <a class="first-letter:uppercase anchor underline-offset-4 max-w-72 truncate" [ngClass]="{'text-gray-800':last}"
    [routerLink]="last ? null :  item.link">{{item.name|
    titlecase}}</a>
  @if (!(first && last) && !last) {
  <mat-icon>chevron_right</mat-icon>
  }
  }
</nav>
