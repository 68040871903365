import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { PatientPayment } from '@doctorus-front-end-monorepo/graphql';
import {
  ISlideOutFormComponent,
  SLIDE_OUT_DATA_TOKEN,
  SlideOutFormPanelData,
} from '@doctorus-front-end-monorepo/slide-out-panel';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { PaimentFormBuilderService } from '../../../patient-payment/services/paiment-form-builder.service';
@Component({
  selector: 'doctorus-front-end-monorepo-write-patient-payment-form',
  templateUrl: './write-patient-payment-form.component.html',
  styleUrls: ['./write-patient-payment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    UiMatFormFieldErrorDisplayerDirective,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class WritePatientPaymentFormComponent
  implements ISlideOutFormComponent
{
  form: FormGroup;

  constructor(
    private fbs: PaimentFormBuilderService,
    @Inject(SLIDE_OUT_DATA_TOKEN)
    public data: SlideOutFormPanelData<PatientPayment>,
  ) {
    //public dialogRef: MatDialogRef<WritePatientPaymentFormComponent>,
    this.form = this.fbs.createForm(data.extra.patient, data.entity);
  }
  checkForm(): void {
    this.form.markAllAsTouched();
  }
}
