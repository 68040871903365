@let patients = patientsRessource.value();
@let isLoading = patientsRessource.isLoading();

<ui-layout-container
  title="medical notes explorer"
  border="false"
  i18n-title
  description="Query patients by searching for medical terms in their associated medical notes."
  i18n-description
>
  <div class="flex justify-between gap-2 flex-wrap">
    <mat-form-field
      subscriptSizing="dynamic"
      class="max-w-sm my-3 medium-text-field w-full"
    >
      <input
        type="search"
        matInput
        placeholder="Search using a medical term"
        i18n-placeholder
        [formControl]="searchCtrl"
      />
    </mat-form-field>
    <mat-paginator
      [pageIndex]="pageIndex()"
      showFirstLastButtons
      [length]="count()"
      pageSize="50"
      [hidePageSize]="true"
      (page)="pageIndex.set($event.pageIndex)"
    >
    </mat-paginator>
  </div>
  @if (isLoading) {
    <ui-layout-loading></ui-layout-loading>
  } @else {
    @if (patients?.length > 0) {
      @for (item of patients; track $index) {
        <dt>
          <a class="anchor text-lg" [routerLink]="['../..', item.id]">{{
            item | humanName
          }}</a>
        </dt>
        <dd [innerHTML]="item.headline"></dd>
      }
    } @else {
      <ui-layout-empty-state title="found no patients" i18n-title
        ><span i18n
          >No patients found matching the medical term '{{
            searchTerm() ?? ''
          }}'</span
        ></ui-layout-empty-state
      >
    }
    <dl></dl>
  }
</ui-layout-container>
