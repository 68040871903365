<ui-layout-container
  border="false"
  [contentBorder]="contentBorder"
  paddingLevel="0"
  level="4"
  [title]="title"
>
  <div ngProjectAs="actions">
    <button [routerLink]="['new']" mat-stroked-button>
      <span>{{ createActionTitle }}</span>
    </button>
  </div>
  @if (component) {
    <ng-container
      [ngComponentOutletInputs]="componentInputs()"
      [ngComponentOutlet]="component"
    ></ng-container>
  } @else {
    <ng-content></ng-content>
  }
  @if (objs().length < 1) {
    <ui-layout-empty-state>
      {{ emptyStateContent }}
    </ui-layout-empty-state>
  }
</ui-layout-container>
