import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { ListWrapperComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { PatientDataService } from '@doctorus-front-end-monorepo/graphql';
import {
  AccountCurrencyPipe,
  CoalescePipe,
} from '@doctorus-front-end-monorepo/util-formatting';
import { map } from 'rxjs';

@Component({
  selector: 'lib-patient-payment-list',
  templateUrl: './patient-payment-list.component.html',
  styleUrls: ['./patient-payment-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'paymentsList',
  imports: [
    AccountCurrencyPipe,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatDividerModule,
    MatMenuModule,
    RouterModule,
    MatDividerModule,
    CoalescePipe,
    DatePipe,
    MatTableModule,
    ListWrapperComponent,
  ],
})
export class PatientPaymentListComponent {
  objs = toSignal(
    inject(PatientDataService).selectedPatient$.pipe(
      map(x => x?.payments ?? []),
    ),
  );
  displayedColumns = ['date', 'amount', 'type'];
}
