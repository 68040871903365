import { parseDuration } from '@doctorus-front-end-monorepo/util-formatting';
import * as dateFns from 'date-fns';
import { fr } from 'date-fns/locale';
import { Temporal } from 'temporal-polyfill';

export const timezoneToOffset = (timeZoneIdentifer: string): string => {
  const tz = Temporal.TimeZone.from(timeZoneIdentifer);
  const offset = tz.getOffsetStringFor
    ? tz.getOffsetStringFor(new Date().toISOString())
    : 'N/A'; // => '+01:00'
  return offset;
};

export const DURATIONS_CHOICES = [
  15, 30, 45, 60, 90, 120, 180, 240, 300, 360, 420,
].map(x =>
  Temporal.Duration.from({
    minutes: x % 60,
    ...(Math.floor(x / 60) > 0 && { hours: Math.floor(x / 60) }),
  }),
);

export const dateSortFunc =
  <T, K extends keyof T>(key: K, sort: 'desc' | 'asc' = 'desc') =>
  (a: T, b: T) =>
    dateFns.compareDesc(new Date(a[key] as string), new Date(b[key] as string));

export const sortArrayFunc = <T, K extends keyof T>(
  sortKey: K,
  sort: 'desc' | 'asc' = 'desc',
): ((value: any) => any) =>
  function (value: T[]) {
    return value.sort(dateSortFunc(sortKey, sort));
  };

export const duration = (
  isoDuration: string | dateFns.Duration | null | undefined,
  format?: Array<keyof dateFns.Duration>,
) =>
  isoDuration
    ? dateFns.formatDuration(
        typeof isoDuration === 'string'
          ? parseDuration(isoDuration)
          : isoDuration,
        {
          format: format,
          locale: fr,
        },
      )
    : '';
