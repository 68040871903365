import { Query, TypedDocumentNode } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { Patient } from './generated';
export class FetchPatientService extends Query<
  { getPatient: Patient },
  { id: string | number }
> {
  document!:
    | DocumentNode
    | TypedDocumentNode<{ getPatient: Patient }, { id: string | number }>;
  //document!: DocumentNode | TypedDocumentNode<{}, EmptyObject>;
}
