<nav aria-label="Breadcrumb" class="flex gap-2">
  @for (item of breadcrumbs; track $index;let first = $first, last = $last) {
  <a class="first-letter:uppercase anchor " [ngClass]="{'text-gray-600 font-bold':last}"
    [routerLink]="last ? null : item.link">{{item.name|
    titlecase}}</a>
  @if (!(first && last) && !last) {
  <mat-icon>chevron_right</mat-icon>
  }
  }
</nav>
