<form  class="flex flex-col gap-4" [formGroup]="form">
  <mat-form-field  uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>date</mat-label>
    <input placeholder="DD/MM/YYYY" #input matInput type="date" formControlName="date" />
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>amount</mat-label>
    <input min="0" step="10" required formControlName="amount" matInput type="number" />
    <mat-icon matSuffix>price_check</mat-icon>
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n>type</mat-label>
    <input formControlName="type" matInput type="text" />
  </mat-form-field>
</form>
