<form [formGroup]="form" class="max-w-sm w-full">
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <input #input type="number" min="0" [step]="10" matInput formControlName="fees">
    <mat-error></mat-error>
    <mat-icon (click)="clear()" matSuffix>close</mat-icon>
  </mat-form-field>
  <mat-chip-listbox>
    <mat-chip-option (selectionChange)="updateTextField($event)" [value]="option"
      *ngFor="let option of suggestions">{{option}}</mat-chip-option>
  </mat-chip-listbox>
</form>
