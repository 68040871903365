import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  inject,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { ArrayExistPipe } from '@doctorus-front-end-monorepo/util-array';
import { AngularPinturaModule } from '@pqina/angular-pintura';
import {
  PinturaDefaultImageWriterResult,
  createDefaultImageWriter,
  getEditorDefaults,
} from '@pqina/pintura';
import { CustomInEvent } from '../types';
// import filepond module
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  LocaleAnnotate,
  LocaleCore,
  LocaleCrop,
  LocaleFilter,
  LocaleFinetune,
  LocaleMarkupEditor,
  LocaleResize,
} from '@pqina/pintura/locale/fr_FR';
import heic from 'heic-convert/browser';
import * as upng from 'upng-js';
import utif from 'utif2';

@Component({
  selector: 'util-document-image-picker-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    ArrayExistPipe,
    MatDialogModule,
    MatSelectModule,
    AngularPinturaModule,
  ],
  templateUrl: './image-picker-dialog.component.html',
  styleUrl: './image-picker-dialog.component.scss',
})
export class ImagePickerDialogComponent implements AfterViewInit, OnDestroy {
  images = signal(this.data.initiaImages ?? []);
  selectedImage?: string;
  ref = inject(DialogRef);
  downloading = signal(false);
  editedImage = signal<Blob | undefined>(undefined);
  options: any = getEditorDefaults({
    imageWriter: createDefaultImageWriter({
      quality: 1,
    }),
    utils: ['crop', 'resize', 'annotate'],
    locale: {
      ...LocaleCore,
      ...LocaleCrop,
      ...LocaleFinetune,
      ...LocaleResize,
      ...LocaleFilter,
      ...LocaleAnnotate,
      ...LocaleMarkupEditor,
    },
  });

  // This will set a square crop aspect ratio
  //imageCropAspectRatio: 1,
  constructor(@Inject(DIALOG_DATA) public data: { initiaImages: string[] }) {}
  ngAfterViewInit(): void {}
  ngOnDestroy(): void {
    this.images().forEach(url => URL.revokeObjectURL(url));
  }

  private loadBlob(blob: Blob | File): void {
    const url = URL.createObjectURL(blob);
    this.images.update(val => [...val, url]);
    this.selectImage(url);
  }

  onImagePick = (event: Event): void => {
    const _event = event as CustomInEvent<InputEvent, HTMLInputElement>;
    const file = _event.target?.files && _event.target?.files[0];
    if (file) {
      this.downloading.set(true);
      if (/heic|heif/.test(file.type)) {
        file
          .arrayBuffer()
          .then(_buffer =>
            heic({
              buffer: _buffer,
              format: 'JPEG',
              //toType: 'image/png',
              //quality: 1,
            }),
          )
          .then(x => {
            this.downloading.set(false);

            this.loadBlob(Array.isArray(x) ? x[0] : x);
          });
      } else if (/tiff/.test(file.type)) {
        file.arrayBuffer().then(x => {
          const ifds = utif.decode(x);
          utif.decodeImage(x, ifds[0]);
          const rgba = utif.toRGBA8(ifds[0]); // Uint8Array with RGBA pixels
          const png = upng.encode([rgba], ifds[0].width, ifds[0].height, 256);
          this.downloading.set(false);

          this.loadBlob(new Blob([png]));
        });
      } else {
        this.downloading.set(false);

        this.loadBlob(file);
      }
    }
  };

  selectImage(url: string): void {
    this.selectedImage = url;
  }

  handleEditorProcess(
    imageWriterResult: PinturaDefaultImageWriterResult,
  ): void {
    this.editedImage.set(imageWriterResult.dest);
  }
  handleImageUpdate(): void {
    this.editedImage.set(undefined);
  }

  close(payload?: Blob): void {
    this.ref.close(payload);
  }
}
